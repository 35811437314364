import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  DialogContent, Divider, FormControlLabel, Grid, IconButton, Switch, Tab, Tabs, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ConditionalInfoTooltip, ConditionalTooltip } from 'components/_commons/ConditionalTooltip/ConditionalTooltip';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { DocumentList } from 'components/_commons/Document/DocumentList';
import { FormInputField, InputFile } from 'components/_commons/Form/Inputs';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { useAccessRights, useModal } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { FluidBalanceEntryService } from 'services/FluidBalanceEntryService';
import { FluidBalanceService } from 'services/FluidBalanceService';
import shortid from 'shortid';
import { DocumentHelper, translate } from 'utils';
import { APPLICATION_MANAGEMENT_ROLES, FLUID_BALANCE_ENTRY_VALIDATION_STATUS } from 'utils/constants';
import { DateHelper } from 'utils/helpers';
import { iconEnum } from 'utils/icons';
import { StatusValidationToggle } from '../../FluidBalance/FluidBalanceEntry/StatusValidationToggle';
import { Button } from '../Button';
import { Document } from '../Document/Document';
import { SmallFormAddress } from '../Form';
import { ButtonSpinner } from '../Skeletons';
import { TextAddress } from '../Text';

const ItemSectionTitle = ({ title, tooltip }: {
  title: string,
  tooltip?: any
}) => {
  const theme: any = useTheme();
  return (
    <Grid item>
      {tooltip && (
        tooltip
      )}
      <Typography color={theme.palette.grey.main} display="inline" style={{ marginBottom: 10 }}>
        {translate(title)}
      </Typography>
    </Grid>
  );
};

const ItemStock = ({
  name, year, bold = false, children, previousValue, showOld = false, required = false
}: {
  bold?: boolean,
  children: any,
  name: string,
  previousValue?: string,
  required?: boolean,
  showOld?: boolean,
  year?: number
}) => (
  <Grid alignItems="center" container direction="row" item justifyContent="space-between">
    <Grid item xs={8}>
      <Typography fontWeight={bold ? 'bold' : 'unset'}>
        {translate(`fluidBalance.updateEntryModal.${name}`, { year })}
        {required && <span style={{ color: 'red' }}> *</span>}
      </Typography>
    </Grid>
    <Grid item textAlign="end" xs={showOld ? 2 : 4}>
      {children}
    </Grid>
    {showOld && <Grid item textAlign="end" xs={2}>{previousValue}</Grid>}
  </Grid>
);

const ItemInputStock = ({
  name, year, canEdit = false, onChange, setValue, previousValue, showOld = false,
  required = false, defaultValue, optionalToolTipTranslationKey, hasError = false
}: {
  canEdit?: boolean,
  defaultValue?: string,
  hasError?: boolean,
  name: string,
  onChange?: any,
  optionalToolTipTranslationKey?: string,
  previousValue?: string,
  required?: boolean,
  setValue: any,
  showOld?: boolean,
  year?: number
}) => (
  <ItemStock
    name={name}
    previousValue={previousValue}
    required={required}
    showOld={showOld}
    year={year}
  >
    <ConditionalTooltip translationKey={optionalToolTipTranslationKey}>
      <div>
        <FormInputField
          canBeHighlighted={required}
          disabled={!canEdit}
          endAdornment=" kg"
          hasError={hasError}
          name={name}
          size="small"
          textAlign="right"
          type="numeric"
          value={defaultValue}
          onChange={(event) => {
            let { value } = event.target;
            if (value.length >= 10) {
              value = value.replace(/[^\d]/, '').slice(0, 10);
            } else {
              value = value.replace(/[^\d]/, '');
            }
            if (value === '') {
              value = '0';
            }
            setValue(name, value);
            onChange && onChange();
          }}
        />
      </div>
    </ConditionalTooltip>
  </ItemStock>
);

const ItemInputText = ({
  name, canEdit = false, required = false, previousValue, showOld = false, optionalToolTipTranslationKey
}: {
  canEdit?: boolean,
  name: string,
  optionalToolTipTranslationKey?: string,
  previousValue?: string,
  required?: boolean,
  showOld?: boolean
}) => (
  <ItemStock
    name={name}
    previousValue={previousValue}
    required={required}
    showOld={showOld}
  >
    <ConditionalTooltip translationKey={optionalToolTipTranslationKey}>
      <div>
        <FormInputField disabled={!canEdit} name={name} size="small" />
      </div>
    </ConditionalTooltip>
  </ItemStock>
);

const AddressInputText = ({
  canEdit = false, required = false, previousValue, showOld = false, value, label, onChange, optionalToolTipTranslationKey
}: {
  canEdit?: boolean,
  label?: string,
  onChange: any,
  optionalToolTipTranslationKey?: string,
  previousValue?: string,
  required?: boolean,
  showOld?: boolean,
  value?: any
}) => (
  <Grid alignItems="center" container direction="row" item justifyContent="space-between" marginBottom="10px">
    <Grid container direction="column" item xs={showOld ? 11 : 12}>
      <Grid item>
        <Typography fontWeight="unset">
          {translate(label)}
          {required && <span style={{ color: 'red' }}> *</span>}
        </Typography>
      </Grid>
      <Grid item>
        <SmallFormAddress
          disabled={!canEdit}
          optionalToolTipTranslationKey={optionalToolTipTranslationKey}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
    {showOld && (
      <Grid alignSelf="end" item textAlign="end" xs={1}>
        {previousValue ? (
          <TextAddress address={previousValue} />
        ) : '-'}
      </Grid>
    )}
  </Grid>
);

const ItemAutoStock = ({
  name, year, value, bold = false, previousValue, showOld = false, optionalToolTipTranslationKey
}: {
  bold?: boolean,
  name: string,
  optionalToolTipTranslationKey?: string,
  previousValue?: any,
  showOld?: boolean,
  value: number,
  year?: number
}) => (
  <ItemStock
    bold={bold}
    name={name}
    previousValue={previousValue}
    showOld={showOld}
    year={year}
  >
    <ConditionalTooltip translationKey={optionalToolTipTranslationKey}>
      <Typography
        fontWeight={bold ? 'bold' : 'unset'}
        gutterBottom
        style={{ margin: bold ? '10px 0' : '2px 0' }}
      >
        {`${value || 0} kg`}
      </Typography>
    </ConditionalTooltip>
  </ItemStock>
);

const CustomDivider = () => (
  <Grid item>
    <Divider
      flexItem
      orientation="horizontal"
      sx={{ mb: '-1px' }}
      variant="middle"
    />
  </Grid>
);

const CustomTabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const CommentSection = ({ comment, handleDeleteComment }) => (
  <Grid
    container
    direction="column"
    item
    style={{
      width: '350px',
      margin: '15px 5px',
      alignSelf: comment.operator ? 'end' : 'start'
    }}
  >
    <Grid container direction="row" item justifyContent="space-between" wrap="nowrap">
      <Grid item>
        {comment.operator ? DateHelper.formatNiceDate(comment.creationDate) : comment.userName}
      </Grid>
      <Grid item>
        {comment.operator ? comment.userName : DateHelper.formatNiceDate(comment.creationDate)}
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      item
      justifyContent="space-between"
      style={{
        maxWidth: '100%',
        wordWrap: 'break-word',
        border: 'solid black 1px',
        padding: '10px',
        borderRadius: '3px',
        backgroundColor: comment.private ? '#DDDDDD' : '#FFFFFF'
      }}
      wrap="nowrap"
    >
      <Grid item whiteSpace="pre-line">
        {comment.comment || '-'}
      </Grid>
      {comment.canDelete && (
        <Grid container item justifyContent="center" xs={1}>
          <DatafluidesTooltip title={translate('button.delete')}>
            <IconButton
              style={{ padding: 2 }}
              onClick={handleDeleteComment}
            >
              <FontAwesomeIcon
                color={iconEnum.trashAlt.defaultColor}
                icon={iconEnum.trashAlt.icon as any}
                size="sm"
                swapOpacity
              />
            </IconButton>
          </DatafluidesTooltip>
        </Grid>
      )}
    </Grid>
    {comment.documents?.length > 0 && (
      <Grid container direction="column" item marginTop="5px">
        {comment.documents.map((doc) => (
          <Grid item key={doc.id}>
            <Document
              base64Content={doc.base64Content}
              canValidate={false}
              deletable={false}
              document={doc}
              name={doc.name}
            />
          </Grid>
        ))}
      </Grid>
    )}
  </Grid>
);

const HistorySection = ({ history, date }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <>
      <Grid
        item
        style={{
          width: '100%',
          margin: '15px 0',
          alignSelf: 'center'
        }}
      >
        <Divider style={{ backgroundColor: 'white', cursor: 'pointer', userSelect: 'none' }} onClick={() => setHidden(!hidden)}>
          {/* eslint-disable-next-line max-len */}
          {`${history.length} ${translate('fluidBalance.updateEntryModal.modificationsFromComplementRequest', { date: DateHelper.formatNiceDate(date) })} (${translate(hidden ? 'common.clickToExtend' : 'common.clickToReduce')})`}
        </Divider>
      </Grid>
      <Grid
        container
        direction="column"
        item
        style={{
          width: '700px',
          alignSelf: 'center',
          maxHeight: hidden ? '0px' : 'unset',
          overflow: 'hidden'
        }}
      >
        {history.map((hist) => {
          if (hist.document) {
            return (
              <Grid container direction="row" item key={shortid.generate()}>
                <Grid item style={{ marginLeft: '30px' }}>
                  <b>{`${hist.fieldName} :`}</b>
                </Grid>
                <Grid item style={{ marginLeft: '10px' }}>
                  <Document
                    base64Content={hist.document.document.base64Content}
                    canValidate={false}
                    deletable={false}
                    document={hist.document.document}
                    name={hist.document.document.name}
                  />
                </Grid>
              </Grid>
            );
          }
          return (
            <Grid item key={shortid.generate()} style={{ marginLeft: '30px' }}>
              <b>{`${hist.fieldName} : `}</b>
              {`${hist.oldValue}  ->  ${hist.newValue}`}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const UpdateFluidBalanceModal = ({
  onClose,
  onSubmit,
  balanceEntry,
  updateEntryInList,
  canEvaluate,
  isEvaluationSide,
  isCategoryVOnly,
  isCategoryVOrVVHUOnly,
  tab,
  year,
  isCreation,
  isOperatorSide,
  fluidOptions,
  fluidOptionsLoading
}: ModalProps<any>) => {
  const defaultValues = {
    ...balanceEntry,
    initialAmountHeldNew: balanceEntry?.initialAmountHeldNew ?? 0,
    initialAmountHeldUsed: balanceEntry?.initialAmountHeldUsed ?? 0,
    finalAmountHeldNew: balanceEntry?.finalAmountHeldNew,
    finalAmountHeldUsed: balanceEntry?.finalAmountHeldUsed,
    amountBoughtInOperatorCountry: balanceEntry?.amountBoughtInOperatorCountry,
    amountBoughtOutsideOperatorCountry: balanceEntry?.amountBoughtOutsideOperatorCountry ?? 0,
    amountDestroyed: balanceEntry?.amountDestroyed ?? 0,
    amountGivenBack: balanceEntry?.amountGivenBack ?? 0,
    amountHandedOver: balanceEntry?.amountHandedOver ?? 0,
    amountLoadedMaintenance: balanceEntry?.amountLoadedMaintenance,
    amountLoadedNewMaterial: balanceEntry?.amountLoadedNewMaterial,
    amountRecoveredMaintenance: balanceEntry?.amountRecoveredMaintenance,
    amountRecoveredOutOfOrder: balanceEntry?.amountRecoveredOutOfOrder,
    amountRecycled: balanceEntry?.amountRecycled ?? 0,
    amountRegenerated: balanceEntry?.amountRegenerated ?? 0,
    deltaInput: balanceEntry?.deltaInput ?? 0,
    deltaOutput: balanceEntry?.deltaOutput ?? 0,
    deltaTotal: balanceEntry?.deltaTotal ?? 0
  };
  const theme = useTheme();
  const formContext = useForm({ defaultValues });
  const { watch, setValue } = formContext;
  const canEdit = !balanceEntry || balanceEntry.canEdit;
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const [fluidBalanceHandedOverJustification, setFluidBalanceHandedOverJustification] = useState([]);
  const [commentDocs, setCommentDocs] = useState([]);
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [tabIndex, setTabIndex] = useState(tab ?? 0);
  const [showOld, setShowOld] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [isUpdatingBalance, setIsUpdatingBalance] = useState(false);
  const [regenerationAddress, setRegenerationAddress] = useState(balanceEntry?.regenerationInstitutionAddress);
  const [destructionAddress, setDestructionAddress] = useState(balanceEntry?.destructionInstitutionAddress);
  const isBackOffice = useAccessRights({ roles: APPLICATION_MANAGEMENT_ROLES });

  useEffect(() => {
    if (balanceEntry && !fluidBalanceHandedOverJustification?.length) {
      FluidBalanceService.getFluidBalanceHandedOverJustification(balanceEntry.id)
        .then((documentList) => setFluidBalanceHandedOverJustification(documentList))
        .catch((error) => enqueueSnackbar(error.message || error, { variant: 'error' }));
    }
    // eslint-disable-next-line
  }, [balanceEntry]);

  const sumValues = useCallback((...names) => names.reduce((prev, curr) => {
    const currentValue = watch(curr);
    return parseInt(currentValue ? (prev ?? 0) + Number(currentValue) : prev, 10);
  }, 0), [watch]);

  const sumPrevious = (...values) => values.reduce(
    (prev, curr) => parseInt(curr ? (prev ?? 0) + Number(curr) : prev, 10),
    0
  );

  const onSubmitModal = useCallback((submittedFields) => {
    onSubmit({
      ...submittedFields,
      fluidBalanceDocumentViews: fluidBalanceHandedOverJustification.map((file) => ({
        id: file?.id,
        document: file?.document,
        type: 'FLUID_BALANCE_HANDED_OVER_JUSTIFICATION'
      })),
      exchangeHistory: [],
      destructionInstitutionAddress: destructionAddress,
      regenerationInstitutionAddress: regenerationAddress
    }, setIsUpdatingBalance);
  }, [onSubmit, fluidBalanceHandedOverJustification, destructionAddress, regenerationAddress]);

  const updateInitialAmountHeld = useCallback(() => (
    setValue('initialAmountHeld', sumValues('initialAmountHeldNew', 'initialAmountHeldUsed'))), [setValue, sumValues]);
  const previousInitialAmountHeld = sumPrevious(
    balanceEntry?.previousEntry?.initialAmountHeldNew,
    balanceEntry?.previousEntry?.initialAmountHeldUsed
  );
  const updateFinalAmountHeld = useCallback(() => (
    setValue('finalAmountHeld', sumValues('finalAmountHeldNew', 'finalAmountHeldUsed'))), [setValue, sumValues]);
  const previousFinalAmountHeld = sumPrevious(
    balanceEntry?.previousEntry?.finalAmountHeldNew,
    balanceEntry?.previousEntry?.finalAmountHeldUsed
  );
  const updateAmountBought = useCallback(() => (
    setValue('amountBought', sumValues('amountBoughtInOperatorCountry', 'amountBoughtOutsideOperatorCountry'))), [setValue, sumValues]);
  const previousAmountBought = sumPrevious(
    balanceEntry?.previousEntry?.amountBoughtInOperatorCountry,
    balanceEntry?.previousEntry?.amountBoughtOutsideOperatorCountry
  );
  const updateAmountLoaded = useCallback(() => (
    setValue('amountLoaded', sumValues('amountLoadedNewMaterial', 'amountLoadedMaintenance'))), [setValue, sumValues]);
  const previousAmountLoaded = sumPrevious(
    balanceEntry?.previousEntry?.amountLoadedNewMaterial,
    balanceEntry?.previousEntry?.amountLoadedMaintenance
  );
  const updateAmountRecovered = useCallback(() => {
    const totalAmountRecovered = sumValues('amountRecoveredOutOfOrder', 'amountRecoveredMaintenance');
    setValue('amountRecovered', totalAmountRecovered);
    if (isCategoryVOnly) {
      setValue('amountRecycled', totalAmountRecovered);
    }
  }, [isCategoryVOnly, setValue, sumValues]);
  const previousAmountRecovered = sumPrevious(
    balanceEntry?.previousEntry?.amountRecoveredOutOfOrder,
    balanceEntry?.previousEntry?.amountRecoveredMaintenance
  );
  const updateAmountProcessed = useCallback(() => (
    setValue('amountProcessed', sumValues('amountRecycled', 'amountRegenerated', 'amountDestroyed'))), [setValue, sumValues]);
  const previousAmountProcessed = sumPrevious(
    balanceEntry?.previousEntry?.amountRecycled,
    balanceEntry?.previousEntry?.amountRegenerated,
    balanceEntry?.previousEntry?.amountDestroyed
  );

  const deltaInput = sumValues('initialAmountHeld', 'amountBought', 'amountRecovered') || 0;
  const deltaOutput = sumValues('finalAmountHeld', 'amountLoaded', 'amountGivenBack', 'amountHandedOver') || 0;

  const previousDeltaInput = sumPrevious(previousInitialAmountHeld, previousAmountBought, previousAmountRecovered);
  const previousDeltaOutput = sumPrevious(
    previousFinalAmountHeld,
    previousAmountLoaded,
    balanceEntry?.previousEntry?.amountGivenBack,
    balanceEntry?.previousEntry?.amountHandedOver
  );

  useEffect(() => {
    updateInitialAmountHeld();
    updateFinalAmountHeld();
    updateAmountBought();
    updateAmountLoaded();
    updateAmountRecovered();
    updateAmountProcessed();
    // eslint-disable-next-line
  }, []);

  const scroll = useCallback(() => {
    if (!scrollRef) {
      return;
    }
    scrollRef.scrollIntoView({ behavior: 'smooth' });
  }, [scrollRef]);

  useEffect(() => {
    scroll();
  }, [scroll]);

  const tabProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const handleUpdateEntryInList = useCallback((entry) => {
    updateEntryInList(entry);
    // eslint-disable-next-line
    balanceEntry.exchangeHistory = [...entry.exchangeHistory];
    // eslint-disable-next-line
    balanceEntry.validationStatus = entry.validationStatus;
    setValue('commentField', watch('commentField'));
  }, [balanceEntry, setValue, updateEntryInList, watch]);

  const handleDeleteJustification = useCallback((file) => open({
    type: 'WARNING',
    onConfirm: () => {
      if (file.id) {
        setFluidBalanceHandedOverJustification(fluidBalanceHandedOverJustification.filter(
          (fileToCompare) => fileToCompare.id !== file.id
        ));
      } else if (fluidBalanceHandedOverJustification.some(
        (doc) => doc?.addingDate === file?.addingDate && doc?.document?.name === file?.document?.name
      )) {
        setFluidBalanceHandedOverJustification(
          fluidBalanceHandedOverJustification.filter(
            (doc) => doc?.addingDate !== file?.addingDate || doc?.document?.name !== file?.document?.name
          )
        );
      }
    }
  }), [fluidBalanceHandedOverJustification, open]);

  const handleDeleteCommentDoc = useCallback((file) => open({
    type: 'WARNING',
    onConfirm: () => {
      setCommentDocs(
        commentDocs.filter(
          (doc) => doc?.addingDate !== file?.addingDate || doc?.document?.name !== file?.document?.name
        )
      );
    }
  }), [commentDocs, open]);

  const handleAddJustification = (doc) => {
    if (doc) {
      const docToSave = {
        addingDate: Date.now(),
        document: {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        }
      };
      setFluidBalanceHandedOverJustification((docsAlreadyPresent) => [...docsAlreadyPresent, docToSave]);
    }
  };

  const handleAddCommentDoc = (doc) => {
    if (doc) {
      const docToSave = {
        addingDate: Date.now(),
        document: {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        }
      };
      setCommentDocs((docsAlreadyPresent) => [...docsAlreadyPresent, docToSave]);
    }
  };

  const handleDeleteComment = useCallback((commentId) => {
    FluidBalanceEntryService.deleteComment(commentId)
      .then(() => {
        handleUpdateEntryInList({
          ...balanceEntry,
          exchangeHistory: [...balanceEntry.exchangeHistory.filter((e) => e.comment?.id !== commentId)]
        });
      });
  }, [balanceEntry, handleUpdateEntryInList]);

  const handleCreateComment = () => {
    if (isCreatingComment) {
      return;
    }
    if (!watch('commentField') && !commentDocs?.length) {
      return;
    }
    setIsCreatingComment(true);
    const newComment = {
      comment: watch('commentField'),
      private: !isPublic,
      documents: commentDocs.map((doc) => ({
        ...doc.document
      }))
    };
    FluidBalanceEntryService.createComment(balanceEntry.id, newComment)
      .then((view) => {
        const newStatus = isBackOffice ? balanceEntry?.validationStatus : FLUID_BALANCE_ENTRY_VALIDATION_STATUS.NONE;
        handleUpdateEntryInList({
          ...balanceEntry,
          validationStatus: newStatus,
          exchangeHistory: [...balanceEntry.exchangeHistory, { comment: view }]
        });
        setCommentDocs([]);
        setValue('commentField', '');
        setTimeout(() => {
          scroll();
        }, 20);
      }).finally(() => {
        setIsCreatingComment(false);
      });
  };

  const handleTabChange = (e, index) => {
    setTabIndex(index);
  };

  const displayExchangeHistory = useCallback(() => (
    <Grid container direction="column">
      {balanceEntry?.exchangeHistory.map((hist) => {
        if (hist.comment) {
          return (
            <CommentSection
              comment={hist.comment}
              handleDeleteComment={() => handleDeleteComment(hist.comment.id)}
              key={hist.comment.id}
            />
          );
        }
        return (
          <HistorySection date={hist?.date} history={hist.history} key={shortid.generate()} />
        );
      })}
      <div
        ref={setScrollRef}
        style={{ float: 'left', clear: 'both' }}
      />
    </Grid>
  ), [balanceEntry?.exchangeHistory, handleDeleteComment]);

  const displayInputSection = () => (
    <Grid
      container
      direction="column"
      padding="20px"
    >
      {commentDocs?.length > 0 && (
        <Grid
          container
          item
          paddingBottom="5px"
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            scrollbarWidth: 'thin'
          }}
          wrap="nowrap"
        >
          {commentDocs.map((file) => (
            <Grid
              item
              key={shortid.generate()}
              style={{
                border: 'dashed 1px black',
                borderRadius: '10px',
                margin: '0 5px',
                padding: '2px 5px'
              }}
            >
              <Document
                base64Content={file.document.base64Content}
                deletable
                document={file}
                handleDelete={() => handleDeleteCommentDoc(file)}
                name={file.document.name}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid
        alignItems="center"
        container
        direction="row"
        flexWrap="nowrap"
        item
        justifyContent="space-between"
        marginTop="5px"
      >
        <Grid alignSelf="center" item marginRight="20px">
          <InputFile
            color="secondary"
            disabled={!canEdit}
            handleAddDocument={handleAddCommentDoc}
            iconOnly
            id="importDocumentsButton"
            optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.commentDocument"
            startIcon={faPaperclip}
          />
        </Grid>
        {isEvaluationSide && (
          <Grid alignSelf="center" item>
            <FormControlLabel
              control={(
                <ConditionalTooltip disableBackground translationKey="fluidBalance.updateEntryModal.toolTip.isPublicComment">
                  <Switch
                    checked={isPublic}
                    color="primary"
                    name="a"
                    required
                    onChange={() => setIsPublic(!isPublic)}
                  />
                </ConditionalTooltip>
              )}
              label={translate('exchange.comment.isPublicComment')}
              labelPlacement="top"
            />

          </Grid>
        )}
        <Grid alignSelf="center" item width="100%">
          <FormInputField
            disabled={!canEdit}
            fullWidth
            helperText={isOperatorSide && translate('fluidBalance.updateEntryModal.operatorCommentMessage')}
            helperTextColor={theme.palette.warning.main}
            label={translate('common.addComment')}
            maxRows={5}
            minRows={2}
            multiline
            name="commentField"
            required
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid
          alignSelf="center"
          container
          item
          justifyContent="center"
          marginLeft="20px"
          xs={2}
        >
          <Grid item maxHeight="40px">
            <Button
              disabled={!canEdit || isCreatingComment}
              startIcon={isCreatingComment ? <ButtonSpinner /> : <FontAwesomeIcon icon={iconEnum.plane.icon as any} />}
              type="primary"
              onClick={handleCreateComment}
            >
              {translate('common.send')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderConditionalInfoTooltip = ((translationKey: string) => (
    <ConditionalInfoTooltip translationKey={translationKey} />
  ));

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'updateFluidBalance',
        style: {
          height: '100%', overflow: 'auto', width: '80vw'
        }
      }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {isCreation
          ? translate('fluidBalance.updateEntryModal.titleCreate')
          : translate('fluidBalance.updateEntryModal.titleUpdate', { name: balanceEntry?.fluid?.name })}
      </ModalHeader>

      <Box style={{ margin: '0px 10px' }} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="basic tabs example" value={tabIndex} variant="fullWidth" onChange={handleTabChange}>
          <Tab label="Détail" {...tabProps(0)} />
          {balanceEntry?.id && <Tab label="Échanges" {...tabProps(1)} />}
        </Tabs>
      </Box>

      <DialogContent style={{
        height: '100%', overflowY: 'auto'
      }}
      >
        <CustomTabPanel index={0} value={tabIndex}>
          <Grid container direction="column" spacing={1}>
            {isCreation && (
              <Grid item>
                <FormSelectField
                  disableClearable
                  label={translate('fluidBalance.updateEntryModal.selectOption')}
                  loading={fluidOptionsLoading}
                  loadingText={translate('common.loading')}
                  name="selectedFluid"
                  noOptionsText={translate('warnings.noOptionsAvailable')}
                  options={fluidOptions}
                  required
                  variant="outlined"
                  onChange={(event, selected) => setValue('fluid', { id: selected.value })}
                />
              </Grid>
            )}
            {balanceEntry?.previousEntry && (
              <Grid container direction="row" item justifyContent="space-between">
                <Grid item textAlign="end" xs={7.5}>
                  {translate('fluidBalance.displayPrecedentYearValues')}
                  <Switch checked={showOld} onChange={() => setShowOld(!showOld)} />
                </Grid>
                <Grid item textAlign="end" xs={showOld ? 2.5 : 3.5}>
                  {`${translate('fluidBalance.valuesFromLabel')} ${year}`}
                </Grid>
                {showOld && (
                  <Grid item textAlign="end" xs={2}>
                    {`${translate('fluidBalance.valuesFromLabel')} ${year - 1}`}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container direction="column" item>
              <ItemSectionTitle
                title="fluidBalance.updateEntryModal.stocksTitle"
                tooltip={renderConditionalInfoTooltip('fluidBalance.updateEntryModal.toolTip.stocksTitle')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('initialAmountHeldNew')}
                name="initialAmountHeldNew"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.initialAmountHeldNew"
                previousValue={`${balanceEntry?.previousEntry?.initialAmountHeldNew ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                year={year}
                onChange={updateInitialAmountHeld}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('initialAmountHeldUsed')}
                name="initialAmountHeldUsed"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.initialAmountHeldUsed"
                previousValue={`${balanceEntry?.previousEntry?.initialAmountHeldUsed ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                year={year}
                onChange={updateInitialAmountHeld}
              />
              <ItemAutoStock
                bold
                name="initialAmountHeld"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.initialAmountHeld"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousInitialAmountHeld || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('initialAmountHeld')}
                year={year}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('finalAmountHeldNew')}
                name="finalAmountHeldNew"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.finalAmountHeldNew"
                previousValue={`${balanceEntry?.previousEntry?.finalAmountHeldNew ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                year={year}
                onChange={updateFinalAmountHeld}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('finalAmountHeldUsed')}
                name="finalAmountHeldUsed"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.finalAmountHeldUsed"
                previousValue={`${balanceEntry?.previousEntry?.finalAmountHeldUsed ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                year={year}
                onChange={updateFinalAmountHeld}
              />
              <ItemAutoStock
                bold
                name="finalAmountHeld"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.finalAmountHeld"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousFinalAmountHeld || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('finalAmountHeld')}
                year={year}
              />
            </Grid>
            <CustomDivider />
            <Grid container direction="column" item>
              <ItemSectionTitle
                title="fluidBalance.updateEntryModal.boughtTitle"
                tooltip={renderConditionalInfoTooltip('fluidBalance.updateEntryModal.toolTip.boughtTitle')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountBoughtInOperatorCountry')}
                name="amountBoughtInOperatorCountry"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountBoughtInOperatorCountry"
                previousValue={`${balanceEntry?.previousEntry?.amountBoughtInOperatorCountry ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountBought}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountBoughtOutsideOperatorCountry')}
                name="amountBoughtOutsideOperatorCountry"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountBoughtOutsideOperatorCountry"
                previousValue={`${balanceEntry?.previousEntry?.amountBoughtOutsideOperatorCountry ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountBought}
              />
              {
                watch('amountBoughtOutsideOperatorCountry') > 0
                && (
                  <Typography color={theme.palette.warning.main} variant="body2">
                    {translate('fluidBalance.updateEntryModal.warningOutsideOfFrance')}
                  </Typography>
                )
              }
              <ItemAutoStock
                bold
                name="amountBought"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountBought"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousAmountBought || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('amountBought')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountLoadedNewMaterial')}
                hasError={balanceEntry?.isRestrictedCategory && watch('amountLoadedNewMaterial') > 0}
                name="amountLoadedNewMaterial"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountLoadedNewMaterial"
                previousValue={`${balanceEntry?.previousEntry?.amountLoadedNewMaterial ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountLoaded}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountLoadedMaintenance')}
                name="amountLoadedMaintenance"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountLoadedMaintenance"
                previousValue={`${balanceEntry?.previousEntry?.amountLoadedMaintenance ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountLoaded}
              />
              <ItemAutoStock
                bold
                name="amountLoaded"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountLoaded"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousAmountLoaded || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('amountLoaded')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountRecoveredOutOfOrder')}
                name="amountRecoveredOutOfOrder"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountRecoveredOutOfOrder"
                previousValue={`${balanceEntry?.previousEntry?.amountRecoveredOutOfOrder ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountRecovered}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountRecoveredMaintenance')}
                name="amountRecoveredMaintenance"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountRecoveredMaintenance"
                previousValue={`${balanceEntry?.previousEntry?.amountRecoveredMaintenance ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountRecovered}
              />
              <ItemAutoStock
                bold
                name="amountRecovered"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountRecovered"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousAmountRecovered || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('amountRecovered')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountGivenBack')}
                name="amountGivenBack"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountGivenBack"
                previousValue={`${balanceEntry?.previousEntry?.amountGivenBack ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
              />
            </Grid>
            <CustomDivider />
            <Grid container direction="column" item>
              <ItemSectionTitle
                title="fluidBalance.updateEntryModal.gapsTitle"
                tooltip={renderConditionalInfoTooltip('fluidBalance.updateEntryModal.toolTip.gapsTitle')}
              />
              <ItemAutoStock
                name="deltaInput"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.deltaInput"
                previousValue={(
                  <Typography
                    fontWeight="unset"
                    gutterBottom
                    style={{ margin: '2px 0' }}
                  >
                    {`${previousDeltaInput || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={deltaInput}
              />
              <ItemAutoStock
                name="deltaOutput"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.deltaOutput"
                previousValue={(
                  <Typography
                    fontWeight="unset"
                    gutterBottom
                    style={{ margin: '2px 0' }}
                  >
                    {`${previousDeltaOutput || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={deltaOutput}
              />
              <ItemAutoStock
                bold
                name="deltaTotal"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.deltaTotal"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${Math.abs((previousDeltaInput - previousDeltaOutput)) || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={Math.abs((deltaInput - deltaOutput))}
              />
            </Grid>
            <CustomDivider />
            <Grid container direction="column" item>
              <ItemSectionTitle
                title="fluidBalance.updateEntryModal.disposalTitle"
                tooltip={renderConditionalInfoTooltip('fluidBalance.updateEntryModal.toolTip.disposalTitle')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountHandedOver')}
                name="amountHandedOver"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountHandedOver"
                previousValue={`${balanceEntry?.previousEntry?.amountHandedOver ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
              />
              <Grid container direction="column" item>
                <Grid item marginTop="10px">
                  <ItemInputText
                    canEdit={canEdit}
                    name="justificationHandedOver"
                    optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.justificationHandedOver"
                    previousValue={balanceEntry?.previousEntry?.justificationHandedOver ?? '-'}
                    required={watch('amountHandedOver') > 0}
                    showOld={showOld}
                  />
                </Grid>
                <Grid item marginTop="10px">
                  <DocumentList
                    canEdit={canEdit}
                    canValidate={false}
                    documents={fluidBalanceHandedOverJustification}
                    importLabel="button.import"
                    optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.documentsJustificationHandedOver"
                    spacing={2.5}
                    titleStyles={{
                      textAlign: 'center',
                      variant: 'h6'
                    }}
                    onDelete={handleDeleteJustification}
                    onImport={handleAddJustification}
                  />
                </Grid>
              </Grid>
            </Grid>
            <CustomDivider />
            <Grid container direction="column" item>
              <ItemSectionTitle
                title="fluidBalance.updateEntryModal.processedTitle"
                tooltip={renderConditionalInfoTooltip('fluidBalance.updateEntryModal.toolTip.processedTitle')}
              />
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountRecycled')}
                hasError={
                  isCategoryVOnly && ((
                    watch('amountRecoveredOutOfOrder') > 0 || watch('amountRecoveredMaintenance') > 0
                  ) && watch('amountRecycled') === 0)
                }
                name="amountRecycled"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountRecycled"
                previousValue={`${balanceEntry?.previousEntry?.amountRecycled ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountProcessed}
              />
              {!isCategoryVOrVVHUOnly && (
                <>
                  <ItemInputStock
                    canEdit={canEdit}
                    defaultValue={watch('amountRegenerated')}
                    name="amountRegenerated"
                    optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountRegenerated"
                    previousValue={`${balanceEntry?.previousEntry?.amountRegenerated ?? 0} kg`}
                    required
                    setValue={setValue}
                    showOld={showOld}
                    onChange={updateAmountProcessed}
                  />
                  <AddressInputText
                    canEdit={canEdit}
                    label="fluidBalance.updateEntryModal.regenerationInstitution"
                    optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.regenerationInstitution"
                    previousValue={balanceEntry?.previousEntry?.regenerationInstitutionAddress}
                    required={watch('amountRegenerated') > 0}
                    showOld={showOld}
                    value={regenerationAddress}
                    onChange={setRegenerationAddress}
                  />
                </>
              )}
              <ItemInputStock
                canEdit={canEdit}
                defaultValue={watch('amountDestroyed')}
                name="amountDestroyed"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountDestroyed"
                previousValue={`${balanceEntry?.previousEntry?.amountDestroyed ?? 0} kg`}
                required
                setValue={setValue}
                showOld={showOld}
                onChange={updateAmountProcessed}
              />
              <AddressInputText
                canEdit={canEdit}
                label="fluidBalance.updateEntryModal.destructionInstitution"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.destructionInstitution"
                previousValue={balanceEntry?.previousEntry?.destructionInstitutionAddress}
                required={watch('amountDestroyed') > 0}
                showOld={showOld}
                value={destructionAddress}
                onChange={setDestructionAddress}
              />
              <ItemAutoStock
                bold
                name="amountProcessed"
                optionalToolTipTranslationKey="fluidBalance.updateEntryModal.toolTip.amountProcessed"
                previousValue={(
                  <Typography
                    fontWeight="bold"
                    gutterBottom
                    style={{ margin: '10px 0' }}
                  >
                    {`${previousAmountProcessed || 0} kg`}
                  </Typography>
                )}
                showOld={showOld}
                value={watch('amountProcessed')}
              />
            </Grid>
          </Grid>
        </CustomTabPanel>

        <CustomTabPanel index={1} value={tabIndex}>
          {displayExchangeHistory()}
        </CustomTabPanel>
      </DialogContent>

      {tabIndex === 0 && (
        <CustomActionButtons disabled={!canEdit} isSaving={isUpdatingBalance} onClose={onClose}>
          {canEvaluate && (
            <StatusValidationToggle
              row={balanceEntry}
              updateEntryInList={handleUpdateEntryInList}
            />
          )}
        </CustomActionButtons>
      )}
      {tabIndex === 1 && displayInputSection()}
    </FormContainer>
  );
};

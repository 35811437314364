import { EmailValidator } from 'commons-validator-js';
import { translate } from 'utils';
import { FLUID_BALANCE_ENTRY_VALIDATION_STATUS } from 'utils/constants';
import { DateHelper } from 'utils/helpers/DateHelper';

const validateEmails = (emails) => {
  const emailValidator = new EmailValidator();
  const notMatchingEmail = emails.find((email) => !emailValidator.isValid(email));
  return (notMatchingEmail !== undefined)
    ? translate('attestationFormRequest.contacts.modalAdd.invalidEmail', { email: notMatchingEmail })
    : true;
};

const isCurrentToolNew = (wasNewAtAcquisition, acquisitionDate) => {
  let isNew = false;
  if (wasNewAtAcquisition && acquisitionDate) {
    isNew = !DateHelper.isMoreThanOneYearInThePast(new Date(acquisitionDate));
  }
  return isNew;
};

const validateEmail = (email) => {
  if (!email) return null;
  const emailValidator = new EmailValidator();
  const isValid = emailValidator.isValid(email);
  if (!isValid) {
    return translate('common.emailNotValid');
  }
  return null;
};

const validateNumber = (value) => typeof value === 'number';

const isACategoryAlert = (entry) => (entry.isCategoryV
      && ((entry.amountRecoveredOutOfOrder > 0 || entry.amountRecoveredMaintenance > 0) && entry.amountRecycled === 0))
    || (entry.isRestrictedCategory && entry.amountLoadedNewMaterial > 0);

const isAnEntryAlert = (entry, isEvaluation) => {
  const isAlert = isACategoryAlert(entry);
  if (isEvaluation) {
    return isAlert
      || entry.amountBoughtOutsideOperatorCountry > 0
      || entry.amountHandedOver > 0
      || entry.amountRegenerated > 0
      || entry.amountDestroyed > 0;
  }
  return isAlert;
};

const checkCompletion = (entry) => {
  if (!entry) {
    return false;
  }
  if (!validateNumber(entry.initialAmountHeldNew)) {
    return false;
  }
  if (!validateNumber(entry.initialAmountHeldUsed)) {
    return false;
  }
  if (!validateNumber(entry.finalAmountHeldNew)) {
    return false;
  }
  if (!validateNumber(entry.finalAmountHeldUsed)) {
    return false;
  }
  if (!validateNumber(entry.amountBoughtInOperatorCountry)) {
    return false;
  }
  if (!validateNumber(entry.amountBoughtOutsideOperatorCountry)) {
    return false;
  }
  if (!validateNumber(entry.amountGivenBack)) {
    return false;
  }
  if (!validateNumber(entry.amountLoadedNewMaterial)) {
    return false;
  }
  if (!validateNumber(entry.amountLoadedMaintenance)) {
    return false;
  }
  if (!validateNumber(entry.amountRecoveredOutOfOrder)) {
    return false;
  }
  if (!validateNumber(entry.amountRecoveredMaintenance)) {
    return false;
  }
  if (!validateNumber(entry.amountHandedOver)) {
    return false;
  }
  if (entry.amountHandedOver > 0) {
    if (!entry.justificationHandedOver && !entry.hasJustificationDocument) {
      return false;
    }
  }
  if (!validateNumber(entry.amountRecycled)) {
    return false;
  }
  if (!validateNumber(entry.amountRegenerated)) {
    return false;
  }
  if (entry.amountRegenerated > 0) {
    if (!entry.regenerationInstitutionAddress?.address1) {
      return false;
    }
  }
  if (!validateNumber(entry.amountDestroyed)) {
    return false;
  }
  if (entry.amountDestroyed > 0) {
    if (!entry.destructionInstitutionAddress?.address1) {
      return false;
    }
  }
  return true;
};

const validateEntry = (entry, isEvaluation = false) => checkCompletion(entry) && !isAnEntryAlert(entry, isEvaluation);

const entryNeedToBeManuallyChecked = (entry) => {
  const noValidationStatus = !entry.validationStatus || entry.validationStatus === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.NONE;
  if (noValidationStatus && !validateEntry(entry, true)) {
    return true;
  }

  return noValidationStatus && isAnEntryAlert(entry, true);
};

const canSubmit = (balance) => {
  if (!balance) {
    return false;
  }
  return balance.fluidBalanceEntries.every(checkCompletion);
};

export const Validators = {
  validateEmails,
  validateEmail,
  isCurrentToolNew,
  entryNeedToBeManuallyChecked,
  validateEntry,
  canSubmit
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  Grid, Table, TableCell, TableHead, TableRow,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ConditionalTooltip } from 'components/_commons/ConditionalTooltip/ConditionalTooltip';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { SkeletonSection, Spinner } from 'components/_commons/Skeletons';
import { TextError } from 'components/_commons/Text';
import React, { useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller
import shortid from 'shortid';
import {
  isMobile, isTablet, translate, translationExist
} from 'utils';
import { iconEnum } from 'utils/icons';
import { InfoTooltip } from '../InfoTooltip';

const GenericTableContainer = styled('div')(() => ({
  position: 'relative',
  maxWidth: '100%',
  paddingTop: '20px',
  paddingBottom: '20px',
  overflowX: 'initial'
}));

const TotalRows = styled(Typography)(({ theme }: any) => ({
  fontSize: '1.4rem',
  color: theme.palette.grey.dark,
  textAlign: 'right',

  '@media (max-width: 768px)': {
    marginRight: '20px'
  }
}));

/**
 * If the translation doesn't exist, the tooltip is not display.
 */
const toolTipFromHeader = ((header) => !header.toolTipOnText && translationExist(header.toolTipLabel) && (
  <Grid item key="toolTip">
    {header.toolTipIcon
      ? (
        <DatafluidesTooltip title={translate(header.toolTipLabel)}>
          <FontAwesomeIcon
            color={iconEnum.info.defaultColor}
            icon={iconEnum.info.icon as any}
            size="xl"
            style={{ marginLeft: 10 }}
          />
        </DatafluidesTooltip>
      )
      : <InfoTooltip label={translate(header.toolTipLabel)} size="1x" />}
  </Grid>
));

const renderToolTipOnText = ((header, children) => (header.toolTipOnText
  ? (
    <ConditionalTooltip translationKey={header.toolTipLabel}>
      {children}
    </ConditionalTooltip>
  ) : (
    children
  )
));

const TableHeader = ({ header }) => (
  header.labels ? (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
    >
      {(header.labels ?? []).map((label) => (
        <Grid item key={label}>
          {renderToolTipOnText(
            header,
            <Typography fontWeight="bold" paddingLeft="5px">
              {label && translate(label)}
            </Typography>
          )}
        </Grid>
      ))}
      {header.toolTipLabel && toolTipFromHeader(header)}
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      <Grid alignContent="center" item>
        {renderToolTipOnText(
          header,
          <Typography fontWeight="bold" paddingLeft="5px">
            {header.label && translate(header.label)}
          </Typography>
        )}
      </Grid>
      {header.toolTipLabel && toolTipFromHeader(header)}
    </Grid>
  )
);

const TableRowComponent = ({
  hover,
  row,
  onRowCLick,
  isSelectable,
  handleSelectClick,
  keyValue,
  isItemSelected,
  headers,
  cursor,
  index
}) => {
  const handleClick = useCallback(() => onRowCLick(row), [onRowCLick, row]);
  const theme: any = useTheme();
  return (
    <TableRow
      hover={hover}
      key={keyValue}
      selected={isItemSelected}
      style={{
        cursor,
        height: theme.sizes.rowHeight
      }}
      onClick={handleClick}
    >
      {isSelectable
        && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              color="primary"
              onChange={() => handleSelectClick(row.id)}
            />
          </TableCell>
        )}
      {headers.map((column) => (
        column.template(row, index)
      ))}
    </TableRow>
  );
};

const defaultKeyFunction = (row) => row.id ?? shortid.generate();

export const GenericTable = ({
  rows,
  dataCy = '',
  dataTestid,
  dataTour = '',
  hasMore = false,
  loadMore,
  headers,
  isSelectable = false,
  selectedRowIds,
  setSelectedRowIds,
  hover = true,
  style,
  onRowClick,
  stickyHeader = true,
  id,
  displayTotal = true,
  noEntriesMessage = 'errors.noEntries',
  noEntriesIcon = iconEnum.emptySet.icon,
  total,
  isLoading = false,
  keyFunction = defaultKeyFunction
}: {
  dataCy?: string,
  dataTestid?: any,
  dataTour?: string,
  displayTotal?: boolean,
  hasMore?: boolean,
  headers: Array<{
    customHeader?: boolean,
    hidden?: boolean,
    hideForMobile?: boolean,
    hideForTablet?: boolean,
    label?: string,
    labels?: string[],
    name: string,
    style?: any,
    template: any,
    toolTipIcon?: any,
    toolTipLabel?: string,
    toolTipOnText?: boolean,
    width?: number
  }>,
  hover?: boolean,
  id: string,
  isLoading?: boolean,
  isSelectable?: boolean,
  keyFunction?: any,
  loadMore?: any,
  noEntriesIcon?: any,
  noEntriesMessage?: string,
  onRowClick?: any,
  rows: Array<any>,
  selectedRowIds?: Array<any>,
  setSelectedRowIds?: any,
  stickyHeader?: boolean,
  style?: any,
  total?: number
}) => {
  const tabletView = isTablet();
  const mobileView = isMobile();
  const theme = useTheme();

  const onSelectAllClick = () => {
    if (selectedRowIds.length === 0) {
      const rowIds = rows.map((r) => r.id);
      setSelectedRowIds(rowIds);
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleSelectClick = (itemId) => {
    const selectedIndex = selectedRowIds.indexOf(itemId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowIds, itemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowIds.slice(1));
    } else if (selectedIndex === selectedRowIds.length - 1) {
      newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowIds.slice(0, selectedIndex),
        selectedRowIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRowIds(newSelected);
  };

  const isItemSelected = (itemId) => (isSelectable ? selectedRowIds.indexOf(itemId) !== -1 : false);

  const handleRowClick = useCallback((row) => onRowClick && onRowClick(row), [onRowClick]);

  const handleLoadMore = useCallback(() => loadMore && loadMore(), [loadMore]);

  const filteredHeaders = useMemo(() => (
    headers.filter((header) => (!tabletView || !header.hideForTablet) && (!mobileView || !header.hideForMobile) && !header.hidden)
  ), [headers, tabletView, mobileView]);

  return (isLoading && !rows?.length)
    ? (
      <GenericTableContainer>
        <Spinner />
      </GenericTableContainer>
    ) : (
      <GenericTableContainer
        data-cy={dataCy}
        data-testid={dataTestid}
        data-tour={dataTour}
        style={style}
      >
        {displayTotal && (
          <TotalRows gutterBottom>
            {`${rows.length} / ${total || 0} ${translate('common.totalDisplayed')}`}
          </TotalRows>
        )}

        {
          rows.length ? (
            <Table
              size="small"
              stickyHeader={stickyHeader}
            >
              <TableHead>
                <TableRow>
                  {
                    isSelectable
                    && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={rows.length > 0 && selectedRowIds.length > 0 && rows
                            .map((row) => row.id)
                            .every((rid) => selectedRowIds.includes(rid))}
                          color="primary"
                          indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < rows.length}
                          onChange={onSelectAllClick}
                        />
                      </TableCell>
                    )
                  }
                  {filteredHeaders.map((header) => (
                    <TableCell key={header.name} style={{ width: header.width || 'initial', ...header.style }}>
                      <TableHeader header={header} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <InfiniteScroll
                element="tbody"
                hasMore={hasMore}
                id={id}
                initialLoad={false}
                loadMore={handleLoadMore}
                pageStart={0}
                style={{ minHeight: '1200px' }}
                threshold={50}
              >
                {rows
                  .slice()
                  .map((row, index) => (
                    <TableRowComponent
                      cursor={onRowClick ? 'pointer' : 'initial'}
                      handleSelectClick={handleSelectClick}
                      headers={filteredHeaders}
                      hover={hover}
                      index={index}
                      isItemSelected={isItemSelected(row.id)}
                      isSelectable={isSelectable}
                      key={keyFunction(row)}
                      keyValue={keyFunction(row)}
                      row={row}
                      onRowCLick={handleRowClick}
                    />
                  ))}
              </InfiniteScroll>
            </Table>
          ) : (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color={theme.palette.primary.main} icon={noEntriesIcon} size="3x" />
              <TextError>{translate(noEntriesMessage)}</TextError>
            </Grid>
          )
        }
        {isLoading && <SkeletonSection />}
      </GenericTableContainer>
    );
};

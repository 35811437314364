import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import { ModalSectionTitle } from 'components/_commons/Modals/common/ModalSectionTitle';
import React from 'react';

import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { Banner } from 'components/_commons/Modals/common/BannerItems';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import { Button } from '../../Button';

export const StyledInput = styled('div')(() => ({
  margin: '5px'
}));

export const SelectEntity = ({
  isEdition, newEntityValue, value, children, entityValue,
  loading, loadOptions, name, options, required, title, buttonTitle, onChange, onCreate,
  getOptionLabel, selectLabel, selectPlaceholder, renderSelectOption, createTooltip
}) => {
  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={2}>
      {!isEdition && (
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <ModalSectionTitle title={title} />
            <Grid alignItems="center" container item spacing={2}>
              <Grid item md={6} xs={10}>
                <FormSelectField
                  filterOptions={(optionArray) => optionArray}
                  fullWidth
                  getOptionLabel={getOptionLabel}
                  label={selectLabel}
                  loading={loading}
                  loadingText={translate('common.loading')}
                  name={name}
                  noOptionsText={translate('warnings.noOptionsAvailable')}
                  options={options}
                  placeholder={selectPlaceholder}
                  renderOption={renderSelectOption}
                  required={required}
                  variant="outlined"
                  onChange={onChange}
                  onInputChange={loadOptions}
                />
              </Grid>
              <Grid item xs={2}>
                <DatafluidesTooltip title={createTooltip}>
                  <Button
                    onClick={onCreate}
                  >
                    {translate(buttonTitle)}
                  </Button>
                </DatafluidesTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(value || !required) && (
        <>
          <Grid color={theme.palette.primary.lightest} item xs>
            <Banner
              avatar={(value ? entityValue : newEntityValue)?.avatar}
              description={(value ? entityValue : newEntityValue)?.description}
              title={(value ? entityValue : newEntityValue)?.title}
            />
          </Grid>
          {children}
        </>
      )}
    </Grid>
  );
};

SelectEntity.propTypes = {
  buttonTitle: PropTypes.string.isRequired
};

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonClose = styled(IconButton)(() => ({
  position: 'absolute !important',
  right: 0,
  top: 0
}));

export const CloseButton = ({
  onClose, iconSize, isCloseDisabled
}) => {
  const theme = useTheme();

  return (
    <ButtonClose aria-label="close" disabled={isCloseDisabled} onClick={onClose}>
      <FontAwesomeIcon
        color={isCloseDisabled ? theme.palette.grey.dark : theme.palette.common.white}
        icon={faXmark}
        style={{ margin: 0, fontSize: iconSize ?? '3rem' }}
      />
    </ButtonClose>
  );
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  iconSize: PropTypes.string,
  isCloseDisabled: PropTypes.bool
};

CloseButton.defaultProps = {
  iconSize: null,
  isCloseDisabled: false
};

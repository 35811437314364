import { useTheme } from '@mui/material';
import React from 'react';
import { OtherProps } from 'utils';

export const TextButton = ({
  label, tooltip, onClick, styles, ...props
}: {
  label: string,
  onClick: any,
  styles?: any,
  tooltip?: string
} & OtherProps) => {
  const theme = useTheme();

  return (
    <button
      style={{
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.6rem',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        width: '100%',
        textAlign: 'left',
        ...styles
      }}
      title={tooltip}
      type="button"
      onClick={onClick}
      {...props}
    >
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block'
        }}
      >
        {label}
      </span>
    </button>
  );
};

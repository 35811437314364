import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import React from 'react';
import { isTablet } from 'utils';

export const GenericTooltip = ({
  children, title, open, onClickAwayTablet, ...props
}) => (isTablet()
  ? (
    <ClickAwayListener onClickAway={open && onClickAwayTablet}>
      <DatafluidesTooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        PopperProps={{
          disablePortal: true
        }}
        title={title}
        {...props}
      >
        {children}
      </DatafluidesTooltip>
    </ClickAwayListener>
  )
  : (
    <DatafluidesTooltip title={title} {...props}>
      {children}
    </DatafluidesTooltip>
  ));

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';

const StyledContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  borderRadius: '2px',
  padding: 10
}));

const StyledFormActions = styled('div')(() => ({
  alignItems: 'center'
}));

export const WarningHeader = (({ children, icon }) => {
  const theme = useTheme();

  return (
    <StyledFormActions id="warning">
      <StyledContainer alignItems="center" container justifyContent="center">
        <Grid item justifyContent="center">
          <FontAwesomeIcon
            color={theme.palette.warning.contrastText}
            icon={icon}
          />
        </Grid>
        <Grid item justifyContent="center" marginLeft="10px">
          {children}
        </Grid>
      </StyledContainer>
    </StyledFormActions>
  );
});

WarningHeader.defaultProps = {
  icon: faExclamationTriangle
};

import { styled } from '@mui/material/styles';
import React from 'react';
import { OtherProps } from 'utils';

const StyledFlag = styled('img')(({ theme }: any) => ({
  verticalAlign: 'middle',
  boxShadow: `0 2px 6px ${theme.palette.grey.main}`,
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  boxSizing: 'border-box',
  margin: 'auto'
}));

const FlagImg = ({
  alt, width = 40, isInsideSelect, countryCode, ...props
}) => (
  <StyledFlag
    alt={alt}
    src={isInsideSelect ? `https://flagcdn.com/w40/${countryCode}.png` : `https://flagcdn.com/${countryCode}.svg`}
    width={isInsideSelect ? 'auto' : width}
    {...props}
  />
);

const FlagContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const Flag = ({
  countryCode, alt = 'country-flag', width = 40, isInsideSelect = false, ...props
}: {
  alt?: string,
  countryCode: string,
  isInsideSelect?: boolean,
  width?: number
} & OtherProps) => (
  <FlagContainer style={{ height: isInsideSelect ? '40px' : 'auto', width: isInsideSelect ? 'auto' : width }}>
    <FlagImg alt={alt} countryCode={countryCode} isInsideSelect={isInsideSelect} props={props} />
  </FlagContainer>
);

export default Flag;

import { faBuilding, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, Typography
} from '@mui/material';
import { CloseModalButton } from 'components/_commons/Modals/CloseModalButton';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { translate, UserHelper } from 'utils';

import { useTheme } from '@mui/material/styles';
import { SelectItemWithLeftElement } from 'components/_commons/Form';
import FormAddress from 'components/_commons/Form/FormAddress/FormAddress';
import { CheckboxGroup, FormInputField } from 'components/_commons/Form/Inputs';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { useFetch, useModal } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { keyBy, mapValues } from 'lodash';
import { useSnackbar } from 'notistack';
import { CompanyService, CountryService } from 'services';
import { EnumService, enumValueTypes } from 'services/EnumService';
import { i18nStore } from 'stores';
import { countryItems, STRUCTURE_TYPE } from 'utils/constants';
import { CustomActionButtons } from './_CustomActionButtons';

export const CreateInstitutionModal = ({
  onSubmit, onClose, closeOnSubmit, defaultValues = {}, parentInstitutionGroupId, parentCompanyId,
  withCategories, notEditable, isUpdatingSituation
}: ModalProps<any>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetchingIdentifierFields, setIsFetchingIdentifierFields] = useState(true);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [isSearchingSiret, setIsSearchingSiret] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [countriesSelectItems, setCountriesSelectItems] = useState([]);
  const [country, setCountry] = useState(defaultValues.country ?? countryItems.france);
  const [isCreatingInstitution, setIsCreatingInstitution] = useState(false);
  const { currentLanguage } = i18nStore;
  const { response: attestationCategories } = useFetch(
    () => EnumService.getEnumValues(enumValueTypes.ATTESTATION_CATEGORY),
    [],
    []
  );
  const theme = useTheme();
  const { open } = useModal();
  const options = (attestationCategories ?? []).map((opt) => ({ value: opt, label: opt }));
  const initValues = {
    ...defaultValues,
    activated: defaultValues.activated !== null ? defaultValues.activated : true,
    address1: (defaultValues.address?.address1) || '',
    address2: (defaultValues.address?.address2) || '',
    postalCode: (defaultValues.address?.postalCode) || '',
    city: (defaultValues.address?.city) || '',
    automaticCerfaDelivery: (defaultValues.automaticCerfaDelivery) || false,
    institutionName: defaultValues.name || ''
  };
  const formContext = useForm<any>({ defaultValues: initValues });
  const { watch, setValue } = formContext;
  const editTitle = notEditable ? translate('modalInstitution.viewInstitution') : translate('modalInstitution.editInstitution');

  useEffect(() => {
    if (defaultValues.id) {
      setIsPublic(defaultValues.isPublic);
    } else {
      CompanyService.isPublic(parentCompanyId)
        .then(setIsPublic);
    }
    // eslint-disable-next-line
  }, []);

  const initValueIdentifierField = useCallback((identifierField) => {
    const defaultIdentifier = defaultValues.identifiers.find((identifier) => identifier.idField === identifierField.idField);
    return defaultIdentifier
      ? {
        ...identifierField,
        value: defaultIdentifier.value,
        idValue: defaultIdentifier.idValue,
        suffix: defaultIdentifier.suffix
      } : identifierField;
  }, [defaultValues.identifiers]);

  const initValueIdentifierFields = useCallback((identifierFieldsToInit) => {
    if (defaultValues.identifiers) {
      const initializedIdentifierValues = identifierFieldsToInit
        .map((identifierField) => initValueIdentifierField(identifierField));
      setValue('identifiers', mapValues(keyBy(initializedIdentifierValues, 'name')));
    } else {
      setValue('identifiers', mapValues(keyBy(identifierFieldsToInit, 'name')));
    }
  }, [defaultValues.identifiers, initValueIdentifierField, setValue]);

  const fetchIdentifierFields = useCallback(() => {
    setIsFetchingIdentifierFields(true);

    CountryService.getIdentifiersByCountryId(country.value)
      .then((resp) => {
        initValueIdentifierFields(resp.filter((identifierField) => identifierField.type === STRUCTURE_TYPE.INSTITUTION_MODEL));
        setIsFetchingIdentifierFields(false);
      }).then(() => {

      });
  }, [country, initValueIdentifierFields]);

  useEffect(() => {
    setIsFetchingCountries(true);
    CountryService.getCountrySelectItems()
      .then((json) => {
        setCountriesSelectItems(json);
      })
      .finally(() => setIsFetchingCountries(false));
      // eslint-disable-next-line
  }, []);

  const getInstitutionBySiret = useCallback((siret) => {
    setIsSearchingSiret(true);
    CompanyService.getInstitutionBySiret(siret).then((response) => {
      if (response.header && response.header.statut === 200) {
        const tempInstitution = response.etablissement.uniteLegale.denominationUniteLegale;
        const tempAddress = response.etablissement.adresseEtablissement.codePostalEtablissement;
        setValue('name', tempInstitution);
        if (tempAddress) {
          setValue(
            'address1',
            `${response.etablissement.adresseEtablissement.numeroVoieEtablissement || ''} 
            ${response.etablissement.adresseEtablissement.typeVoieEtablissement || ''} 
            ${response.etablissement.adresseEtablissement.libelleVoieEtablissement || ''}`
          );
          setValue('address2', response.etablissement.adresseEtablissement.complementAdresseEtablissement || '');
          setValue('postalCode', tempAddress);
          setValue('city', response.etablissement.adresseEtablissement.libelleCommuneEtablissement || '');
        }
      } else {
        enqueueSnackbar(response.header.message, { variant: 'warning', autoHideDuration: 5000 });
      }
    })
      .catch(() => enqueueSnackbar(translate('errors.noMatchingSiret'), { variant: 'warning' }))
      .finally(() => setIsSearchingSiret(false));
  }, [enqueueSnackbar, setValue]);

  const handleChangePrimaryIdentifier = (identifier) => {
    if (identifier.primary
        && !watch('name')
        && country.countryCode === 'fr'
        && !isSearchingSiret) {
      if (identifier.value?.match(`^${identifier.rule?.format}$`)) {
        getInstitutionBySiret(identifier.value);
      }
    }
  };

  useEffect(() => {
    fetchIdentifierFields();
    setValue('country', country);
    // eslint-disable-next-line
  }, [country]);

  const handleChangeSelectedCountry = useCallback((countryItem) => {
    setCountry(countryItem);
  }, []);

  useEffect(() => {
    const defaultCountry = UserHelper.getDefaultCountry(defaultValues.address);
    CountryService.getCountrySelectItems()
      .then((countries) => {
        setCountry(countries.find((countryItem) => countryItem.value.trim() === defaultCountry.value.trim()));
        setValue('country', countries.find((countryItem) => countryItem.value.trim() === defaultCountry.value.trim()));
      });
    // eslint-disable-next-line
    }, []);

  const submit = useCallback(() => {
    setValue('identifiers', Object.values(watch('identifiers')));
    const newInstitution = {
      ...formContext.getValues(),
      id: defaultValues.id || null,
      name: watch('name') || '',
      address: {
        hashId: (defaultValues.address && defaultValues.address.hashId) || null,
        address1: watch('address1'),
        address2: watch('address2'),
        postalCode: watch('postalCode'),
        city: watch('city'),
        country: {
          ...watch('country'),
          key: watch('country') && watch('country.countryCode')
        }
      },
      parentInstitutionGroupId,
      parentCompanyId
    };

    onSubmit(newInstitution, setIsCreatingInstitution);

    if (closeOnSubmit) {
      onClose();
    }
  }, [setValue, watch, formContext, defaultValues.id, defaultValues.address, parentInstitutionGroupId, parentCompanyId, onSubmit, closeOnSubmit, onClose]);

  const handleValidateModal = useCallback(() => {
    if (isUpdatingSituation) {
      open({
        type: 'CONFIRM',
        onConfirm: () => submit(),
        title: 'attestationFormRequest.actions.situationUpdateRequest',
        content: translate('attestationFormRequest.confirmation.situationUpdateRequest')
      });
    } else {
      submit();
    }
  }, [isUpdatingSituation, open, submit]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'institutionForm',
        style: { overflow: 'auto' }
      }}
      onSuccess={handleValidateModal}
    >
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding as any} />
        <Typography component="span">
          {defaultValues && defaultValues.name
            ? editTitle
            : translate('modalInstitution.newInstitution')}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ width: '650px', paddingRight: 20 }}>
        <Grid container direction="column">
          {notEditable && (
            <Typography color={theme.palette.primary.main} marginTop={1} variant="h5">
              {translate('modalInstitution.information.howToEdit')}
            </Typography>
          )}
          {isUpdatingSituation && (
            <Typography color={theme.palette.warning.main} marginTop={1} variant="h5">
              {translate('modalInstitution.warning.updatingSituation')}
            </Typography>
          )}
          <Typography margin={1} variant="h6">
            {translate('common.country')}
          </Typography>
          <SelectItemWithLeftElement
            isDisabled={notEditable}
            isFlagElement
            isLoading={isFetchingCountries}
            label={notEditable ? null : 'common.selectCountry'}
            name="country"
            options={countriesSelectItems}
            value={country}
            onChange={handleChangeSelectedCountry}
          />

          <Typography margin={1} variant="h6">
            {translate('common.institution')}
          </Typography>

          {isFetchingIdentifierFields ? (
            <Grid item>
              <FontAwesomeIcon icon={faSpinner as any} spin />
              {translate('common.loading')}
            </Grid>
          )
            : (Object.values(watch('identifiers')).map((identifierField: any) => {
              const {
                idField, name, required, rule
              } = identifierField;
              const regexDesc = rule?.translations?.find((val) => val.code === currentLanguage)?.label ?? '';

              return (
                <>
                  <FormInputField
                    disabled={notEditable}
                    helperText={regexDesc}
                    key={idField}
                    label={name}
                    name={`identifiers.${name}.value`}
                    required={required}
                    validation={{
                      pattern: {
                        value: rule?.format ? new RegExp(`^${rule.format}$`) : '',
                        message: regexDesc
                      }
                    }}
                    onChange={() => handleChangePrimaryIdentifier(identifierField)}
                  />
                  {isPublic && identifierField.primary && (
                    <FormInputField
                      disabled={notEditable}
                      label={translate('common.identifierSuffix')}
                      name={`identifiers.${name}.suffix`}
                      required
                    />
                  )}
                </>
              );
            }))}
          <Grid alignItems="center" container direction="row" marginTop={2}>
            <Grid display="flex" flexGrow={1} item>
              <FormInputField
                disabled={notEditable}
                endAdornment={(isSearchingSiret && <FontAwesomeIcon icon={faSpinner as any} spin />)}
                fullWidth
                label={translate('common.institutionName')}
                name="name"
                required
              />
            </Grid>

          </Grid>

          {/* This input is only used for forcing disabling autocomplete in chrome browser */}
          <input name="fakeInputForForcingDisablingAutocompleteChrome3" style={{ display: 'none' }} type="text" />

          <div style={{ margin: '1rem 0' }} />

          {withCategories && (
            <>
              <Typography margin={1} variant="h6">
                {translate('institutionModal.attestationCategories')}
              </Typography>
              <CheckboxGroup
                name="attestationCategories"
                options={options}
                required
                row
              />
            </>
          )}

          <div style={{ margin: '1rem 0' }} />

          <FormAddress
            disabled={notEditable}
            formContext={formContext}
          />
        </Grid>
      </DialogContent>

      {notEditable
        ? (
          <CloseModalButton onClose={onClose} />
        )
        : (
          <CustomActionButtons
            isSaving={isCreatingInstitution}
            isValidated={defaultValues.validated}
            onClose={onClose}
          />
        )}
    </FormContainer>
  );
};

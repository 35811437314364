import { FormControl, Grid } from '@mui/material';
import SelectField from 'components/_commons/Form/Inputs/SelectField/SelectField';
import { ActivityRoleSelector } from 'components/_commons/Modals/common/ActivityRoleSelector';
import { SelectLabel } from 'components/_commons/Text';
import { useEffectAfterRender } from 'hooks/UseEffectAfterRender';
import {
  compact, isEmpty, isEqual, unionWith, uniqWith
} from 'lodash';
import { groupBy } from 'lodash/collection';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils';

export const UpdateRolesForm = ({
  accessibleRolesByStructureLevel, initialRoles, formContext
}) => {
  const getAvailableStructureLevels = useCallback(
    () => Array.from(accessibleRolesByStructureLevel.keys()),
    [accessibleRolesByStructureLevel]
  );

  const getAvailableStructureLevel = useCallback(
    (structureLevel) => getAvailableStructureLevels()
      .find(
        (structureLevelOption) => structureLevelOption?.value === (
          structureLevel?.value
            ? structureLevel.value
            : null
        ) // The structure level with "null" as value is the key to global roles in map
      ),
    [getAvailableStructureLevels]
  );

  const getAvailableRolesByLevel = useCallback(
    (structureLevel) => accessibleRolesByStructureLevel.get(getAvailableStructureLevel(structureLevel)),
    [accessibleRolesByStructureLevel, getAvailableStructureLevel]
  );

  const getAvailableStructureLevelsForActivity = useCallback(
    () => getAvailableStructureLevels().filter(
      // The structure level with "null" as value is the key to global roles in map
      (structureLevelOption) => !!structureLevelOption?.value
        && getAvailableRolesByLevel(structureLevelOption)?.length > 0
    ),
    [getAvailableStructureLevels, getAvailableRolesByLevel]
  );

  const isAccessibleRole = useCallback(
    (role, structureLevel) => getAvailableRolesByLevel(structureLevel)?.map((selectItem) => selectItem?.value)
      .includes(role?.value),
    [getAvailableRolesByLevel]
  );

  const isAtLeastOneImmutableRole = useCallback(
    (roles, structureLevel) => roles.some((role) => !isAccessibleRole(role, structureLevel)),
    [isAccessibleRole]
  );

  const compareRoles = ((role1, role2) => {
    if (role1.clearableValue && !role2.clearableValue) {
      return 1;
    }
    if (!role1.clearableValue && role2.clearableValue) {
      return -1;
    }
    return 1;
  });

  const rolesByNeedStructureLevel = groupBy(initialRoles ?? [], 'needStructureLevel');

  const [activityRoles, setActivityRoles] = useState(rolesByNeedStructureLevel.true ? rolesByNeedStructureLevel.true.map(
    (value) => {
      const accessibleRoles = getAvailableRolesByLevel(value?.structureLevel);
      return ({
        ...value,
        clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
      });
    }
  ) : []);
  const [globalRoles, setGlobalRoles] = useState(rolesByNeedStructureLevel.false ? rolesByNeedStructureLevel.false.map(
    (value) => {
      // eslint-disable-next-line max-len
      const accessibleRoles = getAvailableRolesByLevel(null); // The structure level with "null" as value is the key to global roles in map
      return ({
        ...value,
        clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
      });
    }
  ) : []);
  const [structureLevels, setStructureLevels] = useState(
    compact([...uniqWith(activityRoles.map((r) => r.structureLevel), isEqual)])
  );

  const getActivityRolesByLevel = useCallback(
    (structureLevel) => activityRoles
      .filter((r) => r.structureLevel?.value === structureLevel?.value),
    [activityRoles]
  );

  useEffect(() => {
    formContext.setValue('roles', unionWith(globalRoles, activityRoles, isEqual));
    // eslint-disable-next-line
  }, [activityRoles, globalRoles]);

  const clearData = () => {
    setStructureLevels([]);
    setGlobalRoles([]);
    setActivityRoles([]);
    formContext.setValue('roles', []);
  };

  useEffectAfterRender(() => {
    clearData();
  }, [accessibleRolesByStructureLevel]);

  useEffect(() => {
    const lastStructureLevel = structureLevels.slice(-1)[0];
    if (structureLevels.length < getAvailableStructureLevels().length) {
      if (!lastStructureLevel || Object.keys(lastStructureLevel).length !== 0) {
        setStructureLevels([...structureLevels, {}]);
      }
    }
  }, [structureLevels, getAvailableStructureLevels]);

  const updateStructureLevelRoles = (level, index) => {
    const previousStructureLevel = structureLevels[index];
    if (previousStructureLevel) {
      setActivityRoles(activityRoles.filter((r) => r.structureLevel?.value !== previousStructureLevel?.value));
    }
    if (level != null) {
      setStructureLevels([...structureLevels.slice(0, index), level, ...structureLevels.slice(index + 1)]);
    } else {
      setStructureLevels([...structureLevels.slice(0, index), ...structureLevels.slice(index + 1)]);
    }
  };

  const handleChangeLevelRoles = (newRoles, structureLevel) => {
    const activityRolesNotForCurrentLevel = activityRoles
      .filter((r) => r.structureLevel?.value !== structureLevel?.value)
      .map(
        (value) => {
          const accessibleRoles = getAvailableRolesByLevel(structureLevel);
          return ({
            ...value,
            clearableValue: accessibleRoles ? accessibleRoles.map((role) => role.value).includes(value.value) : false
          });
        }
      );
    setActivityRoles(unionWith(activityRolesNotForCurrentLevel, newRoles, isEqual));
  };

  return (
    <Grid container direction="row" item spacing={2}>
      {(!isEmpty(getAvailableRolesByLevel(null))) && (
        <Grid item xs={12}>
          <SelectLabel>{translate('pageUserDetails.modal.editGlobalRoles')}</SelectLabel>
          <FormControl fullWidth variant="standard">
            <SelectField
              closeMenuOnSelect={false}
              isClearable={!isAtLeastOneImmutableRole(globalRoles, null)}
              isMulti
              isOptionDisabled={(option) => !isAccessibleRole(option, null)}
              label="common.roles"
              name="roles"
              options={
                getAvailableRolesByLevel(null).map((role) => {
                  const accessibleRoles = getAvailableRolesByLevel(null);
                  return (role.clearableValue
                    ? role
                    : ({
                      ...role,
                      clearableValue: accessibleRoles?.map((r) => r.value).includes(role.value)
                    })
                  );
                })
              }
              value={globalRoles.sort((role1, role2) => compareRoles(role1, role2))}
              onChange={setGlobalRoles}
            />
          </FormControl>
        </Grid>
      )}
      {(getAvailableStructureLevelsForActivity().length > 0 || !isEmpty(activityRoles)) && ( // At least one role for activity
        <Grid item xs={12}>
          <SelectLabel>{translate('pageUserDetails.modal.editActivityRoles')}</SelectLabel>
          {structureLevels.map((structureLevel, index) => {
            const isLevelDisabled = isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel);
            return (
              <ActivityRoleSelector
                accessibleLevels={getAvailableStructureLevelsForActivity()}
                accessibleRoles={getAvailableRolesByLevel(structureLevel)}
                compareRoles={compareRoles}
                defaultLevel={structureLevel}
                defaultLevelRoles={getActivityRolesByLevel(structureLevel)}
                handleChangeLevel={(newLevel) => updateStructureLevelRoles(newLevel, index)}
                handleChangeLevelRoles={(newRoles) => handleChangeLevelRoles(newRoles, structureLevel)}
                isClearable={!isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel)}
                isLevelDisabled={isLevelDisabled}
                key={structureLevel?.value ?? index}
                selectedLevels={structureLevels}
              />
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

import * as React from 'react';
import { ToggleButtonGroupElement } from 'react-hook-form-mui';

import { FormControl, FormGroup } from '@mui/material';
import { OtherProps } from 'utils';

export const ToggleButtons = ({
  name, size, exclusive = true, options = [], enforceSelection = false, ...props
}: {
  enforceSelection?: boolean,
  exclusive?: boolean,
  name: string,
  options?: Array<any>,
  size?: any
} & OtherProps) => (
  <FormControl component="fieldset">
    <FormGroup aria-label="position">
      <ToggleButtonGroupElement
        enforceAtLeastOneSelected={enforceSelection}
        exclusive={exclusive}
        name={name}
        options={options}
        size={size}
        {...props}
      />
    </FormGroup>
  </FormControl>
);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, Typography, useTheme
} from '@mui/material';
import { DocumentList } from 'components/_commons/Document/DocumentList';

import RadioButtonGroupFixed from 'components/_commons/Form/Inputs/RadioButton/RadioButtonGroupFixed';
import { GenericTooltip } from 'components/_commons/GenericTooltip/GenericTooltip';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { AttestationFormRequestService, DocumentService } from 'services';
import {
  DocumentHelper, isMobile, isTablet, translate
} from 'utils';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';
import { iconEnum } from 'utils/icons';

export const OrganizationBlock = ({
  currentOrganization,
  changeOrganization,
  organizations,
  availableActions,
  requestId,
  updateAlerts
}) => {
  const formContext = useForm({ defaultValues: { selectedType: currentOrganization } });
  const [workModeJustification, setWorkModeJustification] = useState({});
  const theme = useTheme();
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isOtherOrganization, setIsOtherOrganization] = useState(currentOrganization === 'OTHER');
  const [openedToolTip, setOpenedToolTip] = useState(null);

  useEffect(() => {
    if (currentOrganization) {
      setIsOtherOrganization(currentOrganization === 'OTHER');
    }
  }, [currentOrganization]);

  const organizationsWithTooltip = useCallback(() => (
    organizations.map((organization, index) => ({
      ...organization,
      endAdornment: (
        <GenericTooltip
          open={openedToolTip === index}
          title={organization.tooltip}
          onClickAwayTablet={() => setOpenedToolTip(null)}
        >
          {isTablet()
            ? (
              <IconButton onClick={() => setOpenedToolTip(index)}>
                <FontAwesomeIcon
                  color={iconEnum.info.defaultColor}
                  icon={iconEnum.info.icon}
                  size="lg"
                  style={{ marginLeft: 10 }}
                />
              </IconButton>
            )
            : (
              <FontAwesomeIcon
                color={iconEnum.info.defaultColor}
                icon={iconEnum.info.icon}
                size="lg"
                style={{ marginLeft: 10 }}
              />
            )}
        </GenericTooltip>
      )
    }))), [organizations, openedToolTip, setOpenedToolTip]);

  useEffect(() => {
    if (isOtherOrganization) {
      AttestationFormRequestService.getWorkModeJustificationDocument(requestId)
        .then((resp) => {
          setWorkModeJustification(resp);
        })
        .catch(() => {
          setWorkModeJustification({});
        })
        .finally(() => updateAlerts(SectionEnum.Operators));
    }
    // eslint-disable-next-line
  }, [updateAlerts, isOtherOrganization]);

  const handleAddJustification = (doc, setIsSavingDocument) => {
    if (doc) {
      setIsSavingDocument(true);
      AttestationFormRequestService.saveDocument(
        requestId,
        {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        },
        'WORK_MODE_JUSTIFICATION'
      )
        .then((resp) => {
          setWorkModeJustification({
            document: {
              ...doc,
              base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
            },
            documentId: resp.documentId,
            id: resp.id
          });
          updateAlerts(SectionEnum.Operators);
        })
        .finally(() => {
          setIsSavingDocument(false);
          enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentSaved'), { variant: 'success' });
        });
    }
  };

  const handleDeleteJustification = useCallback((file) => open({
    type: 'WARNING',
    onConfirm: () => {
      if (file.id) {
        DocumentService.deleteAttestationFormDocument(file.id)
          .then(() => {
            setWorkModeJustification({});
            updateAlerts(SectionEnum.Operators);
          })
          .then(() => {
            enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentDeleted'), { variant: 'success' });
          });
      }
    }
  }), [open, updateAlerts, enqueueSnackbar]);

  const handleValidation = useCallback((id, validation) => {
    setWorkModeJustification({ ...workModeJustification, validation });
  }, [workModeJustification]);

  const changeOrganizationWithConfirmation = (newOrganization) => {
    const isLeavingOtherMode = newOrganization !== 'OTHER' && isOtherOrganization;
    if (isLeavingOtherMode && workModeJustification.id) {
      open({
        type: 'WARNING',
        title: translate('operatorsStep.organization.leavingOtherWorkMode.title'),
        text: translate('operatorsStep.organization.leavingOtherWorkMode.content'),
        onConfirm: () => {
          changeOrganization(newOrganization);
          setWorkModeJustification({});
        }
      });
    } else {
      changeOrganization(newOrganization);
    }
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'radioButtonGroupFixed'
      }}
    >
      <RadioButtonGroupFixed
        controlledValue={currentOrganization === 'OTHER' && workModeJustification.id ? currentOrganization : undefined}
        itemStyles={{
          padding: '0.5rem 1.5rem 0.5rem 0.25rem',
          border: `1px solid ${theme.palette.grey.light}`,
          borderRadius: '6px'
        }}
        labelStyles={{
          fontWeight: 600,
          fontSize: 18
        }}
        name="selectedType"
        options={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)
          ? organizationsWithTooltip()
          : organizationsWithTooltip().filter((org) => org.value === currentOrganization)}
        row={!isMobile()}
        valueKey="value"
        onChange={(option) => changeOrganizationWithConfirmation(option)}
      />
      {
        isOtherOrganization && (
          <Grid container direction="column" rowSpacing={1} style={{ marginTop: '4px' }}>
            <Grid item>
              <Typography>{translate('operatorsStep.organization.justification')}</Typography>
            </Grid>
            <Grid container direction="row" item>
              <Grid item md={6} xl={4} xs={12}>
                <DocumentList
                  canEdit={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)}
                  canValidate={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION)}
                  documents={Object.keys(workModeJustification).length ? [workModeJustification] : []}
                  handleValidation={handleValidation}
                  importLabel="button.import"
                  sizeLimit={1}
                  spacing={2.5}
                  titleStyles={{
                    textAlign: 'center',
                    variant: 'h6'
                  }}
                  onDelete={handleDeleteJustification}
                  onImport={handleAddJustification}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </FormContainer>
  );
};

import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardButtonAdd } from 'components/_commons/Button';
import { TwoColumnGrid } from 'components/_commons/Column';
import { SkeletonSection } from 'components/_commons/Skeletons';
import { TextError } from 'components/_commons/Text';
import { ContactCard } from 'components/AttestationForm/_sections/Contacts/ContactCard';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { AttestationFormContactService, AttestationFormRequestService } from 'services';
import shortid from 'shortid';
import { translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';

const StyledLoadingSection = styled(SkeletonSection)(() => ({
  backdropFilter: 'blur(3px)',
  marginTop: '22px',
  marginRight: '-10px',
  marginLeft: '-10px',
  marginBottom: '-10px'
}));

export const Contacts = ({ requestId, availableActions, updateAlerts }) => {
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState({});

  const fetchContacts = useCallback(() => {
    setIsLoading(true);
    AttestationFormRequestService.getAllContacts(requestId)
      .then((response) => {
        setContacts(response.reduce((res, contact) => {
          res[contact.type]?.push(contact) || (res[contact.type] = [contact]);
          return res;
        }, {}));
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar, requestId]);

  useEffect(() => {
    fetchContacts();
    updateAlerts(SectionEnum.Contacts);
  }, [fetchContacts, updateAlerts]);

  const handleUpdateContact = useCallback((promise, action, setIsContactSaving) => {
    setIsLoading(true);
    setIsContactSaving && setIsContactSaving(true);
    promise
      .then(() => {
        fetchContacts();
        updateAlerts(SectionEnum.Contacts);
        enqueueSnackbar(translate(action), { variant: 'success' });
        close();
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => {
        setIsLoading(false);
        setIsContactSaving && setIsContactSaving(true);
      });
  }, [fetchContacts, updateAlerts, enqueueSnackbar, close]);

  const addContact = useCallback(() => open({
    type: 'ADD_CONTACT',
    onSubmit: (contact, setIsContactSaving) => handleUpdateContact(
      AttestationFormRequestService.createOrUpdateContacts(requestId, contact),
      'attestationFormRequest.contacts.snackbar.created',
      setIsContactSaving
    ),
    closeOnSubmit: false,
    requestId,
    maxWidth: false
  }), [open, requestId, handleUpdateContact]);

  const deleteContact = useCallback(
    (contactDeleted) => open({
      type: 'WARNING',
      onConfirm: () => {
        handleUpdateContact(
          AttestationFormContactService.deleteContact(contactDeleted.id),
          'attestationFormRequest.contacts.snackbar.deleted'
        );
      }
    }),
    [handleUpdateContact, open]
  );

  const editContact = useCallback((contact) => open({
    type: 'ADD_CONTACT',
    onSubmit: (modifiedContact, setIsContactSaving) => {
      const newContact = _.merge(contact, modifiedContact);
      handleUpdateContact(
        AttestationFormRequestService.createOrUpdateContacts(requestId, newContact),
        'attestationFormRequest.contacts.snackbar.updated',
        setIsContactSaving
      );
    },
    requestId,
    defaultContact: contact,
    closeOnSubmit: false,
    maxWidth: false,
    existingContacts: contacts
  }), [open, requestId, contacts, handleUpdateContact]);

  return (
    <Grid container direction="column" spacing={3}>
      {availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM) && (
        <Grid item>
          <CardButtonAdd
            icon={faUser}
            label={translate('attestationFormRequest.contacts.add')}
            variant="outlined"
            onClick={addContact}
          />
        </Grid>
      )}
      <Grid item>
        <div style={{ position: 'relative', minHeight: '15vh' }}>
          {!(Object.values(contacts).length || availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM))
            ? <TextError>{translate('errors.noEntries')}</TextError>
            : (
              <TwoColumnGrid keys={Object.keys(contacts)}>
                {Object.values(contacts).map((contactsForType) => (
                  <Grid item key={shortid.generate()}>
                    <ContactCard
                      canEdit={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)}
                      contacts={contactsForType}
                      onDelete={deleteContact}
                      onEdit={editContact}
                    />
                  </Grid>
                ))}
              </TwoColumnGrid>
            )}
          {isLoading && <StyledLoadingSection backgroundColor="rgba(0,0,0,0)" />}
        </div>
      </Grid>
    </Grid>
  );
};

Contacts.propTypes = {
  requestId: PropTypes.string.isRequired
};

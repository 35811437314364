// Docs : https://geo.api.gouv.fr/adresse
import {
  faMapMarkerAltSlash, faSearch, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Chip, ClickAwayListener, Divider,
  Grid,
  IconButton, InputAdornment, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConditionalTooltip } from 'components/_commons/ConditionalTooltip/ConditionalTooltip';
import { TextAddress } from 'components/_commons/Text';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { AddressService } from 'services';
import { translate } from 'utils';
import { FormHelper } from 'utils/helpers';
import { iconEnum } from 'utils/icons';

const AutocompleteContainer = styled('section')(() => ({
  position: 'relative'
}));

const AddressList = styled('ul')(({ theme }) => ({
  position: 'absolute',
  top: '56px',
  width: '100%',
  maxHeight: '250px',
  margin: '0',
  padding: '0',
  background: theme.palette.common.white,
  border: `1px solid${theme.palette.grey.lighter}`,
  overflow: 'auto',
  zIndex: '2'
}));

const AddressItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease-in',
  padding: '1rem',
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: theme.palette.grey.lighter
  }
}));

const ENTER_KEYCODE = 'Enter';

export const SmallFormAddress = ({
  value, onChange, disabled, optionalToolTipTranslationKey
}) => {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressListIsVisible, setAddressListIsVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(value);
  const debouncedQuery = FormHelper.useDebounce(query, 500);

  useEffect(() => {
    const getAddressList = async () => {
      if (debouncedQuery) {
        setIsSearching(true);
        try {
          const results = await AddressService.getAddressList(debouncedQuery, 'fr')
            .then((response) => response.features);
          setAddressList(results);
          setAddressListIsVisible(true);
          setIsSearching(false);
        } catch {
          setIsSearching(false);
        }
      } else {
        setAddressList([]);
      }
    };

    getAddressList();
  }, [debouncedQuery]);

  const handleChangeQuery = useCallback((event) => setQuery(event.target.value), []);

  const handleClearQuery = useCallback(() => {
    setQuery('');
    setAddressList([]);
    setIsSearching(false);
  }, []);

  const handleShowAddressList = useCallback(() => setAddressListIsVisible(true), []);

  const handleHideAddressList = useCallback(() => setAddressListIsVisible(false), []);

  const handleSelectAddress = useCallback((address) => {
    const postcode = address.context.find((ctx) => ctx.id.includes('postcode'));
    const city = address.context.find((ctx) => ctx.id.includes('place'));

    const addressFormatted = {
      address1: `${address.address ?? ''} ${address.text ?? ''}`,
      address2: '',
      postalCode: postcode?.text ?? '',
      city: city?.text ?? '',
      country: {
        value: 0,
        label: 'France',
        countryCode: 'fr'
      }
    };

    setSelectedAddress(addressFormatted);
    onChange(addressFormatted);
    handleHideAddressList();
  }, [handleHideAddressList, onChange]);

  return (
    <>
      <ConditionalTooltip displayInline={false} translationKey={optionalToolTipTranslationKey}>
        <div>
          {!disabled && (!selectedAddress ? (
            <ClickAwayListener onClickAway={handleHideAddressList}>
              <AutocompleteContainer>
                <TextField
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: query !== '' && (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleClearQuery}>
                          <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {isSearching
                          ? <FontAwesomeIcon icon={faSpinner} spin />
                          : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                      </InputAdornment>
                    )
                  }}
                  label={translate('common.addressAutocomplete')}
                  value={query}
                  variant="outlined"
                  onChange={handleChangeQuery}
                  onFocus={handleShowAddressList}
                />
                {addressListIsVisible && (
                  <AddressList>
                    {addressList.length > 0 && addressList.map((address) => (
                      <Fragment key={address.id}>
                        <AddressItem
                          tabIndex={0}
                          onClick={() => handleSelectAddress(address)}
                          onKeyDown={(e) => e.key === ENTER_KEYCODE && handleSelectAddress(address)}
                        >
                          <div style={{ minWidth: '120px' }}>
                            <Chip label={translate(`common.${address.place_type[0]}`)} />
                          </div>
                          <div>
                            <Typography style={{ fontWeight: 600 }}>{address.place_name}</Typography>
                          </div>
                        </AddressItem>
                        <Divider />
                      </Fragment>
                    ))}
                    {addressList.length === 0 && debouncedQuery && !isSearching && (
                      <Typography align="center" style={{ padding: '2rem' }}>
                        <FontAwesomeIcon icon={faMapMarkerAltSlash} size="2x" />
                        <br />
                        {translate('errors.noAddressList')}
                      </Typography>
                    )}
                  </AddressList>
                )}
              </AutocompleteContainer>
            </ClickAwayListener>
          ) : (
            <Grid alignItems="center" container justifyContent="end">
              <Grid item>
                <TextAddress address={selectedAddress} />
              </Grid>
              <Grid item marginLeft="10px">
                <IconButton
                  style={{ padding: 2 }}
                  onClick={() => {
                    setSelectedAddress(null);
                    onChange(null);
                  }}
                >
                  <FontAwesomeIcon
                    color={iconEnum.trashAlt.defaultColor}
                    icon={iconEnum.trashAlt.icon}
                    size="sm"
                    swapOpacity
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      </ConditionalTooltip>
      {disabled && (
        <ConditionalTooltip displayInline={false} translationKey={optionalToolTipTranslationKey}>
          <Grid container justifyContent="end">
            <Grid item>
              {selectedAddress ? (
                <TextAddress address={selectedAddress} />
              ) : '-'}
            </Grid>
          </Grid>
        </ConditionalTooltip>
      )}
    </>
  );
};

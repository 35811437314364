import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Typography } from '@mui/material';
import React from 'react';

import { CardButton } from 'components/_commons/Button';
import CustomIcon from 'components/_commons/CustomIcon/CustomIcon';

export const CardButtonAdd = ({
  onClick, icon, label, ...props
}) => (
  <CardButton onClick={onClick} {...props}>
    <Grid alignItems="center" container direction="column" spacing={1}>
      <Grid item xs={label ? 8 : 12}>
        <CustomIcon icon={icon} secondIcon={faPlus} secondSize="2x" size="2x" />
      </Grid>
      {label
        && (
          <Grid item xs={4}>
            <Typography variant="h5">
              {label}
            </Typography>
          </Grid>
        )}
    </Grid>
  </CardButton>
);

import { DialogContent } from '@mui/material';
import {
  DocumentAssignationModalAssignation
} from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationModalAssignation';
import {
  DocumentAssignationModalRegistration
} from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationModalRegistration';
import { DocumentAssignationModalSummary } from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationModalSummary';
import { DocumentAssignationModalStepsEnum } from 'core/enums/steps/document-assignation-modal-steps.enum';
import { useFetch } from 'hooks';
import React, { useCallback } from 'react';
import { AttestationFormToolService } from 'services';
import { isTablet } from 'utils';

export const DocumentAssignationContainer = ({
  currentStep,
  canValidate,
  formContext,
  disableNextStep,
  requestId
}) => {
  const {
    response: documentTypes,
    isLoading: isLoadingDocumentTypes
  } = (useFetch(() => AttestationFormToolService.getAllAvailableDocumentTypes(), []));

  const {
    response: tools,
    isLoading: isLoadingTools
  } = (useFetch(() => AttestationFormToolService.getAttestationFormTools(requestId), []));

  const renderSteps = useCallback(() => {
    switch (currentStep) {
    case DocumentAssignationModalStepsEnum.REGISTRATION:
      return (
        <DocumentAssignationModalRegistration
          canValidate={canValidate}
          disableNextStep={disableNextStep}
          documentTypes={documentTypes}
          formContext={formContext}
          isLoadingDocumentTypes={isLoadingDocumentTypes}
        />
      );

    case DocumentAssignationModalStepsEnum.ASSIGNATION:
      return (
        <DocumentAssignationModalAssignation
          disableNextStep={disableNextStep}
          formContext={formContext}
          isLoadingTools={isLoadingTools}
          tools={tools}
        />
      );
    case DocumentAssignationModalStepsEnum.SUMMARY:
      return (
        <DocumentAssignationModalSummary
          formContext={formContext}
        />
      );
    default:
      return null;
    }
  }, [currentStep, canValidate, disableNextStep, documentTypes, formContext, isLoadingDocumentTypes, isLoadingTools, tools]);

  return (
    <DialogContent style={{
      width: isTablet() ? null : '80vw',
      height: '70vh',
      padding: '0'
    }}
    >
      {renderSteps()}
    </DialogContent>
  );
};

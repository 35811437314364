import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import {
  faBars, faSignalAltSlash, faUserCheck, faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Fab, Grid, IconButton, Skeleton, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Button } from 'components/_commons/Button';
import { Desktop, Tablet } from 'components/_commons/MediaQueries';
import { useAccessRights, useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAccountService } from 'services';

import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { isMobile, KeyCloakUtils, translate } from 'utils';
import {
  APPLICATION_MANAGEMENT_ROLES, ROUTES, URL_DATATECNEA
} from 'utils/constants';
import { iconEnum, ICONS } from 'utils/icons';
import { HeaderMenu } from './HeaderMenu';
import { SideMenu } from './SideMenu';

const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: theme.sizes.headerHeight,
  zIndex: theme.zIndex.big,
  background: theme.palette.primary.main,
  backgroundImage: "url('/assets/images/bg/header-decoration.png')",
  backgroundPosition: `${theme.sizes.headerItemWidth} center`,
  backgroundRepeat: 'no-repeat'
}));

const SpaceIndicator = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  fontWeight: 'bold',
  margin: '0 1rem 0 0',
  verticalAlign: 'middle',
  color: theme.palette.common.white
}));

const DesktopOnlyGridItem = styled(Grid)(({ theme }) => ({
  position: 'relative',
  width: theme.sizes.headerItemWidth,
  height: theme.sizes.headerHeight,
  padding: '0.5rem 2rem',
  background: theme.palette.common.white,

  img: {
    maxWidth: '100%'
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    zIndex: '-1',
    top: 0,
    right: '-120px',
    borderLeft: `60px solid ${theme.palette.common.white}`,
    borderRight: '60px solid transparent',
    borderBottom: '60px solid transparent'
  },

  '@media (max-width: 768px)': {
    display: 'none'
  }
}));

const Logo = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '2.6rem',

  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.grey.light
  },

  span: {
    fontFamily: '"Roboto-Light", "Roboto", "OpenSans", Arial, Helvetica, sans-serif'
  },

  sup: {
    marginLeft: '2px',
    fontSize: '1.4rem'
  },

  '@media (max-width: 768px)': {
    marginLeft: '2rem',
    fontSize: '2rem',

    sup: {
      fontSize: '1rem'
    }
  }
}));

const ButtonChangeLanguage = styled(IconButton)(({ theme }) => ({
  '&:disabled': {
    svg: {
      display: 'none'
    }
  },

  span: {
    fontSize: '1.7rem',
    color: theme.palette.common.white
  }
}));

const StyledStructureLevelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
  margin: '0 10px 0 0',
  padding: '0'
}));

const UserName = styled('span')(({ theme }) => ({
  maxWidth: '140px',
  color: theme.palette.common.white,
  marginRight: '2rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '@media (max-width: 768px)': {
    maxWidth: '110px'
  }
}));

const StyledButtonMenu = styled(Fab)(({ theme }) => ({
  position: 'relative',

  span: {
    fontSize: '1.4rem'
  },

  '.buttonMenuIcon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '-4px',
    right: '-4px',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    color: theme.palette.primary.main,
    background: theme.palette.common.white
  }
}));

export const Header = observer(({ withNav }) => {
  const { open, close } = useModal();
  const navigate = useNavigate();
  const theme = useTheme();
  const isNotMobile = !isMobile();
  const hasManagementAccessRights = useAccessRights({ roles: APPLICATION_MANAGEMENT_ROLES });
  const { enqueueSnackbar } = useSnackbar();
  const { userStore, i18nStore } = useStores();
  const {
    isConnecting,
    isConnected,
    userConnected,
    isOffline,
    currentStructureLevel,
    structureLevelOptions,
    isCurrentScopeLoading
  } = userStore;
  const { currentLanguage, languageList } = i18nStore;

  const [toggleMenu, setToggleMenu] = useState(false);

  const connectUser = useCallback(() => {
    userStore.setIsConnecting();

    if (userStore.keycloak) {
      userStore.keycloakLogin()
        .catch(() => {
          userStore.setIsConnecting(false);
          enqueueSnackbar(translate('errors.offlineNoKeycloak'), { variant: 'error' });
        })
        .finally(() => userStore.setIsConnecting(false));
    } else {
      KeyCloakUtils.init()
        .then((keycloak) => userStore.connectUser(keycloak))
        .catch(() => {
          userStore.setIsConnecting(false);
          enqueueSnackbar(translate('errors.offlineNoKeycloak'), { variant: 'error' });
        })
        .finally(() => userStore.setIsConnecting(false));
    }
  }, [userStore, enqueueSnackbar]);

  const handleToggleMenu = useCallback(() => setToggleMenu(!toggleMenu), [toggleMenu]);

  const handleChangeLanguage = useCallback((language, setIsSavingLanguage) => {
    if (language) {
      setIsSavingLanguage(true);
      if (userStore.isConnected && language.value !== localStorage.getItem('i18nextLng')) {
        UserAccountService.updateLocale(language.value).then(() => {
          i18nStore.loadLanguage(language.value);
          userStore.keycloak.updateToken(999999)
            .then((refreshed) => {
              refreshed && userStore.keycloak.login();
              close();
            })
            .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }))
            .finally(() => setIsSavingLanguage(false));
        });
      } else {
        i18nStore.loadLanguage(language.value);
        close();
        setIsSavingLanguage(false);
      }
    }
  }, [close, enqueueSnackbar, i18nStore, userStore.isConnected, userStore.keycloak]);

  const displayModalLanguage = useCallback(() => open({
    type: 'CHANGE_LANGUAGE',
    onSubmit: (language, setIsSavingLanguage) => handleChangeLanguage(language, setIsSavingLanguage),
    defaultValues: currentLanguage
  }), [open, handleChangeLanguage, currentLanguage]);

  const updateCurrentInstitution = (institution, setIsUserLevelSaving) => {
    if ((currentStructureLevel?.value || institution?.value) && currentStructureLevel?.value !== institution?.value) {
      setIsUserLevelSaving(true);
      UserAccountService.updateCurrentInstitution(institution).then((response) => {
        if (response) {
          close();
          navigate(0);
          window.location.reload();
        }
      }).finally(() => setIsUserLevelSaving(false));
    }
  };

  const changeInstitution = () => open({
    type: 'CHANGE_INSTITUTION',
    structureLevelOptions,
    isLoading: isCurrentScopeLoading,
    defaultValue: currentStructureLevel.value ?? '',
    onSubmit: updateCurrentInstitution
  });

  return (
    <header>
      <HeaderContainer>
        <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
          <DesktopOnlyGridItem item>
            <Link to={ROUTES.HOME}>
              <img alt="CEMAFROID" src="/assets/images/companies/CEMAFROID.png" />
            </Link>
          </DesktopOnlyGridItem>
          <Grid item>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Logo data-link={ROUTES.HOME} to={ROUTES.HOME}>
                  <strong>DATA</strong>
                  <span>
                    FLUIDES
                    <sup>®</sup>
                  </span>
                </Logo>
              </Grid>
              {isOffline && (
                <Tablet>
                  <Grid item>
                    <DatafluidesTooltip title={translate('errors.offline')}>
                      <IconButton color="primary" size="small">
                        <FontAwesomeIcon className="ml1" color={theme.palette.common.white} icon={faSignalAltSlash} />
                      </IconButton>
                    </DatafluidesTooltip>
                  </Grid>
                </Tablet>
              )}
            </Grid>
          </Grid>
          {isConnecting
            ? <Skeleton height={30} variant="rect" width="200px" />
            : (
              <Grid item>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="flex-end"
                  style={{ paddingRight: isNotMobile ? '2rem' : '1rem', height: theme.sizes.headerHeight }}
                >

                  {languageList.length > 1 && (
                    <Grid item style={{ paddingRight: '2rem' }}>
                      <DatafluidesTooltip
                        title={translate('modalChangeLanguage.tooltip', { language: currentLanguage.toUpperCase() })}
                      >
                        <span>
                          <ButtonChangeLanguage onClick={displayModalLanguage}>
                            {isNotMobile && <FontAwesomeIcon color="#ffffff" icon={iconEnum.globe.icon} size="xs" />}
                            <span className="mr1 ml1">{currentLanguage.split('_')[0].toUpperCase()}</span>
                            {isNotMobile && <FontAwesomeIcon color="#ffffff" icon={ICONS.CHEVRON_LIST.icon} size="xs" />}
                          </ButtonChangeLanguage>
                        </span>
                      </DatafluidesTooltip>
                    </Grid>
                  )}
                  {isConnected
                    ? (
                      <>
                        <Grid item>
                          {userConnected && <UserName>{userConnected.name}</UserName>}
                          <>
                            <br />
                            {isCurrentScopeLoading || (ICONS.STRUCTURE_LEVEL[currentStructureLevel?.type] === undefined) ? (
                              <FontAwesomeIcon color={theme.palette.common.white} icon={faSpinner} size="sm" spin />
                            ) : (
                              <>
                                {hasManagementAccessRights && (
                                  <Desktop>
                                    <Grid item>
                                      <SpaceIndicator>
                                        {translate('common.backOfficeSpace')}
                                      </SpaceIndicator>
                                    </Grid>
                                  </Desktop>
                                )}
                                {!hasManagementAccessRights && (
                                  <StyledStructureLevelButton
                                    startIcon={<FontAwesomeIcon icon={ICONS.STRUCTURE_LEVEL[currentStructureLevel?.type].icon} />}
                                    variant="text"
                                    onClick={changeInstitution}
                                  >
                                    {currentStructureLevel?.label}
                                  </StyledStructureLevelButton>
                                )}
                              </>
                            )}
                          </>
                        </Grid>
                        <StyledButtonMenu
                          color="primary"
                          data-cy="headerMenu"
                          data-tour="step-header-user"
                          size="small"
                          onClick={handleToggleMenu}
                        >
                          <span>{`${userConnected.given_name.charAt(0)}${userConnected.family_name.charAt(0)}`}</span>
                          <span className="buttonMenuIcon">
                            <FontAwesomeIcon icon={faBars} size="xs" />
                          </span>
                        </StyledButtonMenu>
                      </>
                    ) : !isOffline && (
                      <>
                        <Grid item>
                          <a href={`${URL_DATATECNEA}/inscription`}>
                            <Button
                              color="primary"
                              startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                              variant="contained"
                            >
                              {isNotMobile && translate('button.register')}
                            </Button>
                          </a>
                        </Grid>
                        <Grid item marginLeft="10px">
                          <Button
                            startIcon={<FontAwesomeIcon icon={faUserCheck} />}
                            type="secondary"
                            onClick={connectUser}
                          >
                            {isNotMobile && translate('button.connect')}
                          </Button>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Grid>
            )}

        </Grid>
        <SideMenu isOpen={toggleMenu} setToggleMenu={setToggleMenu} />
      </HeaderContainer>
      {withNav && <HeaderMenu isConnected={isConnected} />}

    </header>
  );
});

import React from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';

import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { FormInputField } from 'components/_commons/Form/Inputs';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { translate } from 'utils';
import { ATTESTATION_STATUS } from 'utils/constants';

const WarningMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontWeight: 'bold'
}));
export const RejectRequestSupervisionModal = ({ modalState, onClose, onSubmit }) => {
  const formContext = useForm();
  const { getValues, trigger } = formContext;

  const { wasRefusedInstructor, transmittedLessThanOneYearAgo, canCloseAttestation } = modalState;

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'rejectRequestForm' }}
    >
      <ModalHeader onClose={onClose}>{translate('attestationFormRequest.conclusion.supervision.confirmTitle')}</ModalHeader>

      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <Grid container spacing={3}>
          {!wasRefusedInstructor && (
            <Grid item xs={12}>
              <WarningMessage variant="h6">
                {translate('attestationFormRequest.conclusion.supervision.requestNotRefusedInstructor')}
              </WarningMessage>
            </Grid>
          )}
          {transmittedLessThanOneYearAgo && (
            <Grid item xs={12}>
              <WarningMessage variant="h6">
                {translate('attestationFormRequest.conclusion.supervision.requestTransmittedLessThanAYearAgo')}
              </WarningMessage>
            </Grid>
          )}
          <Grid item xs={12}>
            <ModalFormField title={translate('attestationFormRequest.conclusion.supervision.refusalComment')}>
              <FormInputField fullWidth name="comment" required />
            </ModalFormField>

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ marginRight: 'auto' }}
          variant="outlined"
          onClick={onClose}
        >
          {translate('button.cancel')}
        </Button>

        {
          canCloseAttestation ? (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  const result = await trigger();
                  result && onSubmit({ status: ATTESTATION_STATUS.REMOVED, ...getValues() });
                  result && onClose();
                }}
              >
                {translate('button.disableAttestation.withdraw')}
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  const result = await trigger();
                  result && onSubmit({ status: ATTESTATION_STATUS.SUSPENDED, ...getValues() });
                  result && onClose();
                }}
              >
                {translate('button.disableAttestation.suspend')}
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                const result = await trigger();
                result && onSubmit({ status: null, ...getValues() });
                result && onClose();
              }}
            >
              {translate('button.confirm')}
            </Button>
          )
        }

      </DialogActions>
    </FormContainer>
  );
};

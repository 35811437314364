import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DecisionButton } from 'components/_commons/Button/DecisionButton';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AttestationEvaluationService } from 'services/AttestationEvaluationService';
import { translate } from 'utils';
import { ROUTES } from 'utils/constants';
import { DateHelper } from 'utils/helpers';

export const ConclusionInstructor = ({
  requestId, unresolvedAlerts, actionsDisabled, transmissionDate
}) => {
  const theme = useTheme();
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const transmittedLessThanOneYearAgo = transmissionDate && !DateHelper.isMoreThanOneYearInThePast(new Date(transmissionDate));
  const unresolvedAlertsRemaining = unresolvedAlerts?.length > 0;

  const handleSuccess = useCallback(() => {
    navigate(ROUTES.INSTRUCTIONS);
    enqueueSnackbar(translate('attestationFormRequest.conclusion.instruction.decisionApplied'), { variant: 'success' });
  }, [enqueueSnackbar, navigate]);

  const handleError = useCallback(
    (error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }),
    [enqueueSnackbar]
  );

  const validateInstruction = useCallback(() => (open({
    type: 'CONFIRM',
    variant: unresolvedAlertsRemaining ? 'WARNING' : 'PRIMARY',
    title: 'attestationFormRequest.conclusion.instruction.confirmTitle',
    content: translate(unresolvedAlertsRemaining
      ? 'attestationFormRequest.conclusion.instruction.confirmValidateUnresolvedAlerts'
      : 'attestationFormRequest.conclusion.instruction.confirmValidate'),
    onConfirm: () => {
      AttestationEvaluationService.validateInstruction(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [open, unresolvedAlertsRemaining, requestId, handleSuccess, handleError]);

  const dismissInstruction = useCallback(() => (open({
    type: 'CONFIRM',
    variant: unresolvedAlertsRemaining ? 'PRIMARY' : 'WARNING',
    title: 'attestationFormRequest.conclusion.instruction.confirmTitle',
    content: translate(unresolvedAlertsRemaining
      ? 'attestationFormRequest.conclusion.instruction.confirmDismiss'
      : 'attestationFormRequest.conclusion.instruction.confirmDismissNoAlerts'),
    onConfirm: () => {
      AttestationEvaluationService.dismissInstruction(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [open, unresolvedAlertsRemaining, requestId, handleSuccess, handleError]);

  const rejectInstruction = useCallback(() => (open({
    type: 'CONFIRM',
    variant: transmittedLessThanOneYearAgo ? 'WARNING' : 'PRIMARY',
    title: 'attestationFormRequest.conclusion.instruction.confirmTitle',
    content: translate(transmittedLessThanOneYearAgo
      ? 'attestationFormRequest.conclusion.instruction.confirmRejectRecentRequest'
      : 'attestationFormRequest.conclusion.instruction.confirmReject'),
    onConfirm: () => {
      AttestationEvaluationService.rejectInstruction(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [open, transmittedLessThanOneYearAgo, requestId, handleSuccess, handleError]);

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={4}>
        <DecisionButton
          color={theme.palette.success}
          label="button.conform"
          onClick={() => !actionsDisabled && validateInstruction()}
        />
      </Grid>
      <Grid item xs={4}>
        <DecisionButton
          color={theme.palette.warning}
          description="attestationFormRequest.conclusion.instruction.dismiss"
          label="button.notConform"
          onClick={() => !actionsDisabled && dismissInstruction()}
        />
      </Grid>
      <Grid item xs={4}>
        <DecisionButton
          color={theme.palette.error}
          description="attestationFormRequest.conclusion.instruction.reject"
          label="button.notConform"
          onClick={() => !actionsDisabled && rejectInstruction()}
        />
      </Grid>
    </Grid>
  );
};

import { RequestHelper } from '../utils';
import { API_ROUTES } from '../utils/constants';

// PUT
export const validateFluidBalanceEntry = (entryId, removeComments) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCE_ENTRIES.VALIDATE_FLUID_BALANCE_ENTRY(entryId, removeComments)
);

export const rejectFluidBalanceEntry = (entryId, fluidBalanceEntryCommentViews) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCE_ENTRIES.REJECT_FLUID_BALANCE_ENTRY(entryId),
  fluidBalanceEntryCommentViews
);

export const createComment = (entryId, fluidBalanceEntryCommentView) => RequestHelper.POST(
  API_ROUTES.FLUID_BALANCE_ENTRIES.CREATE_COMMENT(entryId),
  fluidBalanceEntryCommentView
);

export const deleteComment = (commentId) => RequestHelper.DELETE(
  API_ROUTES.FLUID_BALANCE_ENTRIES.DELETE_COMMENT(commentId)
);

// DELETE
export const deleteFluidBalanceEntry = (entryId) => RequestHelper.DELETE(
  API_ROUTES.FLUID_BALANCE_ENTRIES.FLUID_BALANCE_ENTRY(entryId)
);

export const FluidBalanceEntryService = {
  validateFluidBalanceEntry,
  rejectFluidBalanceEntry,
  deleteFluidBalanceEntry,
  createComment,
  deleteComment
};

import { Modal } from 'components/_commons/Modals';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { OtherProps } from 'utils';

export type ModalProps<T> = OtherProps & {
  /** When true, the modal will close immediately after the submit action without waiting. */
  closeOnSubmit?: boolean;
  /** When true, the modal will only be closed using the close or cancel actions. */
  disableClose?: boolean;
  /** The action to call when the close action is used (in addition to closing the modal). */
  onClose?: (event?: any) => any;
  /** The action to call when the submit action is used. This action is responsible for closing the modal afterwards. */
  onSubmit?: (value: T, setIsLoading?: (loading: boolean) => void) => any;
  /** The action to call when the confirm action is used.
   * Take only optional parameters like the one used to update the saving progress status of the modal. */
  onConfirm?: (setIsLoading?: (loading: boolean) => void) => any;
}

export type ModalState = ModalProps<any> & {
  /** The type of the modal */
  type: string;
}
interface ModalContextType {
  close: (secondary?: boolean) => void,
  open: (state: ModalState, secondary?: boolean) => void
}

export const ModalContext = createContext<ModalContextType>({
  open: (_) => console.log("Context not updated yet!"),
  close: (_) => console.log("Context not updated yet!")
});

export const useModal = () => useContext<ModalContextType>(ModalContext);

export const ModalProvider = ({children}) => {
  const [isOpen, setOpen] = useState(false);
  const [modalState, setModalState] = useState<ModalState | undefined>(undefined);

  const openModal = useCallback((options: ModalState) => {
    setOpen(true);
    setModalState(options);
  }, []);

  const closeModal = () => {
    setOpen(false);
    setModalState(undefined);
  }

  return (
    <>
      <ModalContext.Provider value={{open: openModal, close: closeModal}}>
        {children}
      </ModalContext.Provider>

      <Modal isOpen={isOpen} modalState={modalState} closeModal={closeModal} />
    </>
  );
};
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';

import { SelectField } from 'components/_commons/Form';
import { translate } from 'utils';
import { ICONS } from 'utils/icons';

export const StructureLevelSelect = ({
  isDisabled = false, isLoading = false, options, placeholder, selectedItem, label,
  required = false, onSelectItem, isMultiple = false, menuPlacement = 'bottom', isClearable = false
}: {
  isClearable?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
  isMultiple?: boolean,
  label?: string,
  menuPlacement?: string,
  onSelectItem: any,
  options: any,
  placeholder?: string,
  required?: boolean,
  selectedItem: any
}) => {
  const theme: any = useTheme();

  const formatStructureLevelOption = (structureLevel, metaData) => {
    const { icon } = ICONS.STRUCTURE_LEVEL[structureLevel?.type] || ICONS.STRUCTURE_LEVEL.COMPANY;
    const isMenu = metaData.context === 'menu';
    const iconColor = metaData.selectValue.includes(structureLevel) ? theme.palette.primary.lighter : theme.palette.primary.main;
    const indent = '&nbsp;'.repeat(structureLevel.indentNumber * 6);
    const name = structureLevel.type === 'COMPANY' ? `<b>${structureLevel.label}</b>` : structureLevel.label;
    const optionLabel = isMenu ? name : structureLevel.label;
    return isMenu ? (
      <>
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(indent) }} />
        <FontAwesomeIcon color={iconColor} fixedWidth icon={icon} size="sm" style={{ marginRight: 10 }} />
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(optionLabel) }} />
      </>
    )
      : (
        <>
          { structureLevel?.type && (
            <FontAwesomeIcon
              color={theme.palette.primary.main}
              fixedWidth
              icon={icon}
              size="sm"
              style={{ marginRight: 10 }}
            />
          )}
          <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(optionLabel) }} />
        </>
      );
  };

  return (
    <SelectField
      formatOptionLabel={formatStructureLevelOption}
      id="structure-level-select"
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMultiple}
      isSearchable
      label={label}
      loadingMessage={() => translate('common.loading')}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      name="structure-level-select"
      options={options}
      placeholder={placeholder}
      required={required}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }), container: () => ({ width: 500 }) }}
      value={selectedItem}
      onChange={(value) => onSelectItem(value)}
    />
  );
};

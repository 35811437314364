import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl, FormControlLabel, InputLabel, Radio, RadioGroup
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { translate } from 'utils';
import { FLUID_BALANCE_ENTRY_VALIDATION_STATUS } from '../../../utils/constants';
import { iconEnum } from '../../../utils/icons';

const getBackgroundColor = (value) => {
  switch (value) {
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID:
    return '#8ACA8D';
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID:
    return '#F97575';
  default:
    return '#D9D9D9FF';
  }
};

const getSliderColor = (value) => {
  switch (value) {
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID:
    return '#0ea04b';
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID:
    return '#e2001a';
  default:
    return '#8c8c8c';
  }
};

const getLeftAlignment = (value) => {
  switch (value) {
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID:
    return '100%';
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID:
    return '0';
  default:
    return '50%';
  }
};

const getTransform = (value) => {
  switch (value) {
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID:
    return 'translateX(-25px)';
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID:
    return 'translateX(0)';
  default:
    return 'translateX(-12px)';
  }
};

const getSliderIcon = (value) => {
  switch (value) {
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID:
    return <FontAwesomeIcon data-value="VALID" icon={iconEnum.check.icon} />;
  case FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID:
    return <FontAwesomeIcon data-value="INVALID" icon={iconEnum.xMark.icon} />;
  default:
    return null;
  }
};

const StyledSwitchTriple = styled('div')(({ value }) => ({
  position: 'relative',
  width: '90px',
  transition: 'all 0.3s',
  borderRadius: '20px',
  boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',

  backgroundColor: getBackgroundColor(value),

  'label .MuiRadio-root': {
    opacity: value === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.NONE ? 1 : 0
  },

  '.MuiFormControlLabel-root': {
    width: '30px',
    margin: 0
  },
  '.MuiFormGroup-root, .MuiRadio-root': {
    width: '100%'
  },
  '.MuiRadio-root': {
    height: '22px'
  }
}));

const StyledSlider = styled('span')(({ value }) => ({
  position: 'absolute',
  width: '26px',
  height: '26px',
  left: getLeftAlignment(value),
  top: '-2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: getSliderColor(value),
  transition: 'all 0.3s',
  transform: getTransform(value),
  borderRadius: '50%',
  svg: {
    color: '#ffffff'
  }
}));

export const ValidationSwitchTriple = ({
  label = '',
  name,
  onChange,
  value
}) => {
  const theme = useTheme();

  return (
    <>
      {label && <InputLabel shrink>{translate(label)}</InputLabel>}
      <StyledSwitchTriple value={value}>
        <FormControl className="formControl">
          <RadioGroup
            aria-labelledby={translate(label)}
            name={name}
            row
            style={{
              flexWrap: 'nowrap'
            }}
            value={value}
            onChange={onChange}
          >
            <FormControlLabel
              control={<Radio icon={<FontAwesomeIcon color={theme.palette.error.main} icon={iconEnum.xMark.icon} size="sm" />} />}
              value={FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID}
            />
            <FormControlLabel
              control={
                <Radio icon={<FontAwesomeIcon color={theme.palette.grey.dark} icon={iconEnum.horizontalRule.icon} size="sm" />} />
              }
              value={FLUID_BALANCE_ENTRY_VALIDATION_STATUS.NONE}
            />
            <FormControlLabel
              control={
                <Radio icon={<FontAwesomeIcon color={theme.palette.success.main} icon={iconEnum.check.icon} size="sm" />} />
              }
              value={FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID}
            />
          </RadioGroup>
          <StyledSlider value={value}>
            {getSliderIcon(value)}
          </StyledSlider>
        </FormControl>
      </StyledSwitchTriple>
    </>
  );
};

import i18n from 'i18next';

import {
  DocumentHelper, FilterHelper, FormHelper, IndexedDBHelper,
  NavigatorHelper, RequestHelper, StorageHelper, UserHelper
} from './helpers';
import { KeyCloakUtils } from './keycloak/keycloakUtils';

export const isMobile = () => window.outerWidth <= 600;
export const isTablet = () => window.outerWidth <= 912;
export * from './constants';

const translationNotFound = 'errors.translationNotFound';

const isWhitespaceString: (str: string) => boolean = (str) => !str.replace(/\s/g, '').length;

export const translationExist: (key: string, options?: any) => boolean = (key, options) => {
  if (key && i18n.exists(key)) {
    const translation = i18n.t(key, options);
    if (translation && !isWhitespaceString(translation)) {
      return true;
    }
  }
  return false;
};

export const translate = (key: string, options?: any) => {
  if (key && i18n.exists(key)) {
    const translation = i18n.t(key, options);
    if (translation) {
      return translation;
    }
  }
  // TODO remove this once all translation are properly added
  // eslint-disable-next-line no-console
  console.log(`Translation not found for : ${key}`);
  if (i18n.exists(translationNotFound)) {
    return i18n.t(translationNotFound);
  }
  return translationNotFound.substring(translationNotFound.lastIndexOf('.') + 1);
};

export const RandomNumber = () => {
  const crypto = window.crypto || (window as any).msCrypto;
  return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
};

export const generateUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = RandomNumber() * 16 | 0;
  const v = c === 'x' ? r : ((r && 0x3) || 0x8);
  return v.toString(16);
});

export const transformObjectListToStringQueriesParam = (objectList) => {
  if (!objectList.length) return '';

  let queriesParams = '&';

  const addArrayToQuery = (i, val, index, arrayOfValueLength) => {
    queriesParams += `${objectList[i].key}=${val.value}`;
    if (index < arrayOfValueLength - 1) queriesParams += '&';
  };

  for (let i = 0; i < objectList.length; i += 1) {
    // If the params is a SelectItem
    if (objectList[i].value) {
      // If we have an array of SelectItem
      if (objectList[i].value.length && typeof (objectList[i].value) === 'object') {
        const arrayOfValueLength = objectList[i].value.length;
        objectList[i].value.map((val, index) => addArrayToQuery(i, val, index, arrayOfValueLength));
      } else if (objectList[i].value.value) {
        queriesParams += `${objectList[i].key}=${objectList[i].value.value}`;
      } else if (objectList[i].value instanceof Date) {
        // TODO add .split('T')[0] when the backend change to date format
        queriesParams += `${objectList[i].key}=${new Date(objectList[i].value).toISOString()}`;
      } else {
        queriesParams += `${objectList[i].key}=${objectList[i].value}`;
      }
    } else {
      queriesParams += `${objectList[i].key}=${objectList[i].label}`;
    }

    if (i < objectList.length - 1) queriesParams += '&';
  }

  return queriesParams;
};

export const checkRegexMismatch = (rule, value) => {
  // If no rule is specified, the regex is always considered a match
  if (!rule) {
    return false;
  }
  // If no value is specified, the regex is always considered a mismatch
  if (!value) {
    return true;
  }
  return !new RegExp(`^${rule}$`).test(value);
};

export {
  DocumentHelper,
  FilterHelper,
  FormHelper,
  IndexedDBHelper, KeyCloakUtils, NavigatorHelper,
  RequestHelper,
  StorageHelper,
  UserHelper
};

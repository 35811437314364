import { useEffect, useState } from 'react';
import { AttestationCategoryHelper } from './AttestationCategoryHelper';
import { DateHelper } from './DateHelper';
import { DocumentHelper } from './DocumentHelper';
import { FilterHelper } from './FilterHelper';
import { FormHelper } from './FormHelper';
import { IndexedDBHelper } from './IndexedDBHelper';
import { NavigatorHelper } from './NavigatorHelper';
import { RequestHelper } from './RequestHelper';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';
import { Validators } from './ValidatorsHelper';

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export {
  AttestationCategoryHelper, DateHelper,
  DocumentHelper,
  FilterHelper,
  FormHelper,
  IndexedDBHelper,
  NavigatorHelper,
  RequestHelper,
  StorageHelper, useDebounce, UserHelper,
  Validators
};

import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { enumOptionTypes, EnumService } from 'services/EnumService';

class EnumOptionsStore {
  constructor() {
    this.requestTypes = [];
    this.requestStatuses = [];
    this.attestationStatuses = [];
    this.auditStatuses = [];
    this.fluidBalanceStatuses = [];
    this.areAttestationFormRelatedEnumsLoading = true;

    makeObservable(this, {
      requestTypes: observable,
      requestStatuses: observable,
      attestationStatuses: observable,
      auditStatuses: observable,
      fluidBalanceStatuses: observable,
      areAttestationFormRelatedEnumsLoading: observable,
      getAllAttestationFormRelatedEnums: action
    });
  }

  getAllAttestationFormRelatedEnums() {
    return Promise.all([
      EnumService.getEnumOptions(enumOptionTypes.ATTESTATION_FORM_REQUEST_TYPE)
        .then((resp) => runInAction(() => {
          this.requestTypes = resp;
        })),
      EnumService.getEnumOptions(enumOptionTypes.ATTESTATION_FORM_REQUEST_STATUS)
        .then((resp) => runInAction(() => {
          this.requestStatuses = resp;
        })),
      EnumService.getEnumOptions(enumOptionTypes.ATTESTATION_STATUS)
        .then((resp) => runInAction(() => {
          this.attestationStatuses = resp;
        })),
      EnumService.getEnumOptions(enumOptionTypes.AUDIT_STATUS)
        .then((resp) => runInAction(() => {
          this.auditStatuses = resp;
        })),
      EnumService.getEnumOptions(enumOptionTypes.FLUID_BALANCE_STATUS)
        .then((resp) => runInAction(() => {
          this.fluidBalanceStatuses = resp;
        }))
    ])
      .finally(() => runInAction(() => {
        this.areAttestationFormRelatedEnumsLoading = false;
      }));
  }
}

export const enumOptionsStore = new EnumOptionsStore();

import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET
const getDetails = (contactId) => RequestHelper.GET(API_ROUTES.CONTACTS.CONTACT(contactId));

const getContactTypes = () => RequestHelper.GET(API_ROUTES.CONTACTS.CONTACT_TYPES());

// PUT
const updateContact = (contactId, contact) => RequestHelper.PUT(API_ROUTES.CONTACTS.CONTACT(contactId), contact);

// DELETE
const deleteContact = (contactId) => RequestHelper.DELETE(API_ROUTES.CONTACTS.CONTACT(contactId));

export const AttestationFormContactService = {
  getDetails,
  getContactTypes,
  updateContact,
  deleteContact
};

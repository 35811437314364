import i18next from 'i18next';
import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { I18nService, UserAccountService } from 'services';
import { userStore } from 'stores/UserStore';
import { StorageHelper } from 'utils/helpers';

export const frenchLocale = 'fr_FR';

class I18nStore {
  constructor() {
    this.currentLanguage = localStorage.getItem('i18nextLng') || frenchLocale;
    this.languageList = [];

    makeObservable(this, {
      currentLanguage: observable,
      languageList: observable,
      loadLanguage: action,
      checkLanguage: action,
      loadLanguageList: action
    });
  }

  findLanguageByLocale(locale) {
    return this.languageList.find((l) => l.value.toUpperCase().indexOf(locale.toUpperCase()) !== -1);
  }

  checkLanguage(lng) {
    let actualLanguage = lng;
    if (!this.findLanguageByLocale(actualLanguage)) {
      const localLanguage = localStorage.getItem('i18nextLng');
      if (localLanguage && this.findLanguageByLocale(localLanguage)) {
        // Fallback to the local saved language if the required language is not activated for this application
        actualLanguage = localLanguage;
      } else if (this.findLanguageByLocale(frenchLocale)) {
        // Fallback to french if the local saved language is unavailable or not activated for this application
        actualLanguage = frenchLocale;
      } else if (this.languageList.length > 0) {
        // Fallback to the first activated language for this application if french is not available
        actualLanguage = this.languageList[0].value;
      }
    }
    if (actualLanguage !== lng) {
      UserAccountService.updateLocale(actualLanguage)
        .then(() => userStore.keycloak.updateToken(999999)
          .then((refreshed) => {
            refreshed && userStore.keycloak.login();
          }));
    }
  }

  loadLanguage(lng) {
    I18nService.getLabels('DATAFLUIDES', lng)
      .then((bundle) => this.setBundle(lng, bundle))
      .catch(() => this.setBundle(lng, JSON.parse(StorageHelper.GET('i18nextLngBundle'))));
  }

  setBundle(lng, bundle) {
    StorageHelper.SET('i18nextLngBundle', bundle);
    i18next.addResourceBundle(lng, 'translation', bundle, true, true);
    // Change the i18next language to the new one
    i18next.changeLanguage(lng)
      .then(() => runInAction(() => {
        if (localStorage.getItem('i18nextLng') !== lng) {
          // Remove the old language from the resources if it exists
          localStorage.getItem('i18nextLng') && i18next.removeResourceBundle(localStorage.getItem('i18nextLng'), 'translation');
        }
        // Set the language cache variable to the new one
        StorageHelper.SET('i18nextLng', lng);
        this.currentLanguage = lng;
      }));
  }

  loadLanguageList() {
    I18nService.getLanguageList()
      .then((languages) => runInAction(() => {
        this.languageList = languages;
      }));
  }
}

export const i18nStore = new I18nStore();

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const PageSubtitle = ({ text, styles }) => (
  <Typography
    align="center"
    style={{ margin: '1rem 0', ...styles }}
    variant="h3"
  >
    {translate(text)}
  </Typography>
);

export const PageTitle = ({
  title, text, icon, iconColor = 'success', iconColorVariant = 'main', iconTooltip = '', withMargin = true, button, align = 'left', backButtonRoute = -1
}: {
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify',
  backButtonRoute?: number,
  button?: any,
  icon?: any,
  iconColor?: string,
  iconColorVariant?: string,
  iconTooltip?: string,
  text: string,
  title?: string,
  withMargin?: boolean
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Typography
      align={align}
      style={{ margin: withMargin ? '3rem 0' : 0 }}
      variant="h2"
    >
      {backButtonRoute
        && (
          <DatafluidesTooltip title={translate('button.back')}>
            <IconButton style={{ marginRight: 5 }} onClick={() => navigate(backButtonRoute)}>
              <FontAwesomeIcon color={iconEnum.back.defaultColor} icon={iconEnum.back.icon as any} size="sm" />
            </IconButton>
          </DatafluidesTooltip>
        )}

      {(title && translate(title)) || text}

      {icon && (
        <span className="ml1">
          <DatafluidesTooltip aria-label={translate(iconTooltip)} title={translate(iconTooltip)}>
            <IconButton>
              <FontAwesomeIcon color={theme.palette[iconColor][iconColorVariant]} icon={icon} />
            </IconButton>
          </DatafluidesTooltip>
        </span>
      )}

      {button && (
        <span className="ml1">
          {button}
        </span>
      )}
    </Typography>
  );
};

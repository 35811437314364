import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconButton } from 'components/_commons/Button';
import { TextButton } from 'components/_commons/Button/TextButton';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { InvalidDocumentIcon } from 'components/_commons/Icon/InvalidDocumentIcon';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const Document = (({
  name, base64Content, deletable = false, handleDelete, tooltip, document, handleValidation,
  canValidate = false, unread = false, invalid = false, modalFullScreen = true, loadContent = false
}: {
  base64Content: string,
  canValidate?: boolean,
  deletable?: boolean,
  document: any,
  handleDelete?: any,
  handleValidation?: any,
  invalid?: boolean,
  loadContent?: boolean,
  modalFullScreen?: boolean,
  name: string,
  tooltip?: string,
  unread?: boolean
}) => {
  const { open, close } = useModal();
  const theme = useTheme();
  const docBase64Content = base64Content || document?.base64Content;
  const docId = document?.documentId || document?.id;
  const needToGetContent = loadContent || (docId && !docBase64Content);

  const handleOpenPDFModal = useCallback(() => (
    canValidate
      ? open({
        onSubmit: (documents, setIsSavingDocuments) => {
          setIsSavingDocuments(true);
          handleValidation(docId, documents[0].validation);
          setIsSavingDocuments(false);
          close();
        },
        canValidate,
        type: 'DOCUMENTS_VERIFICATION',
        canEdit: canValidate,
        fullscreen: modalFullScreen,
        modalState: {
          documents: [{
            base64Content: docBase64Content, name, id: docId, validation: document?.validation
          }]
        }
      }) : open({
        type: 'DISPLAY_DOCUMENT',
        backdropClick: true,
        fullscreen: modalFullScreen,
        loadContent: needToGetContent,
        document: {
          name,
          documentId: docId,
          base64Content: docBase64Content
        }
      })
  ), [open, close, handleValidation, canValidate, docBase64Content, docId, document, modalFullScreen, name, needToGetContent]);

  return (
    <Grid alignItems="center" container direction="row">
      <Grid item width="100%" xs={deletable ? 10 : 12}>
        <Grid alignItems="center" container direction="row" spacing={1}>
          <Grid item xs={((unread && canValidate) || invalid) ? 10 : 12}>
            <TextButton
              label={name}
              tooltip={tooltip ? translate(tooltip) : name}
              onClick={handleOpenPDFModal}
            />
          </Grid>
          {unread && canValidate
            && (
              <Grid item xs={2}>
                <DatafluidesTooltip title={translate('attestationFormRequest.alerts.unread')}>
                  <Grid item>
                    <IconButton onClick={handleOpenPDFModal}>
                      <FontAwesomeIcon color={theme.palette.warning.main} icon={iconEnum.eye.icon} />
                    </IconButton>
                  </Grid>
                </DatafluidesTooltip>
              </Grid>
            )}
          {invalid
            && (
              <Grid item xs={2}>
                <DatafluidesTooltip title={translate('attestationFormRequest.alerts.invalidDocument')}>
                  <Grid item>
                    <InvalidDocumentIcon />
                  </Grid>
                </DatafluidesTooltip>
              </Grid>
            )}
        </Grid>

      </Grid>
      {deletable
        && (
          <Grid container item justifyContent="center" xs={2}>
            <DatafluidesTooltip title={translate('button.delete')}>
              <Grid item>
                <IconButton
                  style={{ padding: 2 }}
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon
                    color={iconEnum.trashAlt.defaultColor}
                    icon={iconEnum.trashAlt.icon as any}
                    size="sm"
                    swapOpacity
                  />
                </IconButton>
              </Grid>
            </DatafluidesTooltip>
          </Grid>
        )}

    </Grid>
  );
});

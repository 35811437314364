import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import shortid from 'shortid';

export const InvalidDocumentIcon = ({ size = 'xl' }) => {
  const theme = useTheme();
  return (
    <span className={`fa-layers fa-fw fa-${size}`}>
      <FontAwesomeIcon
        color={theme.palette.error.main}
        icon={faClipboard}
        key={shortid.generate()}
      />
      <FontAwesomeIcon
        color={theme.palette.success.contrastText}
        icon={faXmark}
        key={shortid.generate()}
        transform="shrink-7 down-2.5"
      />
    </span>
  );
};

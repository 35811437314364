export const AlertStatusEnum = {
  Unresolved: 'UNRESOLVED',
  Resolved: 'RESOLVED',
  ResolvedInstructor: 'RESOLVED_INSTRUCTOR',
  Closed: 'CLOSED',
  Cancelled: 'CANCELLED'
};

export const AlertTypeEnum = {
  Automatic: 'AUTOMATIC',
  Attestation_manual: 'ATTESTATION_MANUAL',
  Audit_Manual: 'AUDIT_MANUAL',
  Fluid_balance_manual: 'FLUID_BALANCE_MANUAL',
  Documentary: 'DOCUMENTARY'
};

import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useFieldArray, useForm } from 'react-hook-form-mui';

import { DialogContent } from '@mui/material';
import { translate } from 'utils';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { useFetch } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { useSnackbar } from 'notistack';
import { DocumentService } from 'services';
import { VALIDATION_STATUS } from 'utils/constants';
import { DocumentActionButtons } from './DocumentActionButtons';
import { DocumentFormContainer } from './DocumentFormContainer';

export const DocumentsVerificationModal = ({
  modalState: { documents, documentTypes, options }, onClose, onSubmit, isProof, canValidate, canEdit = true, ignoreValidation
}: ModalProps<any>) => {
  const { response: documentsWithContent, isLoading } = useFetch(
    () => DocumentService.getDocumentsWithContent(documents),
    documents
  );
  const formContext = useForm({ defaultValues: { documents: documentsWithContent } });
  const { fields, update } = useFieldArray({
    control: formContext.control,
    name: 'documents',
    keyName: 'customId'
  });

  useEffect(() => {
    formContext.setValue('documents', documentsWithContent);
    // eslint-disable-next-line
  }, [documentsWithContent]);

  const [documentIndex, setDocumentIndex] = useState(0);
  const totalDocument = fields?.length;
  const { enqueueSnackbar } = useSnackbar();
  const [isSavingDocuments, setIsSavingDocuments] = useState(false);

  const { watch } = formContext;

  const hasMoreDocument = () => documentIndex < (totalDocument - 1);

  const validateAllDocuments = useCallback((updatedDocuments, _documents) => {
    const promises = [];
    updatedDocuments.forEach((document) => {
      if (document.validation?.validationStatus && document.validation?.validationStatus !== VALIDATION_STATUS.UNDEFINED) {
        promises.push(DocumentService.validateDocument(document.id, document.validation));
      }
    });
    Promise.all(promises)
      .then(
        () => {
          enqueueSnackbar(translate('attestationFormRequest.validateDocument.snackbar.saved'), { variant: 'success' });
          onSubmit(_documents, setIsSavingDocuments);
        }
      )
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [enqueueSnackbar, onSubmit]);

  const onSubmitDocuments = (form) => {
    if (hasMoreDocument()) {
      setDocumentIndex(documentIndex + 1);
    } else {
      const updatedDocuments = formContext.getValues('documents');
      updatedDocuments.forEach((document) => {
        if (!document.validation || document.validation.validationStatus === VALIDATION_STATUS.UNDEFINED) {
          DocumentService.invalidateDocument(document.id);
        }
      });
      if (canValidate && !ignoreValidation) {
        validateAllDocuments(updatedDocuments, form.documents);
      } else {
        onSubmit(form.documents, setIsSavingDocuments);
      }
    }
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'updateDocuments' }}
      onSuccess={onSubmitDocuments}
    >
      <ModalHeader onClose={onClose}>
        {translate('attestationFormRequest.verificationModal.title')}
      </ModalHeader>
      <DialogContent style={{ padding: 0 }}>
        <DocumentFormContainer
          canEdit={canEdit}
          canValidate={canValidate}
          documentIndex={documentIndex}
          documentsCount={totalDocument}
          documentTypes={documentTypes}
          fileData={fields?.[documentIndex]}
          formContext={formContext}
          isLoading={isLoading}
          isProof={isProof}
          options={options}
          update={update}
          watch={watch}
        />
      </DialogContent>
      <DocumentActionButtons
        canEdit={canEdit}
        documentNumber={documentIndex + 1}
        documentsCount={totalDocument}
        goBack={() => setDocumentIndex(documentIndex - 1)}
        isLoading={isLoading}
        isSaving={isSavingDocuments}
      />
    </FormContainer>
  );
};

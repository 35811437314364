const ONE_MEG = 1000000;

// LOCAL STORAGE FUNCTIONS
const GET = (key = '') => localStorage.getItem(key);

const SET = (key = '', value = '') => {
  if (typeof value === 'object') {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  return localStorage.setItem(key, value);
};

const DELETE = (key = '') => localStorage.removeItem(key);

// FORMAT FUNCTIONS
const formatToMB = (val) => {
  const opts = {
    maximumFractionDigits: 0,
    style: 'unit',
    unit: 'megabyte'
  };
  let result;
  try {
    result = new Intl.NumberFormat('fr-FR', opts).format(val / ONE_MEG);
  } catch (ex) {
    result = Math.round(val / ONE_MEG).toString();
  }
  return result;
};

// STORAGE FUNCTIONS
const hasStorage = () => navigator.storage;

const hasStoragePersist = () => hasStorage() && navigator.storage.persist;

const persistStorage = () => {
  if (hasStoragePersist()) {
    navigator.storage.persist();
  }
};

const checkStoragePersisted = () => {
  if (hasStoragePersist()) {
    navigator.storage.persisted().then((persisted) => {
      if (!persisted) {
        persistStorage();
      }
    });
  }
};

const getUsedQuota = () => (
  navigator.storage.estimate().then((quota) => {
    const remaining = quota.quota - quota.usage;

    return {
      remaining: formatToMB(remaining),
      used: quota.usage,
      total: quota.quota
    };
  })
);

export const StorageHelper = {
  GET,
  SET,
  DELETE,
  formatToMB,
  hasStorage,
  hasStoragePersist,
  persistStorage,
  checkStoragePersisted,
  getUsedQuota
};

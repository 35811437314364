export const GLOBAL_STYLES = (theme) => ({
  '*, ::after, ::before': {
    boxSizing: 'border-box'
  },

  html: {
    fontSize: '62.5%'
  },

  '@media (max-width: 768px)': {
    html: {
      fontSize: '56.5%'
    },

    body: {
      overflowY: 'scroll'
    },

    'body .MuiStepper-root': {
      padding: '24px 0'
    }
  },

  body: {
    minWidth: theme.sizes.wrapperWidthMin,
    margin: 0,
    padding: 0,
    fontSize: '1.6rem',
    fontFamily: "'Roboto','OpenSans', Arial, Helvetica, sans-serif",
    overflowY: 'scroll'
  },

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: `all ${theme.transitions.duration.standard}`
  },

  'a:hover, a.active': {
    color: theme.palette.primary.dark,
    textDecoration: 'underline'
  },

  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 100,
    margin: '0 0 .5rem 0'
  },

  h1: {
    fontSize: '3.6rem'
  },
  h2: {
    fontSize: '3.2rem'
  },
  h3: {
    fontSize: '2.8rem'
  },
  h4: {
    fontSize: '2.4rem'
  },
  h5: {
    fontSize: '2.2rem'
  },
  h6: {
    fontSize: '1.6rem'
  },

  small: {
    fontSize: '80%'
  },

  /* flexbox */

  '.display-flex': {
    display: 'flex'
  },

  '.flex-direction-column': {
    flexDirection: 'column'
  },

  '.justify-content': {
    justifyContent: 'center'
  },

  '.align-items': {
    alignItems: 'center'
  },

  '.flex-center': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  '.spacing-right > *:not(:last-child)': {
    marginRight: '4px'
  },

  '.spacing-bottom > *:not(:last-child)': {
    marginBottom: '8px'
  },

  '.flex-end': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  /* UTILS */
  '.m1': {
    margin: '0.75rem'
  },

  '.m2': {
    margin: '1rem'
  },

  '.m3': {
    margin: '1.5rem'
  },

  '.mr1': {
    marginRight: '0.8rem'
  },

  '.ml1': {
    marginLeft: '0.8rem'
  },

  '.mb1': {
    marginBottom: '0.8rem'
  },

  '.mt1': {
    marginTop: '0.8rem'
  },

  '.selectField': {
    borderRadius: '0 0 8px 8px'
  },

  '.step-completed': {
    color: `${theme.palette.success.main} !important`
  },

  /* IPHONE ZOOM INPUT FIX */
  // eslint-disable-next-line max-len
  'input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], select, textarea, select:focus, textarea:focus, input:focus': {
    fontSize: '1.8rem !important'
  },

  /* MATERIAL UI */
  'body .MuiButton-root': {
    minHeight: '36px'
  },

  /* TOGGLE BUTTONS */
  'body .MuiToggleButton-root': {
    minWidth: '90px',
    color: theme.palette.grey.main,
    borderColor: theme.palette.grey.main
  },

  'body .MuiToggleButton-root.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },

  'body .MuiToggleButton-root.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.main
  },

  'body .MuiToggleButton-root svg, body .MuiDialogTitle-root svg': {
    marginRight: '1rem'
  },

  /* MODALS */
  'body .MuiDialog-root': {
    zIndex: '1100 !important'
  },

  'body .MuiDialog-paper': {
    overflow: 'hidden'
  },

  'body .MuiDialog-paper form': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1
  },

  'body .MuiDialogTitle-root': {
    color: theme.palette.common.white,
    background: theme.palette.primary.dark
  },

  'body .MuiDialogTitle-root.errorModal': {
    background: theme.palette.error.main
  },

  'body .MuiDialogTitle-root.warningModal': {
    background: theme.palette.warning.main
  },

  'body .MuiDialogTitle-root.confirmModal': {
    background: theme.palette.success.main
  },

  'body .MuiDialogContent-root': {
    paddingTop: '16px'
  },

  /* TOOLTIPS */
  'body .MuiTooltip-tooltip': {
    fontSize: '1.2rem'
  },

  /* FORMS */
  'body .MuiInputBase-input.Mui-disabled': {
    backgroundColor: '#f3f3f3',
    cursor: 'not-allowed'
  },

  /* FAB */
  '.rtf--mb': {
    // eslint-disable-next-line max-len
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },

  '.rtf--mb:hover': {
    backgroundColor: '#01587E !important'
  },

  '.rtf.open .rtf--mb > *': {
    transform: 'none !important'
  }
});

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, IconButton } from 'components/_commons/Button';
import { ConditionalTooltip } from 'components/_commons/ConditionalTooltip/ConditionalTooltip';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useRef, useState } from 'react';
import { DocumentHelper, isMobile, translate } from 'utils';
import { MAX_FILE_SIZE } from 'utils/constants';
import { ICONS } from 'utils/icons';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const InputFileContainer = styled<any>('div')(({ ...props }) => {
  const styles: any = {
    'input[type="file"]': {
      position: 'absolute',
      visibility: 'hidden',
      zIndex: -1
    },

    '@media (max-width: 560px)': {
      maxWidth: '260px'
    }
  };

  if (!props.disabled) {
    styles.label = { cursor: 'pointer' };
  }

  return styles;
});

const InputFile = observer<{
  color?: string,
  disabled?: boolean,
  document?: any,
  endIcon?: any,
  fileAccepted?: string,
  fullWidth?: boolean,
  handleAddDocument: any,
  iconOnly?: boolean,
  id?: string | number,
  label?: string,
  labelButton?: string,
  labelVar?: any,
  maxSize?: number,
  maxWidth?: string,
  needResize?: boolean,
  optionalToolTipTranslationKey?: string,
  required?: boolean,
  startIcon: any,
  text?: string,
  type?: string,
  variant?: string,
  whiteSpace?: any
}>(({
  label, labelVar, labelButton, document, handleAddDocument, maxSize = MAX_FILE_SIZE, startIcon,
  id = 1, disabled = false, fullWidth = false, color, fileAccepted = '.pdf, .doc, .docx, .jpg, .jpeg, .png',
  maxWidth = '300px', required = false, needResize = false, type, endIcon, text, variant, whiteSpace, iconOnly = false,
  optionalToolTipTranslationKey
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef(null);
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const handleFileChange = useCallback((event) => {
    const allFiles = event.target.files;
    DocumentHelper.handleFileUpload(event, needResize, maxSize)
      .then((doc) => {
        const e = event;
        handleAddDocument(doc, setIsSavingDocument, allFiles);
        e.target.value = null;
      })
      .catch((error) => {
        if (error?.message === 'documentTooBig') {
          enqueueSnackbar(translate('errors.documentTooBig', { maxSize: MAX_FILE_SIZE }), { variant: 'error' });
        } else {
          enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' });
        }
      });
  }, [handleAddDocument, needResize, maxSize, enqueueSnackbar]);

  const isValid = Boolean((required && document) || !required);
  const customMaxWidth = isMobile() ? '170px' : maxWidth;

  const ButtonComponent = iconOnly ? IconButton : Button;

  return (
    <InputFileContainer disabled={disabled}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={2}
        wrap="wrap"
      >
        {label && (
          <Grid item>
            <Typography noWrap>
              {translate(label, labelVar)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <ButtonComponent
            color={color}
            disabled={disabled || isSavingDocument}
            fullWidth={fullWidth}
            isvalid={String(isValid)}
            maxwidth={customMaxWidth}
            type={type}
            variant={variant}
            whiteSpace={whiteSpace}
            onClick={() => inputFile.current.click()}
          >
            <ConditionalTooltip disableBackground translationKey={optionalToolTipTranslationKey}>
              <Grid alignItems="center" container marginTop="0" spacing={4} wrap="nowrap">
                {startIcon
                    && (
                      <Grid item paddingTop="0px !important">
                        <FontAwesomeIcon icon={isSavingDocument ? faSpinner : startIcon} spin={isSavingDocument} />
                      </Grid>
                    )}

                {!iconOnly && (
                  <Grid item paddingTop="0px !important">
                    {document?.name && (
                      <Typography noWrap>
                        {document.name}
                      </Typography>
                    )}
                    {(text || labelButton) && (
                      <Typography noWrap>{text ?? translate(labelButton)}</Typography>
                    )}
                  </Grid>
                )}
                {endIcon
                    && (
                      <Grid item paddingTop="0px !important">
                        <Grid container justifyContent="flex-end" spacing={1} wrap="nowrap">
                          <Grid item>
                            <FontAwesomeIcon icon={endIcon} />
                          </Grid>
                          <Grid item>
                            <FontAwesomeIcon icon={ICONS.PLUS.icon as any} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
              </Grid>
            </ConditionalTooltip>
          </ButtonComponent>

        </Grid>
      </Grid>

      <input
        accept={fileAccepted}
        disabled={disabled}
        id={`${id}_file`}
        ref={inputFile}
        type="file"
        onChange={handleFileChange}
      />
    </InputFileContainer>
  );
});

export default InputFile;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import shortid from 'shortid';
import { translate, UserHelper } from 'utils';
import { MENU_ITEMS } from 'utils/constants';

const StyledHeaderMenu = styled('nav')(({ theme }) => ({
  position: 'relative',
  height: theme.sizes.headerHeight,
  background: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey.light}`,
  zIndex: theme.zIndex.big
}));

const StyledLink = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '2rem 3rem',
  color: theme.palette.grey.darker,
  height: 60,
  textTransform: 'uppercase',

  '@media (max-width: 1300px)': {
    padding: '1rem 1.5rem',
    fontSize: '85%'
  },

  '@media (max-width: 1000px)': {
    fontSize: '70%'
  },

  '@media (max-width: 913px)': {
    fontSize: '100%'
  },

  '&.active, &:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '2px',
      left: 0,
      right: 0,
      height: '4px',
      background: theme.palette.primary.main
    }
  },

  svg: {
    marginRight: '1rem',

    '@media (max-width: 913px)': {
      margin: '0.5rem'
    }
  }
}));

const StyledSpan = styled('span')(() => ({
  '@media (max-width: 913px)': {
    display: 'none'
  }
}));

const MenuItem = ({ item }) => {
  const url = item.pathFunction();
  return (
    <Grid item key={shortid.generate()}>
      <StyledLink data-link={url} to={url}>
        <FontAwesomeIcon icon={item.icon} size="lg" />
        <StyledSpan>{translate(item.title)}</StyledSpan>
      </StyledLink>
    </Grid>
  );
};

export const HeaderMenu = observer(() => (
  <StyledHeaderMenu>
    <Grid alignItems="center" container justifyContent="flex-end">
      {MENU_ITEMS
        .filter((item) => (item.accessRights?.length ? UserHelper.hasAccessRight(item.accessRights) : true))
        .map((item) => <MenuItem item={item} key={item.title} />)}
    </Grid>
  </StyledHeaderMenu>
));

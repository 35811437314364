import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { DocumentAssignationContainer } from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationContainer';
import { DocumentAssignationModalActions } from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationModalActions';
import { DocumentAssignationModalStepsEnum } from 'core/enums/steps/document-assignation-modal-steps.enum';
import { ModalProps } from 'hooks/ModalProvider';
import React, { useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { translate } from 'utils';

export const DocumentAssignationModal = ({
  onClose,
  onSubmit,
  canValidate,
  requestId
}: ModalProps<any>) => {
  const formContext = useForm({});

  const [currentStep, setCurrentStep] = useState(DocumentAssignationModalStepsEnum.REGISTRATION);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);
  const [isAssigningDocument, setIsAssigningDocument] = useState(false);

  const onGoBack = () => {
    setCurrentStep(DocumentAssignationModalStepsEnum.prevStep(currentStep));
  };
  const onSubmitForm = (form) => {
    if (DocumentAssignationModalStepsEnum.isLast(currentStep)) {
      onSubmit({
        requestId,
        document: { documentDate: form.documentDate, type: form.documentType, ...form.document },
        toolIds: form.tools.map((tool) => tool.id)
      }, setIsAssigningDocument);
    } else {
      setCurrentStep(DocumentAssignationModalStepsEnum.nextStep(currentStep));
    }
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'documentAssignationForm',
        style: { overflow: 'auto', padding: 0 }
      }}
      onSuccess={onSubmitForm}
    >
      <ModalHeader onClose={onClose}>
        {translate('attestationFormRequest.assignationModal.title')}
      </ModalHeader>
      <DocumentAssignationContainer
        canValidate={canValidate}
        currentStep={currentStep}
        disableNextStep={setIsNextStepDisabled}
        formContext={formContext}
        requestId={requestId}
      />
      <DocumentAssignationModalActions
        currentStep={currentStep}
        disabled={isNextStepDisabled || isAssigningDocument}
        goBack={onGoBack}
        onClose={onClose}
      />
    </FormContainer>
  );
};

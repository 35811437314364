import { DialogTitle, Grid } from '@mui/material';
import React from 'react';
import { CloseButton } from './CloseButton';

export const ModalHeader = ({
  onClose, children, className = '', iconSize, isCloseDisabled = false
}: {
  children?: any,
  className?: string,
  iconSize?: string,
  isCloseDisabled?: boolean,
  onClose: () => void
}) => (
  <DialogTitle className={className} style={{ paddingRight: '4rem' }}>
    {children}
    <Grid alignItems="center" columnSpacing={2} container justifyContent="space-between">
      <Grid item>
        <CloseButton iconSize={iconSize} isCloseDisabled={isCloseDisabled} onClose={onClose} />
      </Grid>
    </Grid>
  </DialogTitle>
);

import { faFile, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle, faSearchPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card, CardContent, CardHeader, Divider, Grid, IconButton, Skeleton, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CardButton, CardButtonAdd } from 'components/_commons/Button';
import CustomIcon from 'components/_commons/CustomIcon/CustomIcon';
import { Document } from 'components/_commons/Document/Document';
import { GenericTooltip } from 'components/_commons/GenericTooltip/GenericTooltip';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useRef, useState } from 'react';
import { AttestationFormRequestService, DocumentService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { DocumentHelper, isTablet, translate } from 'utils';
import { MAX_FILE_SIZE } from 'utils/constants';
import { iconEnum } from 'utils/icons';

const CardContentWithoutBottomPadding = styled(CardContent)(() => ({
  '&:last-child': {
    paddingBottom: 12
  }
}));

export const DocumentCard = (({
  canEdit,
  documentList,
  label,
  template,
  description,
  type,
  keyInput,
  requestId,
  acceptedFormats,
  canValidate,
  updateUnreadDocumentAlerts,
  refreshDocumentCard
}) => {
  const theme = useTheme();
  const inputFile = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const { open } = useModal();

  const handleDelete = useCallback((id) => {
    if (id) {
      open({
        type: 'WARNING',
        onConfirm: () => (
          DocumentService.deleteAttestationFormDocument(id)
            .then(() => {
              refreshDocumentCard(type);
              enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentDeleted'), { variant: 'success' });
            })
        )
      });
    }
  }, [enqueueSnackbar, open, refreshDocumentCard, type]);

  const handleValidation = useCallback(() => {
    if (canValidate) {
      refreshDocumentCard(type);
      updateUnreadDocumentAlerts();
    }
  }, [canValidate, refreshDocumentCard, type, updateUnreadDocumentAlerts]);

  const showTemplate = useCallback(() => {
    open({
      type: 'DISPLAY_DOCUMENT',
      backdropClick: true,
      document: {
        name: template?.document?.name,
        base64Content: DocumentHelper.getDocumentWithBase64Typing(template?.document)
      }
    });
  }, [open, template?.document]);

  const renderEmptyDocument = useCallback(() => (canEdit
    ? (
      <CardButtonAdd
        icon={faFile}
        label={label}
        style={{ flex: 1, alignItems: 'center', display: 'flex' }}
        variant="outlined"
        onClick={() => inputFile.current.click()}
      />
    )
    : (
      <Card style={{ flex: 1 }} variant="outlined">
        <CardHeader
          style={{ backgroundColor: theme.palette.grey.lighter, textAlign: 'left', padding: 10 }}
          title={label}
          titleTypographyProps={{ variant: 'h5', style: { fontWeight: 'bold' } }}
        />
        <CardContentWithoutBottomPadding>
          <Grid alignItems="center" container direction="column" justifyContent="center">
            <FontAwesomeIcon color={theme.palette.primary.main} icon={iconEnum.emptySet.icon} size="1x" />
          </Grid>
        </CardContentWithoutBottomPadding>
      </Card>
    )), [canEdit, label, theme.palette.grey.lighter, theme.palette.primary.main]);

  const onChangeFile = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    DocumentHelper.handleFileUpload(event, false, MAX_FILE_SIZE)
      .then((doc) => AttestationFormRequestService.saveDocument(
        requestId,
        {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        },
        type
      )
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentSaved'), { variant: 'success' });
          refreshDocumentCard(type);
        }))
      .catch((error) => {
        if (error?.message === 'documentTooBig') {
          enqueueSnackbar(translate('errors.documentTooBig', { maxSize: MAX_FILE_SIZE }), { variant: 'error' });
        } else {
          enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'errror' });
        }
      });
  }, [requestId, type, enqueueSnackbar, refreshDocumentCard]);

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="center"
      spacing={1}
      style={{ display: 'flex', flex: 1 }}
      textAlign="center"
    >
      <input
        accept={acceptedFormats}
        id={`${keyInput}_file`}
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
        onChange={onChangeFile}
      />
      <Grid item style={{ flex: 1, height: '100%', display: 'flex' }} xs={9}>
        {
          documentList.length > 0
            ? (
              <Card style={{ flex: 1 }} variant="outlined">
                <CardHeader
                  style={{ backgroundColor: theme.palette.grey.lighter, textAlign: 'left', padding: 10 }}
                  title={label}
                  titleTypographyProps={{ variant: 'h5', style: { fontWeight: 'bold' } }}
                />
                <CardContentWithoutBottomPadding>
                  <Grid alignItems="center" container wrap="nowrap">
                    <Grid container direction="column" item spacing={canEdit ? 0 : 2} xs={canEdit ? 9 : 12}>
                      {documentList.map((document) => (
                        <React.Fragment key={shortid.generate()}>
                          <Grid container item style={{ maxWidth: '100%' }}>
                            <Document
                              canValidate={canValidate}
                              deletable={canEdit}
                              document={document}
                              handleDelete={() => handleDelete(document.id)}
                              handleValidation={handleValidation}
                              invalid={DocumentHelper.isInvalid(document)}
                              loadContent
                              name={document.document.name}
                              unread={DocumentHelper.notYetValidated(document)}
                              url={document.document.base64Content}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    {canEdit && (
                      <>
                        <Divider flexItem orientation="vertical" sx={{ mr: '-1px' }} variant="middle" />
                        <Grid item xs={3}>
                          <IconButton
                            title={translate('button.importDocument')}
                            onClick={() => inputFile.current.click()}
                          >
                            <CustomIcon
                              color={theme.palette.primary.main}
                              icon={faFile}
                              secondIcon={faPlus}
                            />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContentWithoutBottomPadding>
              </Card>
            )
            : renderEmptyDocument()
        }
      </Grid>
      <Grid item xs={3}>
        <Grid alignItems="center" container direction="row">
          {template && (
            <Grid item xs={description ? 8 : 12}>
              <CardButton variant="outlined" onClick={showTemplate}>
                <Grid container direction="column" spacing={1}>
                  <Grid item paddingLeft={2} paddingRight={2}>
                    <Typography>{translate('attestationFormRequest.tracability.documentOverview')}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" paddingLeft={1}>
                      <Skeleton animation={false} height={15} width="80%" />
                      <Skeleton animation={false} height={15} width="50%" />
                      <Skeleton animation={false} height={15} width="70%" />
                    </Grid>

                  </Grid>
                  <Grid item paddingBottom={1}>
                    <FontAwesomeIcon icon={faSearchPlus} size="2x" />
                  </Grid>
                </Grid>
              </CardButton>
            </Grid>
          )}
          {description && (
            <Grid item xs={4}>
              <GenericTooltip
                open={tooltipOpened}
                title={description}
                onClickAwayTablet={() => setTooltipOpened(false)}
              >
                {isTablet()
                  ? (
                    <IconButton onClick={() => setTooltipOpened(true)}>
                      <FontAwesomeIcon
                        color={theme.palette.primary.main}
                        icon={faQuestionCircle}
                        size="2x"
                      />
                    </IconButton>
                  )
                  : (
                    <FontAwesomeIcon
                      color={theme.palette.primary.main}
                      icon={faQuestionCircle}
                      size="2x"
                    />
                  )}

              </GenericTooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

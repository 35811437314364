import { DialogContent, Grid, Typography } from '@mui/material';
import { DocumentList } from 'components/_commons/Document/DocumentList';
import InfoLine from 'components/_commons/InfoLine/InfoLine';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import React, { useEffect, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { DocumentService } from 'services';
import { translate, UserHelper } from 'utils';
import { APPLICATION_MANAGEMENT_ROLES } from 'utils/constants';
import { DateHelper } from 'utils/helpers';

export const DisplayAuditModal = ({
  onClose, auditValues
}) => {
  const [fileData, setFileData] = useState(auditValues?.report);
  const [isFileDataLoading, setIsFileDataLoading] = useState(false);
  const isBackOffice = UserHelper.hasAccessRight(APPLICATION_MANAGEMENT_ROLES);

  useEffect(() => {
    if (!auditValues?.report?.content && auditValues?.report) {
      setIsFileDataLoading(true);
      DocumentService.getFileTransferModel(auditValues.report.id)
        .then((file) => setFileData(file))
        .finally(() => setIsFileDataLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <FormContainer
      FormProps={{
        autoComplete: 'off',
        name: 'auditForm',
        style: { overflow: 'auto' }
      }}
    >
      <ModalHeader onClose={onClose}>
        <Typography component="span">
          {translate('modalAudit.detailsAudit')}
        </Typography>
      </ModalHeader>
      <DialogContent style={{ width: '60vw', paddingLeft: '3rem' }}>
        <Grid container direction="column">
          <Typography margin={1} paddingTop={2} variant="h5">
            {translate('modalAudit.auditSection')}
          </Typography>
          <InfoLine displayIf info={auditValues?.type} label="modalAudit.auditInformation.type" />
          <InfoLine displayIf info={auditValues?.auditor?.label} label="modalAudit.auditInformation.auditor" />
          <InfoLine displayIf info={auditValues?.auditorFreeField} label="modalAudit.auditInformation.auditorFreeField" />
          <InfoLine
            displayIf
            info={DateHelper.formatDate(auditValues.appointment, 'dd/MM/yyyy')}
            label="modalAudit.auditInformation.appointment"
          />
          <InfoLine
            displayIf
            info={DateHelper.formatDate(auditValues.startDate, 'dd/MM/yyyy')}
            label="modalAudit.auditInformation.startDate"
          />

          <Typography margin={1} paddingTop={2} variant="h5">
            {translate('modalAudit.conclusionSection')}
          </Typography>
          <InfoLine displayIf info={auditValues?.status} label="modalAudit.auditInformation.status" />
          <InfoLine displayIf info={auditValues?.reason} label="modalAudit.auditInformation.reason" />
          <InfoLine displayIf info={auditValues?.comment} label="modalAudit.auditInformation.comment" />
          <InfoLine displayIf info={auditValues?.nonCompliances} label="modalAudit.auditInformation.nonCompliances" />
          {isBackOffice && (
            <InfoLine displayIf info={auditValues?.privateComment} label="modalAudit.auditInformation.privateComment" />
          )}

          <Typography margin={1} paddingBottom={1} paddingTop={2} variant="h5">
            {translate('modalAudit.reportSection')}
          </Typography>

          <Grid item width="fit-content">
            <DocumentList
              documents={(!isFileDataLoading && fileData) ? [{ document: fileData }] : []}
              importLabel="button.import"
              sizeLimit={1}
              spacing={2.5}
              titleStyles={{
                textAlign: 'center',
                variant: 'h6'
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>
    </FormContainer>
  );
};

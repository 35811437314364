import { RequestHelper } from 'utils';
import { API_ROUTES, BASE_API_ROUTES } from 'utils/constants';

// GET
const getUserAccounts = () => RequestHelper.GET(BASE_API_ROUTES.USER_ACCOUNTS);

const getUserAccountDetail = (userAccountHashId) => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS.DETAIL(userAccountHashId));

const getCurrentScope = () => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS.CURRENT_SCOPE());

const getAvailableStructureLevelsOptions = () => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS.CURRENT_STRUCTURE_LEVEL_OPTIONS());

const getAccessibleRolesWithStructureLevel = (companyId) => RequestHelper.GET(
  API_ROUTES.USER_ACCOUNTS.ROLES_BY_STRUCTURE_LEVEL(companyId)
);

const getCompanyChildrenOptions = () => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS.COMPANY_CHILDREN_OPTIONS());

// PUT
const updateLocale = (locale) => RequestHelper.PUT(API_ROUTES.USER_ACCOUNTS.UPDATE_LOCALE(locale));

const updateCurrentInstitution = (institution) => RequestHelper.PATCH(API_ROUTES.USER_ACCOUNTS.UPDATE_LEVEL(), institution);

const getUserList = (filter, page, signal) => RequestHelper.POST(API_ROUTES.USER_ACCOUNTS.GET_ALL(page), filter, null, signal);

const createUser = (user) => RequestHelper.POST(API_ROUTES.USER_ACCOUNTS.CREATE(), user);

const updateUser = (id, user) => RequestHelper.PUT(API_ROUTES.USER_ACCOUNTS.UPDATE(id), user);

const updateRoles = (id, roles) => RequestHelper.PUT(API_ROUTES.USER_ACCOUNTS.UPDATE_ROLES(id), roles);

const resetPassword = () => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS.RESET_PASSWORD());

export const UserAccountService = {
  getUserAccounts,
  getUserAccountDetail,
  getCurrentScope,
  getUserList,
  createUser,
  getAvailableStructureLevelsOptions,
  getAccessibleRolesWithStructureLevel,
  getCompanyChildrenOptions,
  updateLocale,
  updateUser,
  updateRoles,
  updateCurrentInstitution,
  resetPassword
};

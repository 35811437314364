import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

const StepTitle = ({ text, number }) => {
  const icon = iconEnum[`square${Number(number)}`] || iconEnum.listDot;
  return (
    <Grid alignItems="center" container direction="row" spacing={1}>
      <Grid item>
        <FontAwesomeIcon color={icon.defaultColor} icon={icon.icon} size="xl" />
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" variant="h7">
          {translate(text).toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StepTitle;

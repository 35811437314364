import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete, DialogContent, Grid, TextField, Typography
} from '@mui/material';
import { useFetch } from 'hooks';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { AttestationEvaluationService } from 'services/AttestationEvaluationService';
import { translate } from 'utils';
import { EVALUATION_ROLES } from 'utils/constants';
import { iconEnum } from 'utils/icons';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';
import { ModalProps } from '../../../hooks/ModalProvider';

export const RequestAssignationModal = ({
  onConfirm, onClose, defaultValue, role, requestId
}: ModalProps<any>) => {
  const [user, setUser] = useState(defaultValue);
  const {
    response: users,
    isLoading
  } = useFetch(() => AttestationEvaluationService.getEvaluatorOptions(requestId, role), [], [requestId, role]);
  const [isSavingAssignation, setIsSavingAssignation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectUser = useCallback((value) => setUser(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    setIsSavingAssignation(true);
    AttestationEvaluationService.assignEvaluator(requestId, role, user?.value ?? null).then(() => {
      if (user == null) {
        enqueueSnackbar(translate(`modalRequestAssignation.${role}.unassigned`), { variant: 'success' });
      } else {
        enqueueSnackbar(translate(`modalRequestAssignation.${role}.assigned`), { variant: 'success' });
      }
      onConfirm(setIsSavingAssignation);
      onClose();
    })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setIsSavingAssignation(false));
  }, [enqueueSnackbar, onClose, onConfirm, requestId, role, user]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={iconEnum.evaluation.icon as any} />
        <Typography component="span">
          {translate('modalRequestAssignation.title')}
        </Typography>
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column">
          <br />
          <Autocomplete
            disablePortal={false}
            id="assignUser"
            loading={isLoading}
            noOptionsText={translate('warnings.noOptionsAvailable')}
            options={users}
            renderInput={(params) => <TextField {...params} label={translate(`modalRequestAssignation.${role}.label`)} />}
            style={{ width: 300 }}
            value={user ?? null}
            onChange={(event, value) => handleSelectUser(value)}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons isSaving={isSavingAssignation} onClose={onClose} />
    </form>
  );
};

RequestAssignationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({}),
  role: PropTypes.oneOf([EVALUATION_ROLES.INSTRUCTOR, EVALUATION_ROLES.SUPERVISOR])
};

RequestAssignationModal.defaultProps = {
  defaultValue: {},
  role: EVALUATION_ROLES.INSTRUCTOR
};

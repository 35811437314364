import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const DatafluidesTooltip = styled(({
  className, title, children, ...props
}) => (
  <Tooltip classes={{ popper: className }} componentsProps={props} disableInteractive title={title}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '1.5rem',
    whiteSpace: 'pre-wrap'
  }
}));

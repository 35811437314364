import { Spinner } from 'components/_commons/Skeletons';
import React from 'react';

import { Grid } from '@mui/material';

import { DocumentBlock } from 'components/_commons/Modals/common/DocumentBlock';

export const DocumentStep = ({ formContext, documentTypes, documentTypesLoading }) => (
  <Grid container justifyContent="center">
    <Grid item md={10}>
      {
        documentTypesLoading
          ? <Spinner />
          : documentTypes.map((docType, index) => (
            <DocumentBlock
              documentDefaultValues={{ documentDate: undefined, type: docType }}
              fieldName={`equipmentView.documentList.innerDocuments.${docType.value}`}
              formContext={formContext}
              key={docType.value}
              labelButton={docType.label}
              showDivider={index !== (documentTypes.length - 1)}
              type="primary"
              variant={index ? 'outlined' : 'contained'}
              withTooltip
            />
          ))
      }
    </Grid>
  </Grid>
);

import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

const getInstitutionAttestationSummaries = (filter, page, signal) => RequestHelper.POST(
  API_ROUTES.INSTITUTIONS.ATTESTATION_SUMMARIES(page),
  filter,
  null,
  signal
);
const getInstitutionToolSummaries = (filter, page, signal) => RequestHelper.POST(
  API_ROUTES.INSTITUTIONS.TOOL_SUMMARIES(page),
  filter,
  null,
  signal
);
const getInstitutionDetails = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.DETAILS(institutionId));
const getInstitutionRequest = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.REQUEST(institutionId));
const getInstitutionAttestation = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.ATTESTATION(institutionId));
const createInstitutionAttestationRequest = (institutionId, categories) => RequestHelper.POST(
  API_ROUTES.INSTITUTIONS.INITIAL_REQUEST(institutionId),
  categories
);
const renewInstitutionAttestationRequest = (institutionId) => RequestHelper.POST(
  API_ROUTES.INSTITUTIONS.RENEWAL_REQUEST(institutionId)
);
const getInstitutionBilan = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.BILAN(institutionId));
const getInstitutionAudit = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.AUDIT(institutionId));
const getInstitutionAudits = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.AUDITS(institutionId));
const getInstitutionLegacyAudits = (institutionId) => RequestHelper.GET(
  API_ROUTES.INSTITUTIONS.LEGACY_AUDITS(institutionId)
);
const createInstitutionAudit = (institutionId, audit) => RequestHelper.POST(API_ROUTES.INSTITUTIONS.AUDIT(institutionId), audit);
const getInstitution = (institutionId) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.ADMINISTRATION(institutionId));
const updateInstitution = (institutionId, institution) => RequestHelper.PUT(
  API_ROUTES.INSTITUTIONS.ADMINISTRATION(institutionId),
  institution
);
const deleteInstitution = (institutionId) => RequestHelper.DELETE(API_ROUTES.INSTITUTIONS.ADMINISTRATION(institutionId));
const createInstitution = (institution) => RequestHelper.POST(API_ROUTES.INSTITUTIONS.SAVE(), institution);
const getInstitutionTree = (institutionId, activity) => RequestHelper.GET(
  API_ROUTES.INSTITUTIONS.TREE(institutionId, activity)
);
const getInstitutionHistory = (institutionModelId, filter, page, signal) => RequestHelper.POST(
  `${API_ROUTES.INSTITUTIONS.HISTORY(institutionModelId)}?page=${page}`,
  filter,
  null,
  signal
);
const getChangesSinceLastEvaluation = (institutionModelId, section) => RequestHelper.GET(
  `${API_ROUTES.INSTITUTIONS.SECTION_HISTORY(institutionModelId)}?section=${section}`
);

const getNotEditableInstitutions = (companyId) => RequestHelper.GET(
  API_ROUTES.INSTITUTIONS.NOT_EDITABLE(companyId)
);

const syderepExport = () => RequestHelper.GET(API_ROUTES.INSTITUTIONS.SYDEREP()).then((response) => {
  DocumentHelper.handleExcelFileDownload(response);
});

const expandedInstitutionsExport = (filter) => RequestHelper.POST(API_ROUTES.INSTITUTIONS.EXPANDED_INSTITUTIONS_EXPORT(), filter)
  .then((response) => { DocumentHelper.handleExcelFileDownload(response); });

const expandedInstitutionsExportByEmail = ((filters, signal) => RequestHelper.POST(
  API_ROUTES.INSTITUTIONS.EXPANDED_INSTITUTIONS_EXPORT_BY_MAIL(),
  filters,
  null,
  signal
));

const getInstitutionOptions = (filter) => RequestHelper.POST(API_ROUTES.INSTITUTIONS.OPTIONS(), filter);

const getCountByStatus = (entity) => RequestHelper.GET(API_ROUTES.INSTITUTIONS.STATUS_COUNT(entity));

export const InstitutionService = {
  getCountByStatus,
  getInstitutionAttestationSummaries,
  getInstitutionToolSummaries,
  getInstitutionDetails,
  getInstitutionRequest,
  getInstitutionAttestation,
  getInstitutionBilan,
  getInstitutionAudit,
  getInstitutionAudits,
  getInstitutionLegacyAudits,
  createInstitutionAudit,
  getInstitution,
  updateInstitution,
  deleteInstitution,
  createInstitution,
  getInstitutionTree,
  createInstitutionAttestationRequest,
  renewInstitutionAttestationRequest,
  getInstitutionHistory,
  getChangesSinceLastEvaluation,
  getNotEditableInstitutions,
  syderepExport,
  expandedInstitutionsExport,
  expandedInstitutionsExportByEmail,
  getInstitutionOptions
};

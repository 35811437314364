import { Chip } from '@mui/material';
import React from 'react';
import { AutocompleteElement } from 'react-hook-form-mui';
import { translate } from 'utils';

export const MultipleInputField = ({
  label, placeholder = '', required = false, variant = 'standard', fullWidth = false, validate, name
}: {
  fullWidth?: boolean,
  label: string,
  name: string,
  placeholder?: string,
  required?: boolean,
  validate: any,
  variant?: 'standard' | 'filled' | 'outlined'
}) => (
  <AutocompleteElement
    autocompleteProps={{
      fullWidth,
      id: 'tags-filled',
      freeSolo: true,
      autoSelect: true,
      renderTags: (value, getTagProps) => value.map((option, index) => (
        <Chip label={option} variant="outlined" {...getTagProps({ index })} />
      ))
    }}
    label={label}
    multiple
    name={name}
    options={[]}
    required={required}
    rules={{
      required: translate('common.requiredField'),
      validate: {
        validation: (value) => ((validate) ? validate(value) : true)
      }
    }}
    textFieldProps={{
      inputProps: { autoComplete: 'no-password' },
      placeholder,
      variant
    }}
  />
);

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconButton } from 'components/_commons/Button';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { InvalidDocumentIcon } from 'components/_commons/Icon/InvalidDocumentIcon';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const DocumentCount = ({
  title, color, count, hideCount, onShowDocuments, toReviewCount, invalidCount
}) => {
  const theme = useTheme();
  const clickable = count && onShowDocuments;
  const TextButton = styled('div')(() => ({
    cursor: clickable ? 'pointer' : 'unset',
    color: color ?? theme.palette.primary.main,
    fontWeight: clickable ? 'bold' : 'initial',
    fontSize: '1.4rem',
    margin: '0.75rem 0',
    '&:hover': {
      textDecoration: clickable ? 'underline' : 'none',
      cursor: clickable ? 'pointer' : 'unset'
    }
  }));

  return count > 0 && !hideCount
    && (
      <Grid alignItems="center" container direction="row" spacing={1}>
        <Grid item xs={(toReviewCount > 0 || invalidCount > 0) ? 9 : 12}>
          <TextButton onClick={count ? onShowDocuments : undefined}>
            {`${title} (${count})`}
          </TextButton>
        </Grid>
        {toReviewCount > 0
          && (
            <Grid alignItems="center" container direction="row" item spacing={1} xs={3}>
              <Grid item>
                <IconButton onClick={onShowDocuments}>
                  <DatafluidesTooltip placement="left" title={translate('attestationFormRequest.alerts.unread')}>
                    <FontAwesomeIcon
                      color={theme.palette.warning.main}
                      icon={iconEnum.eye.icon}
                    />
                  </DatafluidesTooltip>
                  <Typography color={theme.palette.warning.main} fontSize="1.4rem" fontWeight="bold" marginLeft={1}>
                    {toReviewCount}
                  </Typography>
                </IconButton>
              </Grid>
            </Grid>
          )}
        {invalidCount > 0 && toReviewCount === 0
          && (
            <Grid alignItems="center" container item spacing={1} xs={3}>
              <DatafluidesTooltip title={translate('attestationFormRequest.alerts.invalidDocument')}>
                <Grid item>
                  <InvalidDocumentIcon />
                </Grid>
              </DatafluidesTooltip>
              <Grid item>
                <Typography color={theme.palette.error.main} fontSize="1.4rem" fontWeight="bold">
                  {invalidCount}
                </Typography>
              </Grid>
            </Grid>
          )}
      </Grid>
    );
};

const toHtmlText = (text) => text.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s+)/gm, ' ');

const containsIgnoreCaseAndAccents = (string, substring) => {
  if (!string) {
    return false;
  }
  if (!substring) {
    return true;
  }
  return string.normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase()
    .includes(substring.normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase());
};

export const StringHelper = {
  toHtmlText,
  containsIgnoreCaseAndAccents
};

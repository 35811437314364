import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Button as MaterialButton, IconButton as MaterialIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils';

const CustomMaterialButton = ({
  children, type, ...props
}) => <MaterialButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialButton>;

const CustomMaterialIconButton = ({
  children, type, ...props
}) => <MaterialIconButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialIconButton>;

const StyledMaterialButton = styled(CustomMaterialButton)(({ theme, ...props }) => {
  const styles = { ...props.style };

  if (props.margin) {
    styles.margin = `${props.margin} !important`;
  }

  if (props.type === 'danger') {
    styles.color = `${theme.palette.common.white} !important`;
    styles.backgroundColor = `${theme.palette.error.main} !important`;
  }

  if (props.large) {
    styles.minWidth = '100%';
    styles.minHeight = '6rem !important';
  }

  if (props.type === 'danger') {
    styles['&:hover'].color = theme.palette.error.main;
  }

  if (props.whiteSpace) {
    styles['.MuiTypography-root'] = {
      whiteSpace: props.whiteSpace
    };
  }

  return styles;
});

const StyledMaterialIconButton = styled(CustomMaterialIconButton)(({ theme, ...props }) => {
  const styles = {};

  if (props.type === 'danger') {
    styles['&:hover'].color = theme.palette.error.main;
  }

  return styles;
});

export const Button = ({
  children, ...props
}) => (
  <StyledMaterialButton {...props}>
    {children}
  </StyledMaterialButton>
);

export const IconButton = ({
  children, ...props
}) => (
  <StyledMaterialIconButton {...props}>
    {children}
  </StyledMaterialIconButton>
);

export const ButtonBigIcon = ({
  label, icon, onClick
}) => (
  <MaterialButton
    color="primary"
    style={{ padding: '1rem 2rem' }}
    variant="text"
    onClick={onClick}
  >
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FontAwesomeIcon icon={icon} size="3x" />
      </Grid>
      <Grid item>
        {translate(label)}
      </Grid>
    </Grid>
  </MaterialButton>
);

Button.propTypes = {
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  margin: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  large: false,
  margin: '',
  type: 'primary',
  variant: 'contained'
};

import React from 'react';
import { pdfjs } from 'react-pdf';

import { faCloudDownload, faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Divider, Grid, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DocumentHelper, translate } from 'utils';

import { faClipboardCheck, faEmptySet } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { justifications } from 'components/AttestationForm';
import { DocumentDisplay } from 'components/_commons/Document/DocumentDisplay';
import { CheckboxGroup, FormTextareaField, InputFile } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import RadioButtonGroupFixed from 'components/_commons/Form/Inputs/RadioButton/RadioButtonGroupFixed';
import { InvalidDocumentIcon } from 'components/_commons/Icon/InvalidDocumentIcon';
import { SingleSelect } from 'components/_commons/Modals/common/SingleSelect';
import { WithLeftIconText } from 'components/_commons/Text';
import { TecneaTitle } from 'components/_commons/Title';
import { SPECIFIC_DOCUMENT_TYPES, VALIDATION_STATUS } from 'utils/constants';
import { DateHelper } from 'utils/helpers';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormContainer = styled('div')(() => ({
  padding: '1rem'
}));

const FormTitle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem',
  fontSize: '32px'
}));

export const DocumentFormContainer = ({
  fileData, documentIndex, documentTypes, options, documentsCount, formContext,
  update, watch, canValidate, isProof, canEdit = true, isLoading = false
}) => {
  const theme = useTheme();
  const withDate = !SPECIFIC_DOCUMENT_TYPES.includes(watch(`documents[${documentIndex}].type`)?.name);

  return (
    <Grid container direction="row" height="100%">
      <Grid item md={5} xs={7}>
        <DocumentDisplay
          base64Content={DocumentHelper.getDocumentWithBase64Typing(fileData)}
          documentName={fileData.name}
          downloadLabel={translate('common.documentTooBig')}
          height="100%"
          isLoadingContent={isLoading}
          width="100%"
        />
      </Grid>
      <Grid item md={7} xs={5}>
        <FormContainer>
          <FormTitle>
            {isProof
              ? `${translate('attestationFormRequest.verificationModal.proof')}  ${documentIndex + 1}/${documentsCount}`
              : translate('attestationFormRequest.verificationModal.proof')}
          </FormTitle>
          <Divider style={{ margin: '20px' }} />
          <Grid container justifyContent="center">
            <Grid item md={10}>
              <Grid alignItems="center" container spacing={3}>
                {isProof && (
                  <>
                    <Grid item md={12}>
                      <TecneaTitle label={translate('attestationFormRequest.verificationModal.information')} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SingleSelect
                        disabled={!canEdit}
                        key={documentIndex}
                        label={translate('attestationFormRequest.verificationModal.proofType')}
                        name={`documents[${documentIndex}].type`}
                        options={documentTypes ?? []}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                          {withDate && (
                            <FormDatePicker
                              disabled={!canEdit}
                              disableFuture
                              key={documentIndex}
                              label="common.documentDate"
                              name={`documents[${documentIndex}].documentDate`}
                              required
                              validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
                              value={formContext.watch(`documents[${documentIndex}].documentDate`)}
                            />
                          )}
                        </Grid>
                        {(formContext.watch(`documents[${documentIndex}].type`)?.value
                          === justifications.APTITUDE_ATTESTATION) && (
                          <Grid item md={8} xs={12}>
                            <CheckboxGroup
                              disabled={!canEdit}
                              key={documentIndex}
                              name={`documents[${documentIndex}].attestationCategories`}
                              options={options ?? []}
                              required
                              row
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <InputFile
                        disabled={!canEdit}
                        handleAddDocument={(file) => {
                          const updatedDocument = {
                            ...fileData,
                            ...file,
                            base64Content: DocumentHelper.getDocumentWithoutBase64(file)
                          };
                          delete updatedDocument.content;
                          update(documentIndex, updatedDocument);
                          formContext.setValue(`documents[${documentIndex}].validation`, null);
                        }}
                        labelButton="attestationFormRequest.verificationModal.replaceDocument"
                        startIcon={faCloudUpload}
                        type="primary"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant="outlined"
                        onClick={() => DocumentHelper.downloadDocumentFromBase64(fileData.base64Content, fileData.name)}
                      >
                        <Grid alignItems="center" container spacing={4} wrap="nowrap">
                          <Grid item xs={1}>
                            <FontAwesomeIcon icon={faCloudDownload} />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography noWrap>
                              {translate('attestationFormRequest.verificationModal.downloadDocument')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  </>
                )}
                {canValidate && (
                  <>
                    <Grid item md={12}>
                      <TecneaTitle label={translate('attestationFormRequest.verificationModal.validation')} />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        color={theme.palette.warning.main}
                        paddingLeft="2rem"
                      >
                        {translate('warnings.documentValidation.comment')}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <RadioButtonGroupFixed
                        controlledValue={formContext.watch(`documents[${documentIndex}].validation.validationStatus`)
                            ?? VALIDATION_STATUS.UNDEFINED}
                        disabled={!canValidate}
                        key={documentIndex}
                        name={`documents[${documentIndex}].validation.validationStatus`}
                        options={[
                          {
                            label: <WithLeftIconText
                              color={theme.palette.success.main}
                              icon={faClipboardCheck}
                              label={translate('print.enum.DocumentValidationStatus.VALID')}
                              spacing={1}
                            />,
                            value: VALIDATION_STATUS.VALID
                          },
                          {
                            label: <WithLeftIconText
                              customIcon={<InvalidDocumentIcon size="2x" />}
                              label={translate('print.enum.DocumentValidationStatus.RELEVANT_INVALID')}
                              spacing={1}
                            />,
                            value: VALIDATION_STATUS.RELEVANT_INVALID
                          },
                          {
                            label: <WithLeftIconText
                              color={theme.palette.grey.main}
                              icon={faEmptySet}
                              label={translate('print.enum.DocumentValidationStatus.INVALID')}
                              spacing={1}
                            />,
                            value: VALIDATION_STATUS.INVALID
                          }
                        ]}
                        valueKey="value"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormTextareaField
                        fullWidth
                        key={documentIndex}
                        label={translate('common.documentValidation.comment')}
                        name={`documents[${documentIndex}].validation.comment`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>
    </Grid>
  );
};

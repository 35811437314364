export const RequestStatusEnum = {
  INCOMPLETE: 'INCOMPLETE',
  IN_MODIFICATION: 'IN_MODIFICATION',
  SUBMITTED: 'SUBMITTED',
  TO_INSTRUCT: 'TO_INSTRUCT',
  INSTRUCTING: 'INSTRUCTING',
  TO_SUPERVISE: 'TO_SUPERVISE',
  SUPERVISING: 'SUPERVISING',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  CANCELLED: 'CANCELLED'
};

export const withAlertsRequestStatus = [
  RequestStatusEnum.INCOMPLETE,
  RequestStatusEnum.IN_MODIFICATION,
  RequestStatusEnum.SUBMITTED,
  RequestStatusEnum.TO_INSTRUCT
];

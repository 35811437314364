import { Button, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils';

export const CloseModalButton = ({
  onClose
}) => (
  <DialogActions>
    <Button
      variant="text"
      onClick={onClose}
    >
      {translate('button.close')}
    </Button>
  </DialogActions>
);

CloseModalButton.propTypes = {
  onClose: PropTypes.func.isRequired
};

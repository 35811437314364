import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  DialogContent, FormControl, Grid, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Checkbox, FormInputField } from 'components/_commons/Form/Inputs';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import { UpdateRolesForm } from 'components/_commons/Modals/common/UpdateRolesForm';
import { Spinner } from 'components/_commons/Skeletons';
import debounce from 'debounce-promise';
import { useAccessRights, useStores } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { CompanyService, UserAccountService } from 'services';

import { MultipleInputField } from 'components/_commons/Form/Inputs/InputField/MultipleInputField';
import { ModalProps } from 'hooks/ModalProvider';
import { translate } from 'utils';
import { APPLICATION_BACK_OFFICE_ADMIN_ROLES, APPLICATION_ROLES } from 'utils/constants';
import { Validators } from 'utils/helpers';

export const CreateUserModal = ({ onClose, onSubmit }: ModalProps<any>) => {
  const { userStore } = useStores();
  const { scope, currentCompany } = userStore;
  const theme = useTheme();
  const [companies, setCompanies] = useState([]);
  const [initialCompanies, setInitialCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accessibleRolesLoading, setAccessibleRolesLoading] = useState(false);
  const [accessibleRolesByStructureLevel, setAccessibleRolesByStructureLevel] = useState(new Map());
  const [isSavingUser, setIsSavingUser] = useState(false);
  const isBackOfficeAdmin = useAccessRights({ roles: APPLICATION_BACK_OFFICE_ADMIN_ROLES });
  const isFrontOfficeAdmin = useAccessRights({ roles: [APPLICATION_ROLES.OPERATOR_ADMIN] });

  const formContext = useForm({
    defaultValues: {
      login: null,
      person: {
        firstName: null,
        lastName: null,
        mainCompany: null,
        contact: {
          technicalEmail: ''
        }
      },
      accountActivated: false,
      roles: []
    }
  });

  const accountActivated = formContext.watch('accountActivated');
  const mainCompany = formContext.watch('person.mainCompany');

  useEffect(() => {
    if (accountActivated && mainCompany) {
      setAccessibleRolesLoading(true);
      UserAccountService.getAccessibleRolesWithStructureLevel(mainCompany?.value)
        .then((response) => {
          const map = new Map();
          Object.values(response).forEach((value: any) => map.set(value.structureLevelOption, value.roleSelectItems));
          setAccessibleRolesByStructureLevel(map);
        }).finally(() => setAccessibleRolesLoading(false));
    } else {
      setAccessibleRolesByStructureLevel(new Map());
    }
  }, [accountActivated, mainCompany]);

  const onSubmitModal = useCallback((values) => {
    const result = {
      ...values,
      person: {
        ...values?.person,
        contact: {
          ...values?.person?.contact,
          contactEmails: values?.person?.contact?.contactEmails.join(';')
        }
      }
    };
    !result.person.mainCompany && (result.person.mainCompany = scope.company);
    onSubmit(result, setIsSavingUser);
  }, [onSubmit, scope.company]);

  const getCompaniesPromise = useCallback((freeSearch) => new Promise((resolve, reject) => {
    if (isBackOfficeAdmin) {
      CompanyService.getCompanySelectItemList({ freeSearch }, 0)
        .then((response) => resolve(response?.content))
        .catch((error) => reject((error && error.message) || error));
    } else if (isFrontOfficeAdmin) {
      UserAccountService.getCompanyChildrenOptions()
        .then((response) => (response.length ? resolve(response) : resolve([currentCompany])))
        .catch((error) => reject((error && error.message) || error));
    }
  }), [isBackOfficeAdmin, isFrontOfficeAdmin, currentCompany]);

  const debouncedFetchCompanies = debounce(getCompaniesPromise, 500);

  const fetchCompanies = useCallback((freeSearch) => {
    setIsLoading(true);
    debouncedFetchCompanies(freeSearch)
      .then((resp) => {
        setCompanies(resp);
        if (initialCompanies.length < 1) {
          setInitialCompanies(resp);
        }
      })
      .finally(() => setIsLoading(false));
      // eslint-disable-next-line
  }, [initialCompanies?.length]);

  useEffect(() => {
    fetchCompanies('');
    // eslint-disable-next-line
  }, []);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'addUserForm' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faUserPlus as any} />
        {translate('pageUserList.modal.title')}
      </ModalHeader>

      <DialogContent style={{ marginTop: '30px', [theme.breakpoints.up('md')]: { overflowY: 'hidden' } }}>
        <Grid container direction="row" item spacing={2} style={{ maxWidth: '1400px' }}>
          <Grid item style={{ width: '500px' }}>
            <Checkbox label={translate('common.userAccount')} name="accountActivated" />
            <FormControl fullWidth variant="standard">
              {initialCompanies && initialCompanies.length > 0 && (
                <StyledInput>
                  <FormSelectField
                    autoSelect
                    clearOnBlur
                    fullWidth
                    getOptionLabel={option => option?.label}
                    label={translate('common.company')}
                    loading={isLoading}
                    loadingText={translate('common.loading')}
                    name="person.mainCompany"
                    noOptionsText={translate('warnings.noOptionsAvailable')}
                    options={companies ?? []}
                    renderOption={(props, option) => (
                      <ItemOption {...props} isInsideSelect key={option.id}>
                        <Typography variant="h6">
                          {option.label}
                        </Typography>
                      </ItemOption>
                    )}
                    required
                    variant="outlined"
                  />
                </StyledInput>
              )}
              <StyledInput>
                <FormInputField fullWidth label={translate('common.lastName')} name="person.lastName" required />
              </StyledInput>
              <StyledInput>
                <FormInputField fullWidth label={translate('common.firstName')} name="person.firstName" required />
              </StyledInput>
              <StyledInput>
                <MultipleInputField
                  fullWidth
                  label={translate('common.contact')}
                  name="person.contact.contactEmails"
                  required
                  validate={Validators.validateEmails}
                />
              </StyledInput>
              {
                accountActivated && (
                  <>
                    <StyledInput>
                      <FormInputField
                        fullWidth
                        label={translate('common.technicalEmail')}
                        name="person.contact.technicalEmail"
                        required
                        validation={{
                          validate: (value) => Validators.validateEmail(value)
                        }}
                      />
                    </StyledInput>
                    <StyledInput>
                      <FormInputField fullWidth label={translate('common.login')} name="login" required />
                    </StyledInput>
                  </>
                )
              }
            </FormControl>
          </Grid>
          {
            accountActivated && (
              <Grid item style={{ maxHeight: '800px', width: '550px' }}>
                {accessibleRolesLoading || isLoading ? (
                  <Spinner />
                ) : (
                  <UpdateRolesForm
                    accessibleRolesByStructureLevel={accessibleRolesByStructureLevel}
                    formContext={formContext}
                    initialRoles={[]}
                  />
                )}
              </Grid>
            )
          }
        </Grid>
      </DialogContent>

      <CustomActionButtons isSaving={isSavingUser} submitLabelKey="common.create" onClose={onClose} />
    </FormContainer>
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';

export const WithLeftIconText = ({
  label, icon, spacing, customIcon, size, height, color, labelColor, fontWeight, ellipsis, description, defaultIconXs = 0
}) => {
  const typographyEllipsis = ellipsis ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {};

  const getTextXs = useCallback(() => {
    if (ellipsis) {
      return 9;
    }
    return defaultIconXs ? 12 - defaultIconXs : 0;
  }, [defaultIconXs, ellipsis]);

  return (
    <Grid alignItems="center" container direction="row" item spacing={spacing} style={height ? { height } : {}}>
      {(icon || customIcon) && (
        <Grid item xs={ellipsis ? 3 : defaultIconXs}>
          {customIcon
              ?? (
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon color={color} icon={icon} size={size} />
                </span>
              )}
        </Grid>
      )}
      <Grid item xs={getTextXs()}>
        <Typography
          color={labelColor}
          fontWeight={fontWeight}
          variant="h6"
          {...typographyEllipsis}
          sx={{ wordBreak: 'break-all' }}
        >
          {label}
        </Typography>
        {
          description && (
            <Typography
              color={labelColor}
              variant="h6"
              {...typographyEllipsis}
            >
              {description}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
};

import { compact, find } from 'lodash';
import { ATTESTATION_FORM_ACTIONS, ATTESTATION_FORM_STEPS } from 'utils/constants';

export const DecisionEnum = {
  CONFORM: 'CONFORM',
  NOT_CONFORM: 'NOT_CONFORM',
  REJECTION: 'REJECTION'
};

export const SectionEnum = {
  PurchaseOrder: 'PURCHASE_ORDER',
  Contacts: 'CONTACTS',
  Operators: 'OPERATORS',
  Tooling: 'TOOLING',
  Tracability: 'TRACABILITY',
  Transmission: 'TRANSMISSION',
  Conclusion: 'CONCLUSION'
};

export function attestationFormFilteredSteps(isInstructingOrSupervising, availableActions) {
  const canEditForm = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM);

  return compact([
    SectionEnum.PurchaseOrder,
    SectionEnum.Contacts,
    SectionEnum.Operators,
    SectionEnum.Tooling,
    SectionEnum.Tracability,
    !isInstructingOrSupervising && canEditForm && SectionEnum.Transmission,
    isInstructingOrSupervising && canEditForm && SectionEnum.Conclusion
  ]).map((section) => find(ATTESTATION_FORM_STEPS, { section }));
}

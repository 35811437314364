import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { SelectField } from 'components/_commons/Form/Inputs/index';
import { StructureLevelSelect } from 'components/_commons/Modals/common/StructureLevelSelect';

export const ActivityRoleSelector = ({
  handleChangeLevel,
  defaultLevel,
  handleChangeLevelRoles,
  defaultLevelRoles,
  accessibleLevels,
  accessibleRoles,
  selectedLevels,
  isClearable,
  isLevelDisabled,
  compareRoles
}) => {
  const [level, setLevel] = useState(defaultLevel);
  const [levelRoles, setLevelRoles] = useState(defaultLevelRoles?.map(
    (value) => ({
      ...value,
      clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
    })
  ));

  const updateLevelRoles = useCallback((roles) => {
    const newRoles = roles
      ? roles.map((role) => (({
        ...role,
        clearableValue: role.clearableValue ?? accessibleRoles?.map((r) => r.value).includes(role.value),
        structureLevel: level
      })))
      : [];
    setLevelRoles(newRoles);
    handleChangeLevelRoles(newRoles);
  }, [accessibleRoles, handleChangeLevelRoles, level]);

  useEffect(() => {
    if (!isEmpty(levelRoles)) {
      updateLevelRoles(levelRoles);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <StructureLevelSelect
        isClearable={!isEmpty(level) && isClearable}
        isDisabled={
          level.value
            && (
              !accessibleLevels.find((al) => al.value === level.value) || isLevelDisabled
            )
        }
        label="pageUserDetails.modal.chooseStructureLevel"
        menuPlacement="top"
        options={accessibleLevels.map((option) => {
          if (selectedLevels.some((selectedLevel) => selectedLevel.value === option.value)) {
            return {
              ...option,
              isDisabled: true
            };
          }
          return option;
        })}
        selectedItem={level}
        onSelectItem={(value) => {
          setLevel(value);
          handleChangeLevel(value);
        }}
      />

      {!isEmpty(level)
        && (
          <div style={{ margin: 20 }}>
            <SelectField
              closeMenuOnSelect={accessibleRoles?.filter((option) => !levelRoles
                .map((lr) => lr.value)
                .includes(option.value))?.length === 1}
              isClearable={isClearable}
              isMulti
              isOptionDisabled={(option) => !accessibleRoles.includes(option)}
              label="pageUserDetails.modal.roleStructureLevel"
              name="activity-roles"
              options={accessibleRoles}
              value={levelRoles.sort((role1, role2) => compareRoles(role1, role2))}
              onChange={updateLevelRoles}
            />
          </div>
        )}
    </>
  );
};

import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useModal } from '../../../hooks';
import { FluidBalanceEntryService } from '../../../services/FluidBalanceEntryService';
import { translate } from '../../../utils';
import { FLUID_BALANCE_ENTRY_VALIDATION_STATUS } from '../../../utils/constants';
import { ValidationSwitchTriple } from './ValidationSwitchTriple';

export const StatusValidationToggle = ({
  row, updateEntryInList
}) => {
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [validationStatus, setValidationStatus] = useState(row?.validationStatus);

  const doValidateEntry = useCallback((fluidBalanceEntry, removeComments) => {
    FluidBalanceEntryService.validateFluidBalanceEntry(
      fluidBalanceEntry.id,
      removeComments
    ).then(() => {
      setValidationStatus(FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID);
      const updatedExchangeHistory = fluidBalanceEntry.exchangeHistory.filter(
        (e) => !(removeComments && e?.comment?.canDelete)
      );
      updateEntryInList({
        ...fluidBalanceEntry,
        exchangeHistory: [...updatedExchangeHistory],
        validationStatus: FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID
      });
      enqueueSnackbar(translate('fluidBalanceEntry.validateModal.snackbar.success'), { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar((error && error.message) || error, { variant: 'error' });
    });
  }, [enqueueSnackbar, updateEntryInList, setValidationStatus]);

  const validateEntry = useCallback((fluidBalanceEntry) => {
    // If the row doesn't have any invalidation elements, simply validate the entry
    if (!fluidBalanceEntry.exchangeHistory.some((e) => e?.comment?.canDelete)) {
      doValidateEntry(fluidBalanceEntry, false);
      return;
    }
    open({
      type: 'VALIDATE_FLUID_BALANCE_ENTRY',
      onSubmit: (removeComments) => doValidateEntry(
        fluidBalanceEntry,
        removeComments
      ),
      title: 'fluidBalanceEntry.validateModal.title',
      closeOnSubmit: true
    });
  }, [open, doValidateEntry]);

  const rejectEntry = useCallback((fluidBalanceEntry) => {
    open({
      type: 'REJECT_FLUID_BALANCE_ENTRY',
      onSubmit: (fluidBalanceEntryCommentViews) => FluidBalanceEntryService.rejectFluidBalanceEntry(
        fluidBalanceEntry.id,
        fluidBalanceEntryCommentViews
      )
        .then((views) => {
          setValidationStatus(FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID);
          updateEntryInList({
            ...fluidBalanceEntry,
            exchangeHistory: [...fluidBalanceEntry.exchangeHistory, ...views.map((v) => ({ comment: v }))],
            validationStatus: FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID
          });
          enqueueSnackbar(translate('fluidBalanceEntry.rejectModal.snackbar.success'), { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar((error && error.message) || error, { variant: 'error' });
        }),
      title: 'fluidBalanceEntry.rejectModal.title',
      closeOnSubmit: true
    });
  }, [open, enqueueSnackbar, updateEntryInList, setValidationStatus]);

  const handleToggleChange = useCallback((e, fluidBalanceEntry, value) => {
    const isValid = value === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID;
    const isInvalid = value === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID;
    e.preventDefault();

    if (isValid || fluidBalanceEntry?.validationStatus === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.INVALID) {
      validateEntry(fluidBalanceEntry);
    } else if (isInvalid || fluidBalanceEntry?.validationStatus === FLUID_BALANCE_ENTRY_VALIDATION_STATUS.VALID) {
      rejectEntry(fluidBalanceEntry);
    }
  }, [rejectEntry, validateEntry]);

  return (
    <ValidationSwitchTriple
      name="validationStatusToggle"
      value={validationStatus === null ? FLUID_BALANCE_ENTRY_VALIDATION_STATUS.NONE : validationStatus}
      onChange={(e, value) => handleToggleChange(e, row, value)}
    />
  );
};

import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

const getEnumOptions = (enumName) => RequestHelper.GET(API_ROUTES.ENUM_OPTIONS.OPTIONS(enumName));
const getEnumTooltips = (enumName) => RequestHelper.GET(API_ROUTES.ENUM_OPTIONS.TOOLTIPS(enumName));
const getEnumValues = (enumName) => RequestHelper.GET(API_ROUTES.ENUM_OPTIONS.VALUES(enumName));

export const EnumService = {
  getEnumOptions,
  getEnumValues,
  getEnumTooltips
};

export const enumOptionTypes = {
  ATTESTATION_FORM_REQUEST_WORK_MODE: 'ATTESTATION_FORM_REQUEST_WORK_MODE',
  OPERATOR_WORK_MODE: 'OPERATOR_WORK_MODE',
  ATTESTATION_FORM_REQUEST_TYPE: 'ATTESTATION_FORM_REQUEST_TYPE',
  ATTESTATION_FORM_REQUEST_STATUS: 'ATTESTATION_FORM_REQUEST_STATUS',
  ATTESTATION_STATUS: 'ATTESTATION_STATUS',
  AUDIT_STATUS: 'AUDIT_STATUS',
  AUDIT_TYPE: 'AUDIT_TYPE',
  FLUID_BALANCE_STATUS: 'FLUID_BALANCE_STATUS',
  HISTORY_EVENT_TYPE: 'HISTORY_EVENT_TYPE',
  RECORDABLE_CLASS: 'RECORDABLE_CLASS',
  SECTION_DOCUMENT: 'SECTION_DOCUMENT'
};

export const enumValueTypes = {
  ATTESTATION_CATEGORY: 'ATTESTATION_CATEGORY'
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { DISABLE_ELEMENT_STYLE } from 'utils/constants';
import { ICONS } from 'utils/icons';

const StrikedThroughTableCell = styled(TableCell)(({ theme, disabled }) => {
  if (disabled) {
    return DISABLE_ELEMENT_STYLE(theme);
  }
  return {};
});

export const TableTextCell = ({ value, disabled }) => (
  <StrikedThroughTableCell disabled={disabled}>
    {
      value
        ? <Typography color={disabled && 'grey.main'} component="div">{value}</Typography>
        : <FontAwesomeIcon icon={ICONS.NONE.icon} />
    }
  </StrikedThroughTableCell>
);

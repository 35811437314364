import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { SelectField } from '..';

const SelectLanguage = ({
  value = '', onChange, required = false, isFetchingLanguages = false, languages
}: {
  isFetchingLanguages?: boolean,
  languages: any,
  onChange: any,
  required?: boolean,
  value?: string
}) => {
  const handleChange = useCallback((language) => onChange(language), [onChange]);

  return isFetchingLanguages
    ? (
      <FontAwesomeIcon icon={faSpinner as any} spin />
    ) : (
      <SelectField
        isClearable={false}
        label="common.selectLanguage"
        name="language"
        options={languages}
        required={required}
        value={value}
        onChange={handleChange}
      />
    );
};

export default SelectLanguage;

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { translate } from '../../../utils';
import RadioButtonGroupFixed from '../Form/Inputs/RadioButton/RadioButtonGroupFixed';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';

export const ValidateFluidBalanceEntryModal = ({ onClose, onSubmit }) => {
  const [removeAllComments, setRemoveAllComments] = useState(false);
  const stringToBoolean = (value) => value === 'true';

  return (
    <FormContainer
      FormProps={{ autoComplete: 'off', name: 'validateFluidBalanceEntry' }}
      onSuccess={() => onSubmit(removeAllComments)}
    >
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate('fluidBalanceEntry.validateModal.title')}
      </ModalHeader>
      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <Grid alignItems="center" container direction="column" spacing={2}>
          <Grid item>
            <Typography>
              {translate('fluidBalanceEntry.validateModal.content')}
            </Typography>
          </Grid>
          <Grid item>
            <RadioButtonGroupFixed
              name="fluidBalanceEntry.validateModal.removeComments"
              options={[
                {
                  label: translate('common.yes'),
                  value: 'true'
                },
                {
                  label: translate('common.no'),
                  value: 'false'
                }
              ]}
              required
              row
              valueKey="value"
              onChange={(value) => setRemoveAllComments(stringToBoolean(value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <CustomActionButtons
        disabled={removeAllComments === undefined}
        onClose={onClose}
      />
    </FormContainer>
  );
};

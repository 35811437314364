import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Typography } from '@mui/material';
import { Document } from 'components/_commons/Document/Document';
import { InputFile } from 'components/_commons/Form';
import React from 'react';
import shortid from 'shortid';
import { DocumentHelper, translate } from 'utils';

export const DocumentList = ({
  documents, onDelete, onImport, canEdit = false, title, importLabel, titleStyles, optionalToolTipTranslationKey,
  sizeLimit, variant, spacing, canValidate = false, handleValidation, sideways = false
}: {
  canEdit?: boolean,
  canValidate?: boolean,
  documents: any,
  handleValidation?: any,
  importLabel: string,
  onDelete: any,
  onImport: any,
  optionalToolTipTranslationKey?: string,
  sideways?: boolean,
  sizeLimit?: number,
  spacing: any,
  title?: string,
  titleStyles: any,
  variant?: any
}) => (
  <Grid container direction={sideways ? 'row' : 'column'} spacing={spacing} wrap={sideways ? 'nowrap' : 'wrap'}>
    {documents?.length > 0 && (
      <>
        {title && (
          <Grid item>
            <Typography {...titleStyles}>
              {translate(title)}
            </Typography>
          </Grid>
        )}
        <Grid item width="100%">
          <Grid container direction="column" spacing={spacing - 1}>
            {documents.map((file) => (
              <Grid item key={shortid.generate()} width="100%">
                <Document
                  base64Content={file.document.base64Content}
                  canValidate={canValidate}
                  deletable={canEdit}
                  document={file}
                  handleDelete={() => onDelete(file)}
                  handleValidation={handleValidation}
                  invalid={DocumentHelper.isInvalid(file)}
                  name={file.document.name}
                  tooltip="attestationFormRequest.purchaseOrder.visualizeAdditionalFile"
                  unread={DocumentHelper.notYetValidated(file)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    )}
    {(canEdit && (!sizeLimit || documents?.length < sizeLimit))
        && (
          <Grid item>
            <InputFile
              handleAddDocument={onImport}
              id="importDocumentsButton"
              labelButton={importLabel}
              maxWidth="fit-content"
              optionalToolTipTranslationKey={optionalToolTipTranslationKey}
              startIcon={faFile}
              variant={variant}
            />
          </Grid>
        )}
  </Grid>
);

import { faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent } from '@mui/material';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { UpdateRolesForm } from 'components/_commons/Modals/common/UpdateRolesForm';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { translate } from 'utils';
import { ModalProps } from 'hooks/ModalProvider';

export const UpdateRolesModal = ({ onClose, onSubmit, modalState }: ModalProps<any>) => {
  const { userData, accessibleRolesByStructureLevel } = modalState;

  const formContext = useForm({
    defaultValues: {
      roles: userData.roles
    }
  });

  const roles = formContext.watch('roles');
  const [isUpdatingRoles, setIsUpdatingRoles] = useState(false);

  const onSubmitModal = useCallback(() => {
    onSubmit(roles, setIsUpdatingRoles);
  }, [onSubmit, roles]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'updateRolesForm' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faUserTag as any} />
        {
          translate('pageUserDetails.modal.roleTitle', {
            fullName: `${userData?.person?.firstName} ${userData?.person?.lastName}`
          })
        }
      </ModalHeader>

      <DialogContent style={{ width: '50vw', paddingTop: '30px' }}>
        <UpdateRolesForm
          accessibleRolesByStructureLevel={accessibleRolesByStructureLevel}
          formContext={formContext}
          initialRoles={roles}
        />
      </DialogContent>

      <CustomActionButtons isSaving={isUpdatingRoles} onClose={onClose} />
    </FormContainer>
  );
};

UpdateRolesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

UpdateRolesModal.defaultProps = {};

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogActions, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { OtherProps, translate } from 'utils';

export const CustomActionButtons = ({
  onCancel = () => {},
  onClose = () => {},
  disabled = false,
  cancelLabelKey = 'button.cancel',
  submitLabelKey = 'button.save',
  children,
  isSaving,
  ...props
}: {
  cancelLabelKey?: string,
  children?: any,
  disabled?: boolean,
  isSaving?: boolean,
  onCancel?: any,
  onClose?: any,
  submitLabelKey?: string
} & OtherProps) => {
  const handleClick = useCallback(() => {
    if (!isSaving) {
      onCancel();
      onClose();
    }
  }, [onCancel, onClose, isSaving]);

  const renderButtons = (() => (
    <>
      <Button
        disabled={isSaving}
        variant="text"
        onClick={handleClick}
      >
        {translate(cancelLabelKey)}
      </Button>

      <Button
        color="primary"
        disabled={disabled || isSaving}
        startIcon={isSaving && (<FontAwesomeIcon fixedWidth icon={faSpinner as any} spin />)}
        type="submit"
      >
        {translate(submitLabelKey)}
      </Button>
    </>
  ));

  return (
    <DialogActions {...props}>
      {children ? (
        <Grid alignItems="center" container>
          <Grid container item justifyContent="right" xs={6}>
            {children}
          </Grid>
          <Grid container item justifyContent="flex-end" xs={6}>
            {renderButtons()}
          </Grid>
        </Grid>
      ) : (
        renderButtons()
      )}
    </DialogActions>
  );
};

import { CompanyService } from 'services';
import { userStore } from 'stores';
import { APPLICATION_MANAGEMENT_ROLES, APPLICATION_ROLES, STRUCTURE_TYPE } from 'utils/constants';

const hasAccessRight = (requiredRoles) => {
  if (userStore.currentRoles) {
    return requiredRoles.some((role) => Object.values(userStore.currentRoles).includes(role));
  }
  return false;
};

const hasAdminAccessRights = () => {
  if (userStore.currentRoles) {
    return userStore.currentRoles.includes(APPLICATION_ROLES.SUPER_ADMIN)
      || userStore.currentRoles.includes(APPLICATION_ROLES.ADMIN);
  }
  return false;
};

const getInstitutionIdIfAlone = (currentLevel, structureLevelOptions) => {
  if (!hasAccessRight(APPLICATION_MANAGEMENT_ROLES)) {
    if (currentLevel.type === STRUCTURE_TYPE.INSTITUTION_MODEL) {
      return currentLevel.value;
    }
    const index = structureLevelOptions
      .indexOf(structureLevelOptions.find((structureLevelOption) => structureLevelOption.value === currentLevel.value));
    if (index === -1 || index === structureLevelOptions.length) {
      return null;
    }
    const institutions = [];
    // eslint-disable-next-line no-plusplus
    for (let i = index + 1; i < structureLevelOptions.length; i++) {
      if (structureLevelOptions[i].indentNumber <= structureLevelOptions[index].indentNumber) {
        break;
      }
      if (structureLevelOptions[i].type === STRUCTURE_TYPE.INSTITUTION_MODEL) {
        institutions.push(structureLevelOptions[i]);
      }
    }
    if (institutions.length === 1) {
      return institutions[0].value;
    }
  }
  return null;
};

const hasSingleAccessRight = (role) => {
  if (userStore.currentRoles) {
    return (userStore.currentRoles.length === 1 && userStore.currentRoles[0] === role);
  }
  return false;
};

const getDefaultCountry = (address) => {
  const { currentCountry } = userStore;
  return address?.country ?? currentCountry;
};

const hasCompanyAccessToDatabilan = () => {
  const {
    isOffline,
    currentCompany,
    treeRootCompany
  } = userStore;
  if (isOffline) {
    return Promise.resolve(false);
  }
  return CompanyService.getCompany(currentCompany?.value ?? treeRootCompany.value)
    .then((company) => company.databilanOperatorAccountsTotal > 0);
};

export const UserHelper = {
  hasAccessRight,
  hasSingleAccessRight,
  hasAdminAccessRights,
  getDefaultCountry,
  getInstitutionIdIfAlone,
  hasCompanyAccessToDatabilan
};

import { Grid } from '@mui/material';
import { TwoColumnGrid } from 'components/_commons/Column';
import { Spinner } from 'components/_commons/Skeletons';
import { TecneaTitle } from 'components/_commons/Title';
import { UnclassifiedDocumentType } from 'components/AttestationForm';
import { DocumentCard } from 'components/AttestationForm/_sections/Tracability/DocumentCard';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { DocumentsTable } from 'components/AttestationForm/shared/DocumentsTable';
import { useFetch } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { AttestationFormRequestService } from 'services';
import { translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';

export const Tracability = (({
  requestId,
  availableActions,
  updateAlerts,
  updateUnreadDocumentAlerts
}) => {
  const [documentCards, setDocumentCards] = useState([]);
  const [cardKeys, setCardKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshUnclassifiedDocumentList, setRefreshUnclassifiedDocumentList] = useState(0);
  const canValidate = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM);
  const {
    response: unclassifiedDocuments,
    isLoading: unclassifiedDocumentsLoading
  } = useFetch(
    () => AttestationFormRequestService.getUnclassifiedDocuments(
      requestId,
      UnclassifiedDocumentType.UnclassifiedTracability
    ),
    [],
    [refreshUnclassifiedDocumentList]
  );

  const reloadAlerts = useCallback(
    () => {
      updateAlerts(SectionEnum.Tracability);
      if (canValidate) {
        updateUnreadDocumentAlerts();
      }
    },
    [updateAlerts, canValidate, updateUnreadDocumentAlerts]
  );

  const refreshDocumentCard = useCallback(
    (type) => {
      AttestationFormRequestService.getTracabilityDocumentsForType(requestId, type)
        .then((resp) => {
          setDocumentCards((cards) => cards.map((card) => (card.listType.value === type ? resp : card)));
          reloadAlerts();
        });
    },
    [reloadAlerts, requestId]
  );

  const loadTracability = useCallback(() => {
    setIsLoading(true);
    AttestationFormRequestService.getTracabilityDocuments(requestId)
      .then((resp) => {
        const cards = [];
        const keys = [];
        resp.forEach((value) => {
          cards.push(value);
          keys.push(value.listType.value);
        });
        setDocumentCards(cards);
        setCardKeys(keys);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [requestId]);

  useEffect(() => {
    loadTracability();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    reloadAlerts();
    // eslint-disable-next-line
  }, [refreshUnclassifiedDocumentList]);

  return (
    <>
      <Grid container direction="column" spacing={3} width="100%">
        <Grid item xs={12}>
          <TecneaTitle label={translate('attestationFormRequest.tracability.institutionDetails')} />
        </Grid>
        {isLoading
          ? <Spinner />
          : (
            <Grid item width="100%" xs={12}>
              <TwoColumnGrid keys={cardKeys}>
                {documentCards.map((value) => (
                  <Grid container direction="row" key={value.listType.value}>
                    <DocumentCard
                      acceptedFormats=".pdf, .jpg, .jpeg, .png"
                      canEdit={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)}
                      canValidate={canValidate}
                      description={value.description}
                      documentList={value.documentSimpleViewList}
                      keyInput={value.listType.value}
                      label={value.listType.label}
                      refreshDocumentCard={refreshDocumentCard}
                      requestId={requestId}
                      template={value.template}
                      type={value.listType.value}
                      updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
                    />
                  </Grid>
                ))}
              </TwoColumnGrid>
            </Grid>
          )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DocumentsTable
            canEdit={canEdit}
            canValidate={canValidate}
            folders={unclassifiedDocuments}
            isLoading={unclassifiedDocumentsLoading}
            requestId={requestId}
            section={SectionEnum.Tracability}
            styles={{ marginTop: '3rem' }}
            onUpdate={() => setRefreshUnclassifiedDocumentList((value) => value + 1)}
            onUpdateCurrentSection={loadTracability}
          />
        </Grid>
      </Grid>
    </>
  );
});

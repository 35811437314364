import {
  faFileExport,
  faFileImport,
  faUnlock
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeft,
  faBuilding,
  faCalendarCheck,
  faCalendarClock,
  faCheck,
  faCheckCircle as faCheckCircleRegular,
  faCheckDouble,
  faChevronDown,
  faCircleInfo,
  faClipboardCheck as faClipboardCheckRegular,
  faCloudArrowDown,
  faDropletPercent,
  faEmptySet,
  faExclamationTriangle,
  faFileCheck,
  faGlobe,
  faHome,
  faHorizontalRule,
  faHourglassStart,
  faLightbulbOn,
  faPaperPlane,
  faPlus,
  faQuestion,
  faSitemap,
  faStoreAlt,
  faTimer,
  faToolbox,
  faTools,
  faTrashAlt,
  faUserCog,
  faUsers,
  faXmark,
  faXmarkCircle as faXmarkCircleRegular
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle as faCheckCircleSolid,
  faCircleSmall,
  faCommentsAlt,
  faEye,
  faFolderPlus,
  faMagnifyingGlass,
  faPen,
  faPenField,
  faSquare1,
  faSquare2,
  faSquare3,
  faToggleOff,
  faToggleOn,
  faXmarkCircle as faXmarkCircleSolid
} from '@fortawesome/pro-solid-svg-icons';
import { DATAFLUIDES_THEME } from 'styles/DATAFLUIDES_THEME';

const theme = DATAFLUIDES_THEME;

export const iconEnum = {
  horizontalRule: {
    icon: faHorizontalRule,
    defaultColor: theme.palette.grey.dark
  },
  building: {
    icon: faBuilding,
    defaultColor: theme.palette.primary.main
  },
  storeAlt: {
    icon: faStoreAlt,
    defaultColor: theme.palette.primary.main
  },
  sitemap: {
    icon: faSitemap,
    defaultColor: theme.palette.primary.main
  },
  xMark: {
    icon: faXmark,
    defaultColor: theme.palette.error.main
  },
  hourglassStart: {
    icon: faHourglassStart,
    defaultColor: theme.palette.grey.dark
  },
  penField: {
    icon: faPenField,
    defaultColor: theme.palette.grey.dark
  },
  check: {
    icon: faCheck,
    defaultColor: theme.palette.success.main
  },
  faCheckDouble: {
    icon: faCheckDouble,
    defaultColor: theme.palette.success.main
  },
  calendarCheck: {
    icon: faCalendarCheck,
    defaultColor: theme.palette.warning.main
  },
  calendarClock: {
    icon: faCalendarClock,
    defaultColor: theme.palette.warning.main
  },
  exclamationTriangle: {
    icon: faExclamationTriangle,
    defaultColor: theme.palette.warning.main
  },
  emptySet: {
    icon: faEmptySet,
    defaultColor: theme.palette.primary.main
  },
  eye: {
    icon: faEye,
    defaultColor: theme.palette.primary.main
  },
  comment: {
    icon: faCommentsAlt,
    defaultColor: theme.palette.primary.main
  },
  questionMark: {
    icon: faQuestion,
    defaultColor: theme.palette.primary.main
  },
  trashAlt: {
    icon: faTrashAlt,
    defaultColor: theme.palette.error.main
  },
  plane: {
    icon: faPaperPlane,
    defaultColor: theme.palette.primary.main
  },
  xMarkCircle: {
    icon: faXmarkCircleRegular,
    defaultColor: theme.palette.error.main,
    solidIcon: faXmarkCircleSolid
  },
  checkCircle: {
    icon: faCheckCircleRegular,
    defaultColor: theme.palette.success.main,
    solidIcon: faCheckCircleSolid
  },
  timer: {
    icon: faTimer,
    defaultColor: theme.palette.primary.main
  },
  folder: {
    icon: faFolderPlus,
    defaultColor: theme.palette.primary.main
  },
  download: {
    icon: faCloudArrowDown,
    defaultColor: theme.palette.primary.main
  },
  lightBulb: {
    icon: faLightbulbOn,
    defaultColor: theme.palette.grey.main
  },
  info: {
    icon: faCircleInfo,
    defaultColor: theme.palette.primary.main
  },
  listDot: {
    icon: faCircleSmall,
    defaultColor: theme.palette.common.black
  },
  square1: {
    icon: faSquare1,
    defaultColor: theme.palette.common.black
  },
  square2: {
    icon: faSquare2,
    defaultColor: theme.palette.common.black
  },
  square3: {
    icon: faSquare3,
    defaultColor: theme.palette.common.black
  },
  pen: {
    icon: faPen,
    defaultColor: theme.palette.primary.main
  },
  plus: {
    icon: faPlus,
    defaultColor: theme.palette.primary.main
  },
  toggleOff: {
    icon: faToggleOff,
    defaultColor: theme.palette.primary.main
  },
  toggleOn: {
    icon: faToggleOn,
    defaultColor: theme.palette.primary.main
  },
  back: {
    icon: faArrowLeft,
    defaultColor: theme.palette.primary.main
  },
  home: {
    icon: faHome,
    defaultColor: theme.palette.primary.main
  },
  audit: {
    icon: faClipboardCheckRegular,
    defaultColor: theme.palette.primary.main
  },
  fluidBalance: {
    icon: faDropletPercent,
    defaultColor: theme.palette.primary.main
  },
  evaluation: {
    icon: faFileCheck,
    defaultColor: theme.palette.primary.main
  },
  administration: {
    icon: faUserCog,
    defaultColor: theme.palette.primary.main
  },
  users: {
    icon: faUsers,
    defaultColor: theme.palette.primary.main
  },
  tools: {
    icon: faTools,
    defaultColor: theme.palette.primary.main
  },
  toolbox: {
    icon: faToolbox,
    defaultColor: theme.palette.primary.main
  },
  company: {
    icon: faStoreAlt,
    defaultColor: theme.palette.primary.main
  },
  globe: {
    icon: faGlobe,
    defaultColor: theme.palette.primary.main
  },
  search: {
    icon: faMagnifyingGlass,
    defaultColor: theme.palette.primary.main
  },
  import: {
    icon: faFileImport
  },
  export: {
    icon: faFileExport
  },
  unlock: {
    icon: faUnlock
  },
  chevronDown: {
    icon: faChevronDown
  }
};

export const ICONS = {
  EMPTY: iconEnum.emptySet,
  SEARCH: iconEnum.search,
  NONE: iconEnum.horizontalRule,
  WARNING: iconEnum.exclamationTriangle,
  PLUS: iconEnum.plus,
  IMPORT: iconEnum.import,
  EXPORT: iconEnum.export,
  CHEVRON_LIST: iconEnum.chevronDown,
  BOOLEAN: {
    TRUE: iconEnum.check,
    FALSE: iconEnum.xMark
  },
  STRUCTURE_LEVEL: {
    INSTITUTION_MODEL: iconEnum.building,
    COMPANY: iconEnum.storeAlt,
    INSTITUTION_GROUP: iconEnum.sitemap
  },
  ATTESTATION_REQUEST_STATUS: {
    INCOMPLETE: iconEnum.penField,
    IN_MODIFICATION: iconEnum.penField,
    SUBMITTED: iconEnum.hourglassStart,
    TO_INSTRUCT: iconEnum.hourglassStart,
    INSTRUCTING: iconEnum.hourglassStart,
    TO_SUPERVISE: iconEnum.hourglassStart,
    SUPERVISING: iconEnum.hourglassStart,
    ORDER_FORM_VALIDATED: iconEnum.check,
    VALIDATED: iconEnum.check,
    REFUSED: iconEnum.xMark,
    CANCELLED: iconEnum.xMark
  },
  ATTESTATION_REQUEST_EVALUATION_STATUS: {
    PENDING: iconEnum.hourglassStart,
    IN_PROGRESS: iconEnum.hourglassStart,
    IN_COMPLETION: iconEnum.penField,
    VALIDATED: iconEnum.check,
    REFUSED: iconEnum.xMark
  },
  ATTESTATION: {
    VALIDATED: iconEnum.check,
    TO_RENEW: iconEnum.calendarCheck,
    REMOVED: iconEnum.xMark,
    EXPIRED: iconEnum.xMark,
    SUSPENDED: iconEnum.xMark
  },
  AUDIT: {
    PENDING: iconEnum.horizontalRule,
    TO_PLAN: iconEnum.horizontalRule,
    READY: iconEnum.hourglassStart,
    ONGOING: iconEnum.hourglassStart,
    COMPLETION_REQUIRED: iconEnum.xMark,
    ONGOING_REVIEW: iconEnum.hourglassStart,
    SUBMITTED: iconEnum.hourglassStart,
    SUPERVISING: iconEnum.hourglassStart,
    FINISHED_COMPLIANT: iconEnum.check,
    FINISHED_NON_COMPLIANT: iconEnum.xMark,
    REFUSED: iconEnum.xMark
  },
  FLUID_BALANCE_ENTRY: {
    VALID: iconEnum.check,
    NONE: iconEnum.horizontalRule,
    INVALID: iconEnum.xMark
  },
  FLUID_BALANCE: {
    INCOMPLETE: iconEnum.calendarCheck,
    SUPPLEMENT_REQUEST: iconEnum.xMark,
    IN_PROGRESS: iconEnum.hourglassStart,
    SUBMITTED: iconEnum.hourglassStart,
    INSTRUCTING: iconEnum.hourglassStart,
    SENT_BACK_FOR_INSTRUCTION: iconEnum.hourglassStart,
    TO_SUPERVISE_REFUSED: iconEnum.hourglassStart,
    TO_SUPERVISE_VALIDATED: iconEnum.hourglassStart,
    SUPERVISING: iconEnum.hourglassStart,
    VALIDATED: iconEnum.check,
    REFUSED: iconEnum.xMark
  },
  FLUID_BALANCE_CAMPAIGN: {
    EDIT: iconEnum.pen,
    UNLOCK: iconEnum.unlock
  },
  TOGGLE: {
    ON: iconEnum.toggleOn,
    OFF: iconEnum.toggleOff
  }
};

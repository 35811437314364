import { faHeadset } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { AttestationFormRequestService } from 'services';

import { useModal } from 'hooks';
import { translate } from 'utils';
import { ASSISTANCE_EMAIL, ROUTES } from 'utils/constants';

const ContactGrid = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid',
  borderTop: '1px solid',
  borderColor: `${theme.palette.grey.main}`,
  paddingBottom: '15px',
  paddingTop: '15px',
  alignItems: 'center'
}));

export const Transmission = (({
  requestId, unresolvedAlerts, institutionId, shouldValidateProgram = true
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const { open } = useModal();
  const hasAlerts = Boolean(unresolvedAlerts?.length);

  const confirmTransmission = useCallback(() => open({
    type: 'CONFIRM',
    variant: hasAlerts ? 'WARNING' : 'PRIMARY',
    title: hasAlerts
      ? 'attestationFormRequest.transmission.confirmTitleWithAlerts'
      : 'attestationFormRequest.transmission.confirmTitle',
    content: translate(hasAlerts
      ? 'attestationFormRequest.transmission.confirmSubmitWithAlerts'
      : 'attestationFormRequest.transmission.confirmSubmit'),
    onConfirm: () => {
      AttestationFormRequestService.submitRequest(requestId)
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.transmission.submitted'), { variant: 'success' });
          navigate(generatePath(ROUTES.INSTITUTION.DETAIL, { institutionId }));
        });
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  }), [requestId, enqueueSnackbar, navigate, hasAlerts, institutionId, open]);

  const validateProgram = useCallback(() => open({
    type: 'VALIDATE_CERTIFICATION_PROGRAM',
    onSubmit: confirmTransmission
  }), [open, confirmTransmission]);

  const handleTransmission = useCallback(() => {
    shouldValidateProgram ? validateProgram() : confirmTransmission();
  }, [confirmTransmission, validateProgram, shouldValidateProgram]);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid alignItems="center" item justifyContent="center" md={6} xs={6}>
        <img
          alt="icon"
          src={`${process.env.PUBLIC_URL}/assets/images/icons/send-message.png`}
        />
      </Grid>
      <Grid item md={6} sm={10} xs={12}>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h1">
              {translate('attestationFormRequest.transmission.almostDone')}
            </Typography>
          </Grid>
          <Grid item>
            {hasAlerts
              ? (
                <>
                  <Typography>
                    {translate('attestationFormRequest.transmission.alerts.cannotSubmit')}
                    <span style={{ fontWeight: 'bold', color: theme.palette.warning.main }}>
                      {` ${translate(
                        'attestationFormRequest.transmission.alerts.quantity',
                        { number: unresolvedAlerts.length }
                      )}`}
                    </span>
                  </Typography>
                  <Typography marginTop={3}>
                    {translate('attestationFormRequest.transmission.alerts.fixSteps')}
                  </Typography>
                </>
              ) : (
                <Typography>
                  {translate('attestationFormRequest.transmission.readyToSubmit')}
                </Typography>
              )}
          </Grid>
          <Grid item>
            <Button
              color={hasAlerts ? 'warning' : 'primary'}
              style={{ width: '100%' }}
              variant="contained"
              onClick={handleTransmission}
            >
              {translate('attestationFormRequest.transmission.submit')}
            </Button>
          </Grid>
          <Grid item>
            <ContactGrid container>
              <Grid item xs={2}>
                <FontAwesomeIcon icon={faHeadset} size="3x" />
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  {translate('attestationFormRequest.transmission.help')}
                  <br />
                  <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                    {ASSISTANCE_EMAIL}
                  </a>
                </Typography>
              </Grid>
            </ContactGrid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Flag } from 'components/_commons/Flag';
import { SelectField } from 'components/_commons/Form/Inputs';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import React, { useCallback } from 'react';
import { OtherProps } from 'utils';

const CustomOption = ({ data, innerProps, selectProps }) => {
  const { isFlagElement, leftElement } = selectProps;
  const optionFlag = (
    <Flag
      alt={data.countryCode ?? data.value}
      countryCode={data.countryCode ?? data.value}
      isInsideSelect
      style={{ margin: '3px 10px' }}
    />
  );
  return (
    <ItemOption {...innerProps} isInsideSelect>
      {isFlagElement ? optionFlag : leftElement && leftElement(data)}
      <Typography>
        {data.label}
      </Typography>
    </ItemOption>
  );
};

const CustomValue = ({ data, innerProps, selectProps }) => {
  const { isFlagElement, leftElement, valueLabelVariant } = selectProps;
  const valueFlag = (
    <Flag
      alt={data.countryCode ?? data.value}
      countryCode={data.countryCode ?? data.value}
      isInsideSelect
      style={{ margin: '5px 0' }}
    />
  );
  return (
    <ItemOption {...innerProps} isInsideSelect style={{ gridArea: ' 1/1/2/3' }}>
      {isFlagElement ? valueFlag : leftElement && leftElement(data)}
      <Typography style={{ margin: '0.8rem', whiteSpace: 'nowrap' }} variant={valueLabelVariant}>
        {data.label}
      </Typography>
    </ItemOption>
  );
};

const SelectItemWithLeftElement = ({
  value, onChange, required = false, isLoading = false, options, label = 'common.selectOption', placeholder, isFlagElement = false,
  leftElement, valueLabelVariant, isDisabled = false, isClearable = false, ...props
}: {
  isClearable?: boolean,
  isDisabled?: boolean,
  isFlagElement?: boolean,
  isLoading?: boolean,
  label?: string,
  leftElement?: any,
  onChange: any,
  options: any,
  placeholder?: string,
  required?: boolean,
  value?: any,
  valueLabelVariant?: any
} & OtherProps) => {
  const handleChange = useCallback((language) => onChange(language), [onChange]);

  return (
    isLoading ? (
      <FontAwesomeIcon icon={faSpinner as any} spin />
    ) : (
      <SelectField
        components={{ Option: CustomOption, SingleValue: CustomValue }}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isFlagElement={isFlagElement}
        label={label}
        leftElement={leftElement}
        options={options}
        placeholder={placeholder}
        required={required}
        value={value}
        valueLabelVariant={valueLabelVariant}
        onChange={handleChange}
        {...props}
      />
    )
  );
};

export default SelectItemWithLeftElement;

const OperatorEnum = {
  AddOperator: 'add-operator',
  OperatorDocument: 'documents'
};

const isLast = (step) => (step === OperatorEnum.OperatorDocument);

export const OperatorModalStepsEnum = {
  ...OperatorEnum,
  isLast
};

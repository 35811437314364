import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseModalButton } from 'components/_commons/Modals/CloseModalButton';
import { ModalProps } from 'hooks/ModalProvider';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';

export const CreateInstitutionGroupModal = ({
  onSubmit, onClose, closeOnSubmit, defaultValues = {}, defaultActivity, parentInstitutionGroupId, parentCompanyId, notEditable
}: ModalProps<any>) => {
  const [isCreatingInstitutionGroup, setIsCreatingInstitutionGroup] = useState(false);
  const [name, setName] = useState(defaultValues.name);
  const isEdit = defaultValues.name;
  const theme = useTheme();

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();
    const newInstitutionGroup = {
      ...defaultValues,
      name,
      parentInstitutionGroupId,
      parentCompanyId,
      activity: defaultActivity?.value
    };
    // In case of edition do not pass parent data
    if (isEdit) {
      newInstitutionGroup.parentInstitutionGroupId = null;
      newInstitutionGroup.parentCompanyId = null;
    }
    onSubmit(newInstitutionGroup, setIsCreatingInstitutionGroup);
    if (closeOnSubmit) {
      onClose();
    }
  }, [defaultValues, name, parentInstitutionGroupId, parentCompanyId, defaultActivity?.value, isEdit, onSubmit, closeOnSubmit, onClose]);

  return (
    <form autoComplete="off" name="institutionForm" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding as any} />
        <Typography component="span">
          {(notEditable && translate('modalInstitutionGroup.viewInstitutionGroup')) || (isEdit
            ? translate('modalInstitutionGroup.editInstitutionGroup')
            : translate('modalInstitutionGroup.newInstitutionGroup'))}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ minWidth: '500px', paddingTop: 15 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              disabled={notEditable}
              label={translate('modalInstitutionGroup.name')}
              name="institutionGroupName"
              required
              value={name}
              variant="standard"
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled
              label={translate('common.activity')}
              name="institutionActivity"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.common.white
                }
              }}
              value={defaultActivity?.label || ' '}
              variant="standard"
            />
          </Grid>
        </Grid>
      </DialogContent>

      {notEditable
        ? (
          <CloseModalButton onClose={onClose} />
        )
        : (
          <CustomActionButtons
            disabled={notEditable}
            isSaving={isCreatingInstitutionGroup}
            onClose={onClose}
          />
        )}
    </form>
  );
};

import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';

import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useFetch } from 'hooks';
import { AttestationFormRequestService } from 'services';
import { translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';
import { iconEnum } from 'utils/icons';

import { WithLeftIconText } from 'components/_commons/Text';
import { TecneaTitle } from 'components/_commons/Title';
import { ConclusionInstructor } from 'components/AttestationForm/_sections/Conclusion/ConclusionInstructor';
import { ConclusionSupervisor } from 'components/AttestationForm/_sections/Conclusion/ConclusionSupervisor';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { sortBy } from 'lodash/collection';
import { AttestationEvaluationService } from 'services/AttestationEvaluationService';

const DisabledBlock = styled('div')(({ ...props }) => ({
  opacity: props.disabled ? 0.25 : 1
}));

export const Conclusion = ({
  requestId, resolvedAlerts, unresolvedAlerts, availableActions, status
}) => {
  const isSupervision = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_SUPERVISE);
  const isInstruction = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_INSTRUCT);

  const theme = useTheme();
  const [actionsDisabled, setActionsDisabled] = useState(true);
  const [alertsToCheckGroupedBySection, setAlertsToCheckGroupedBySection] = useState({});

  const { response: requestDetails } = (useFetch(() => AttestationFormRequestService.getDetails(requestId), []));
  const { response: unreadDocumentAlerts, isLoading: areUnreadDocumentAlertsLoading } = (
    useFetch(() => AttestationEvaluationService.getUnreadDocumentAlerts(requestId), []));

  useEffect(() => {
    if (!areUnreadDocumentAlertsLoading) {
      setActionsDisabled((resolvedAlerts.length || unreadDocumentAlerts.length) && !isSupervision);
      // Sort alerts by section in order for them to be still sorted when grouped
      const alertsToCheck = sortBy(
        [...resolvedAlerts, ...unreadDocumentAlerts],
        (item) => Object.values(SectionEnum).indexOf(item.section.key)
      );
      setAlertsToCheckGroupedBySection(groupBy(alertsToCheck, 'section.label'));
    }
    // eslint-disable-next-line
  }, [resolvedAlerts, unreadDocumentAlerts, isSupervision]);

  const getInfoLabel = useCallback(() => {
    if (actionsDisabled) {
      return translate('attestationFormRequest.conclusion.description');
    }
    if (isSupervision) {
      return translate('attestationFormRequest.conclusion.supervisionDescription');
    }
    if (isInstruction) {
      return translate('attestationFormRequest.conclusion.instructionDescription');
    }
    return '';
  }, [actionsDisabled, isSupervision, isInstruction]);

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item>
        <WithLeftIconText
          color={theme.palette.primary.main}
          icon={iconEnum.info.icon}
          label={getInfoLabel()}
          labelColor={theme.palette.primary.main}
          spacing={1}
        />
      </Grid>
      <>
        {Object.keys(alertsToCheckGroupedBySection).map((label) => (
          <Grid container direction="column" key={shortid.generate()} spacing={2}>
            <Grid item>
              <TecneaTitle label={label} />
            </Grid>
            <Grid item>
              <Grid container direction="row" key={shortid.generate()} spacing={2}>
                {alertsToCheckGroupedBySection[label]?.documentList?.filter(({ validation }) => validation).length && (
                  <Grid item>
                    <WithLeftIconText
                      color={theme.palette.warning.main}
                      fontWeight="bold"
                      icon={iconEnum.eye.icon}
                      /* eslint-disable-next-line max-len */
                      label={translate('attestationFormRequest.conclusion.unread', { count: alertsToCheckGroupedBySection[label]?.documentList.filter(({ validation }) => validation).length })}
                      labelColor={theme.palette.warning.main}
                      size="xs"
                      spacing={0.5}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {alertsToCheckGroupedBySection[label].map((alert) => (
              <WithLeftIconText
                color={theme.palette.grey.dark}
                defaultIconXs={0.5}
                icon={faHorizontalRule}
                key={label}
                label={alert.description}
                labelColor={theme.palette.error.main}
                size="xs"
              />
            ))}
          </Grid>
        ))}
      </>
      <DisabledBlock disabled={actionsDisabled}>
        <Grid container direction="column" rowGap={4}>
          {isInstruction && (
            <Grid item>
              <TecneaTitle label={translate('attestationFormRequest.conclusion.instruction.title')} />
              <br />
              <ConclusionInstructor
                actionsDisabled={actionsDisabled}
                requestId={requestId}
                transmissionDate={requestDetails.transmissionDate}
                unresolvedAlerts={unresolvedAlerts}
              />
            </Grid>
          )}
          {isSupervision && (
            <Grid item>
              <TecneaTitle label={translate('attestationFormRequest.conclusion.supervision.title')} />
              <br />
              <ConclusionSupervisor
                actionsDisabled={actionsDisabled}
                availableActions={availableActions}
                instructionStatus={requestDetails.instructionStatus}
                requestId={requestId}
                status={status}
                transmissionDate={requestDetails.transmissionDate}
                unresolvedAlerts={unresolvedAlerts}
              />
            </Grid>
          )}
        </Grid>
      </DisabledBlock>
    </Grid>
  );
};

Conclusion.propTypes = {
  requestId: PropTypes.string.isRequired
};

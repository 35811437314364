import { Divider, Grid } from '@mui/material';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';

import { DocumentHelper, translate } from 'utils';

import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { InputFile } from 'components/_commons/Form/Inputs';
import { AddDocumentItem } from 'components/_commons/Modals/common/AddDocumentItem';

export const DocumentBlock = ({
  labelButton, variant, formContext, showDivider, attestationCategories,
  diplomaTypes, fieldName, type, documentDefaultValues = {}, withTooltip = false
}) => {
  const { open } = useModal();
  const theme = useTheme();
  const { fields, remove, append } = useFieldArray({
    control: formContext.control,
    name: fieldName
  });

  const removeDocument = useCallback((index) => open({
    type: 'WARNING',
    onConfirm: () => {
      remove(index);
    }
  }), [open, remove]);

  const displayFields = useCallback(() => fields.map((field, index) => {
    const document = {
      ...field,
      id: formContext.watch(`${fieldName}[${index}].id`)
    };
    return (
      <AddDocumentItem
        attestationCategories={attestationCategories}
        diplomaTypes={diplomaTypes}
        docIndex={index}
        document={document}
        documentType={documentDefaultValues?.type}
        fieldName={fieldName}
        formContext={formContext}
        key={field.id}
        removeDocument={() => removeDocument(index)}
      />
    );
  }), [attestationCategories, diplomaTypes, documentDefaultValues?.type, fieldName, fields, formContext, removeDocument]);

  return (
    <div className="m2">
      <Grid
        alignItems="center"
        container
        justifyContent="center"
        spacing={2}
        wrap="wrap"
      >
        <Grid alignItems="center" container item md={6} spacing={1} xs={8}>
          <Grid item xs={11}>
            <InputFile
              endIcon={faFileAlt}
              fullWidth
              handleAddDocument={(file) => append({
                ...documentDefaultValues,
                ...file,
                documentDate: null,
                base64Content: DocumentHelper.getDocumentWithoutBase64(file)
              })}
              id={`input-file-${fieldName}`}
              label={null}
              text={labelButton}
              type={type}
              variant={variant}
              whiteSpace="break-spaces"
            />
          </Grid>
          <Grid item xs={1}>
            {withTooltip && documentDefaultValues?.type?.value
              && (
                <DatafluidesTooltip
                  title={documentDefaultValues.type.tooltip}
                >
                  <FontAwesomeIcon
                    color={theme.palette.primary.main}
                    icon={faQuestionCircle}
                    size="2x"
                  />
                </DatafluidesTooltip>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid alignItems="center" container justifyContent="center">
        <Grid item md={10} xs={12}>
          <div className="m3 spacing-bottom">
            {displayFields()}
          </div>
        </Grid>
      </Grid>
      {showDivider && <Divider>{translate('attestationFormRequest.operators.divider')}</Divider>}
    </div>
  );
};

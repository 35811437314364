import React from 'react';
import {
  enumOptionsStore,
  i18nStore,
  userStore
} from 'stores';

/**
 * Context used to store the different Mobx stores.
 * <p>
 * This context should be accessed through the {@link useStores} hook.
 * <p>
 * Using React context to insert Mobx stores in the components looks to be the best way to do at ths time.
 * Indeed @inject and @provide are deprecated and direct import does not enable easy component testing.
 * Thus, all Mobx Stores should be in this context.
 */
export const StoresContext = React.createContext({
  enumOptionsStore,
  i18nStore,
  userStore
});

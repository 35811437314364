import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button, Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { translate } from 'utils';

const StyledContainer = styled('div')(({ theme }: any) => ({
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.primary.main,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.main
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main
  }
}));

export const LinearProgressIndicator = ({ step, total }) => {
  const StyledE = styled('span')(() => ({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px'
  }));

  return <StyledE>{`${translate('attestationFormRequest.verificationModal.proof')} ${step}/${total}`}</StyledE>;
};

export const DocumentActionButtons = ({
  goBack, canEdit = false, documentsCount, documentNumber, isLoading = false, isSaving = false
}: {
  canEdit?: boolean,
  documentNumber: number,
  documentsCount: number,
  goBack: any,
  isLoading?: boolean,
  isSaving?: boolean
}) => (
  <StyledContainer>
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item md={3}>
        {documentNumber > 1 && (
          <Button
            className="ml1"
            color="primary"
            variant="contained"
            onClick={goBack}
          >
            {translate('button.previous')}
          </Button>
        )}
      </Grid>
      <Grid item md={6}>
        {documentsCount > 1 && (
          <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
            <Grid item lg={4} md={6} xs={12}>
              <LinearProgressIndicator step={documentNumber} total={documentsCount} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <BorderLinearProgress value={Math.round((documentNumber / documentsCount) * 100)} variant="determinate" />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={3}>
        <div className="flex-end">
          {documentsCount !== documentNumber
            ? (
              <Button
                color="primary"
                disabled={isSaving}
                type="submit"
                variant="contained"
              >
                {translate('button.next')}
              </Button>
            )
            : (
              <Button
                color="primary"
                disabled={!canEdit || isLoading || isSaving}
                startIcon={(isLoading || isSaving) && <FontAwesomeIcon fixedWidth icon={faSpinner as any} spin />}
                type="submit"
                variant="contained"
              >
                {translate('button.save')}
              </Button>
            )}
        </div>
      </Grid>
    </Grid>
  </StyledContainer>
);

import { Grid } from '@mui/material';
import { TecneaTitle } from 'components/_commons/Title';
import React from 'react';

export const ModalFormField = ({ title, children }) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <TecneaTitle label={title} />
    </Grid>
    <Grid item>
      {children}
    </Grid>
  </Grid>
);

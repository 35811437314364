import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const StyledItemOption = styled('section')(({ theme, ...props }) => {
  const styles = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',

    '& > svg': {
      marginRight: '1rem'
    },

    article: {
      flex: 1,
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      marginLeft: '1rem',

      svg: {
        marginRight: '1rem'
      }
    },

    p: {
      margin: '0.5rem 0'
    },

    img: {
      width: '50px',
      maxWidth: '50px',
      marginRight: '0.5rem'
    }
  };

  if (props.isInsideSelect) {
    styles.padding = props.noPadding ? 0 : '0.5rem 1rem';
    styles.cursor = 'pointer';
    styles['&:hover'] = { backgroundColor: theme.palette.grey.lighter };
  }

  return styles;
});

const ItemOption = ({
  children, isInsideSelect, noPadding, ...rest
}) => (
  <StyledItemOption {...rest} isInsideSelect={isInsideSelect} noPadding={noPadding}>
    {children}
  </StyledItemOption>
);

ItemOption.propTypes = {
  isInsideSelect: PropTypes.bool,
  noPadding: PropTypes.bool
};

ItemOption.defaultProps = {
  isInsideSelect: false,
  noPadding: false
};

export default ItemOption;

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// Docs : https://www.npmjs.com/package/react-pdf
import { Button } from 'components/_commons/Button';
import { DocumentDisplay } from 'components/_commons/Document/DocumentDisplay';
import { DocumentService } from 'services';
import { DocumentHelper, translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

export const DisplayDocumentModal = ({
  onClose, document, loadContent
}) => {
  const [base64Content, setBase64Content] = useState(document.base64Content);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadContent) {
      setIsLoading(true);
      DocumentService.getFileTransferModel(document.documentId)
        .then((fileTransfer) => setBase64Content(DocumentHelper.getDocumentWithBase64Typing({
          base64Content: fileTransfer.base64Content,
          name: document.name
        })))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <ModalHeader iconSize="48px" onClose={onClose}>
        <Grid alignItems="center" columnSpacing={2} container justifyContent="space-between">
          <Grid item>
            {document.name}
          </Grid>
          <Grid item style={{ paddingRight: 50 }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => DocumentHelper.downloadDocumentFromBase64(base64Content, document.name)}
            >
              {translate('button.download')}
            </Button>
            {/* <IconButton
              color="secondary"
              size="small"
              onClick={() => DocumentHelper.downloadDocumentFromBase64(base64Content, document.name)}
            >
              <FontAwesomeIcon
                icon={faDownload}
                style={isTablet() ? { marginRight: 0, fontSize: '48px' } : { marginRight: 0 }}
              />
            </IconButton> */}
          </Grid>
        </Grid>
      </ModalHeader>
      <DocumentDisplay
        base64Content={DocumentHelper.getDocumentWithBase64Typing({ name: document.name, base64Content })}
        documentName={document.name}
        downloadLabel={translate('common.documentTooBig')}
        height="100%"
        isLoadingContent={isLoading}
        width="100%"
      />
    </div>
  );
};

DisplayDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape({
    name: PropTypes.string,
    base64Content: PropTypes.string
  })
};

DisplayDocumentModal.defaultProps = {
  document: {}
};

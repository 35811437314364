import { styled } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import React from 'react';
import { isTablet } from 'utils';

const TextContainer = styled('div')(() => ({
  display: 'inline-block',
  width: '100%'
}));

const Label = styled('span')(() => ({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical'
}));

export const TruncatedText = ({ text }) => (
  isTablet() ? (
    <TextContainer>
      <DatafluidesTooltip title={text}>
        <Label>{text}</Label>
      </DatafluidesTooltip>
    </TextContainer>
  ) : (
    <div>{ text }</div>
  )
);

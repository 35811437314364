import * as React from 'react';
import { CheckboxButtonGroup, CheckboxElement } from 'react-hook-form-mui';

import { FormControl, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { translate } from 'utils';

export function Checkbox({ label, name }) {
  return (
    <FormControl component="fieldset">
      <CheckboxElement label={label} name={name} />
    </FormControl>
  );
}
export function CheckboxGroup({
  name, row, options, keyValue = 'value', onChange, required = false, disabled = false
}: {
  disabled?: boolean,
  keyValue?: string,
  name: string,
  onChange?: any,
  options: any,
  required?: boolean,
  row?: boolean
}) {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position">
        <CheckboxButtonGroup
          disabled={disabled}
          name={name}
          options={options}
          row={row}
          rules={{
            required: (required ? translate('common.requiredField') : false)
          }}
          valueKey={keyValue}
          onChange={onChange}
        />
      </FormGroup>
    </FormControl>
  );
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      checked: PropTypes.bool,
      label: PropTypes.string
    })
  )
};

CheckboxGroup.defaultProps = {
  options: []
};

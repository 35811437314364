import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

const update = (equipment) => RequestHelper.PUT(API_ROUTES.TOOLS.ATTESTATION_FORM_TOOL(equipment.id), equipment);
const remove = (equipmentId) => RequestHelper.DELETE(API_ROUTES.TOOLS.ATTESTATION_FORM_TOOL(equipmentId));
const getDetentionDocumentTypes = (isNew, toolType) => RequestHelper.GET(
  API_ROUTES.TOOLS.DETENTION_DOCUMENT_TYPES(isNew, toolType)
);
const getVerificationDocumentTypes = (isNew) => RequestHelper.GET(
  API_ROUTES.TOOLS.VERIFICATION_DOCUMENT_TYPES(isNew)
);

const linkDocument = (toolId, body) => RequestHelper.PUT(API_ROUTES.TOOLS.LINK_DOCUMENT(toolId), body);
const getSpecificDocumentTypes = (type) => {
  if (!type) {
    return Promise.resolve([]);
  }
  return RequestHelper.GET(API_ROUTES.TOOLS.SPECIFIC_DOCUMENT_TYPES(type));
};

const getAvailableDocumentTypes = (type) => RequestHelper.GET(API_ROUTES.TOOLS.AVAILABLE_DOCUMENT_TYPES(type));
const getAllAvailableDocumentTypes = () => RequestHelper.GET(API_ROUTES.TOOLS.ALL_AVAILABLE_DOCUMENT_TYPES());

const getAttestationFormTools = (requestId) => RequestHelper.GET(API_ROUTES.TOOLS.GET_ATTESTATION_FORM_TOOLS(requestId));

const assignDocumentToTools = (body) => RequestHelper.PUT(API_ROUTES.TOOLS.ASSIGN_DOCUMENT_TO_TOOLS(), body);

const importAttestationFormTools = (requestId, body) => (
  RequestHelper.POST(API_ROUTES.TOOLS.IMPORT_ATTESTATION_FORM_TOOLS(requestId), body)
);

const exportAttestationFormTools = (requestId) => (
  RequestHelper.POST(API_ROUTES.TOOLS.EXPORT_ATTESTATION_FORM_TOOLS(requestId))
);

const toggleActivation = (toolId, toggle) => RequestHelper.PUT(API_ROUTES.TOOLS.TOGGLE_ACTIVATION(toolId, toggle));

export const AttestationFormToolService = {
  update,
  remove,
  getDetentionDocumentTypes,
  getVerificationDocumentTypes,
  linkDocument,
  toggleActivation,
  getSpecificDocumentTypes,
  getAttestationFormTools,
  getAvailableDocumentTypes,
  getAllAvailableDocumentTypes,
  assignDocumentToTools,
  importAttestationFormTools,
  exportAttestationFormTools
};

import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET
const getFluidBalanceOptions = (institutionId) => RequestHelper.GET(API_ROUTES.FLUID_BALANCES.OPTIONS(institutionId));
const getFluidBalanceDetails = (balanceId) => RequestHelper.GET(API_ROUTES.FLUID_BALANCES.FLUID_BALANCE(balanceId));
const getInstitutionIdsWithEditableFluidBalancesForUserConnected = (year) => (
  RequestHelper.GET(API_ROUTES.FLUID_BALANCES.INSTITUTION_ID_BY_EDITABLE_BALANCE(year))
);
const getEvaluatorOptions = (fluidBalanceId, role) => RequestHelper.GET(
  API_ROUTES.FLUID_BALANCES.EVALUATOR_OPTIONS(fluidBalanceId, role)
);
const getInstructors = () => RequestHelper.GET(API_ROUTES.FLUID_BALANCES.INSTRUCTORS());
const getSupervisors = () => RequestHelper.GET(API_ROUTES.FLUID_BALANCES.SUPERVISORS());

// POST
const updateFluidBalanceEntry = (balanceId, entry) => RequestHelper.POST(
  API_ROUTES.FLUID_BALANCES.ENTRY(balanceId),
  entry
);
const assignEvaluator = (balanceId, role, userId) => RequestHelper.POST(
  API_ROUTES.FLUID_BALANCES.ASSIGN_EVALUATOR(balanceId, role),
  userId
);
const syderepExport = (filter) => RequestHelper.POST(API_ROUTES.FLUID_BALANCES.SYDEREP(), filter).then((response) => {
  DocumentHelper.handleExcelFileDownload(response);
});

// PUT
const submit = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.SUBMIT(balanceId)
);
const startInstruction = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.START_INSTRUCTION(balanceId)
);
const validateInstruction = (balanceId, body) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.VALIDATE_INSTRUCTION(balanceId),
  body
);

const reject = (balanceId, body) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.REJECT(balanceId),
  body
);

const startSupervision = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.START_SUPERVISION(balanceId)
);

const validateSupervision = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.VALIDATE_SUPERVISION(balanceId)
);

const dismissSupervision = (balanceId, body) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.DISMISS_SUPERVISION(balanceId),
  body
);

const sendBackToOperator = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.SEND_BACK_TO_OPERATOR(balanceId)
);

const getBackToEdition = (balanceId) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.GET_BACK_TO_EDITION(balanceId)
);

const synchronize = (balanceId, databilanFluidBalanceView) => RequestHelper.PUT(
  API_ROUTES.FLUID_BALANCES.SYNCHRONIZE(balanceId),
  databilanFluidBalanceView
);

const getFluidBalanceHandedOverJustification = (balanceId) => (
  RequestHelper.GET(`${API_ROUTES.FLUID_BALANCE_DOCUMENTS.DOCUMENTS(balanceId)}/justification-documents`)
);

const deleteFluidBalanceHandedOverJustification = (attestationFormDocumentId) => (
  RequestHelper.DELETE(API_ROUTES.FLUID_BALANCE_DOCUMENTS.DOCUMENT(attestationFormDocumentId))
);

const unlockAllFluidBalances = () => RequestHelper.PUT(`${API_ROUTES.FLUID_BALANCES.UNLOCK_ALL()}`);
export const FluidBalanceService = {
  getFluidBalanceOptions,
  getFluidBalanceDetails,
  getInstitutionIdsWithEditableFluidBalancesForUserConnected,
  getFluidBalanceHandedOverJustification,
  updateFluidBalanceEntry,
  getEvaluatorOptions,
  assignEvaluator,
  getInstructors,
  getSupervisors,
  submit,
  startInstruction,
  validateInstruction,
  reject,
  startSupervision,
  validateSupervision,
  dismissSupervision,
  syderepExport,
  sendBackToOperator,
  getBackToEdition,
  synchronize,
  deleteFluidBalanceHandedOverJustification,
  unlockAllFluidBalances
};

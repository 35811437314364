import { DialogContent, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { DocumentHelper, translate } from '../../../utils';
import { DocumentList } from '../Document/DocumentList';
import { FormInputField } from '../Form/Inputs/InputField/FormInputField';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';

export const RejectFluidBalanceEntryModal = ({ onClose, onSubmit }) => {
  const formContext = useForm({
    defaultValues: {
      publicComment: '',
      privateComment: ''
    }
  });
  const { watch } = formContext;

  const publicComment = watch('publicComment');
  const [publicCommentDocuments, setPublicCommentDocuments] = useState([]);

  const privateComment = watch('privateComment');
  const [privateCommentDocuments, setPrivateCommentDocuments] = useState([]);

  const documentFromFile = (file) => ({
    addingDate: Date.now(),
    document: {
      name: file.name,
      base64Content: DocumentHelper.getDocumentWithoutBase64(file)
    }
  });

  const addDocument = useCallback((newFile, isPublic) => {
    if (newFile) {
      const newDoc = documentFromFile(newFile);
      if (isPublic) {
        setPublicCommentDocuments([...publicCommentDocuments, newDoc]);
      } else {
        setPrivateCommentDocuments([...privateCommentDocuments, newDoc]);
      }
    }
  }, [publicCommentDocuments, setPublicCommentDocuments, setPrivateCommentDocuments, privateCommentDocuments]);

  const addPublicDocument = useCallback((newFile) => {
    addDocument(newFile, true);
  }, [addDocument]);

  const addPrivateDocument = useCallback((newFile) => {
    addDocument(newFile, false);
  }, [addDocument]);

  const areDifferentDocuments = (doc, oldDoc) => doc?.addingDate !== oldDoc?.addingDate
    || doc?.document?.name !== oldDoc?.document?.name;

  const deleteDocument = useCallback((oldDocument, isPublic) => {
    if (oldDocument) {
      if (isPublic) {
        setPublicCommentDocuments(publicCommentDocuments.filter((doc) => areDifferentDocuments(doc, oldDocument)));
      } else {
        setPrivateCommentDocuments(privateCommentDocuments.filter((doc) => areDifferentDocuments(doc, oldDocument)));
      }
    }
  }, [publicCommentDocuments, setPublicCommentDocuments, setPrivateCommentDocuments, privateCommentDocuments]);

  const deletePublicDocument = useCallback((oldDocument) => {
    deleteDocument(oldDocument, true);
  }, [deleteDocument]);

  const deletePrivateDocument = useCallback((oldDocument) => {
    deleteDocument(oldDocument, false);
  }, [deleteDocument]);

  const submit = useCallback(() => {
    const fluidBalanceEntryCommentList = [];
    if (privateComment) {
      fluidBalanceEntryCommentList.push({
        private: true,
        comment: privateComment,
        documents: privateCommentDocuments.map((doc) => ({
          ...doc.document
        }))
      });
    }
    if (publicComment) {
      fluidBalanceEntryCommentList.push({
        private: false,
        comment: publicComment,
        documents: publicCommentDocuments.map((doc) => ({
          ...doc.document
        }))
      });
    }
    onSubmit(fluidBalanceEntryCommentList);
  }, [privateComment, publicComment, onSubmit, privateCommentDocuments, publicCommentDocuments]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'rejectFluidBalanceEntry' }}
      onSuccess={submit}
    >
      <ModalHeader onClose={onClose}>
        {translate('fluidBalanceEntry.rejectModal.title')}
      </ModalHeader>
      <DialogContent style={{
        width: '50vw', marginTop: '30px', minWidth: '500px', maxWidth: '1000px'
      }}
      >
        <Grid alignItems="left" container direction="column" spacing={2}>
          <Grid item>
            <Typography>
              {translate('fluidBalanceEntry.rejectModal.content')}
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography>
              {translate('fluidBalanceEntry.rejectModal.publicComment')}
            </Typography>
          </Grid>
          <Grid item>
            <FormInputField
              fullWidth
              label={translate('fluidBalanceEntry.rejectModal.publicCommentDescription')}
              name="publicComment"
            />
          </Grid>
          <Grid item marginTop="10px">
            <DocumentList
              canEdit
              documents={publicCommentDocuments}
              importLabel="button.import"
              marginTop="10px"
              spacing={2.5}
              titleStyles={{
                textAlign: 'center',
                variant: 'h6'
              }}
              onDelete={deletePublicDocument}
              onImport={addPublicDocument}
            />
          </Grid>
          <br />
          <Grid item>
            <Typography>
              {translate('fluidBalanceEntry.rejectModal.privateComment')}
            </Typography>
          </Grid>
          <Grid item>
            <FormInputField
              fullWidth
              label={translate('fluidBalanceEntry.rejectModal.privateCommentDescription')}
              name="privateComment"
            />
          </Grid>
          <Grid item marginTop="10px">
            <DocumentList
              canEdit
              documents={privateCommentDocuments}
              importLabel="button.import"
              marginTop="10px"
              spacing={2.5}
              titleStyles={{
                textAlign: 'center',
                variant: 'h6'
              }}
              onDelete={deletePrivateDocument}
              onImport={addPrivateDocument}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <CustomActionButtons
        onClose={onClose}
      />
    </FormContainer>
  );
};

import { justifications } from 'components/AttestationForm';

function mapDocumentTypeByGroup(documents) {
  const initialValue = { attestationDocuments: [], diplomaDocuments: [] };

  return documents.reduce((accumulator, currentValue) => {
    if (currentValue.value === justifications.APTITUDE_ATTESTATION) {
      accumulator.attestationDocuments.push(currentValue);
    } else {
      accumulator.diplomaDocuments.push(currentValue);
    }

    return accumulator;
  }, initialValue);
}

export const OperatorModel = {
  mapDocumentTypeByGroup
};

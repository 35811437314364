import { useEffect, useState } from 'react';
import { translate } from 'utils';

const isFieldRequired = ({ fieldsToCheck = [] }) => fieldsToCheck.some((field) => field);

const preventNumberInput = (e) => {
  const charStr = String.fromCharCode(e.which);
  if (/\d/.test(charStr)) {
    return e.preventDefault();
  }

  return e;
};

const formatNumberInput = (e, currentValue, allowDecimal = false) => {
  // Limit possible inputs to numbers or separators
  if ((e.which < 48 || e.which > 57) && e.which !== 8) {
    if (!allowDecimal || (e.which !== 44 && e.which !== 46)) {
      return e.preventDefault();
    }
    // Only one separator per number
    if (/[,.]/g.test(currentValue)) {
      return e.preventDefault();
    }
  }
  return e;
};

const preventLetterInput = (e) => {
  // 48 and 57 are the delimiters for the numbers code
  // 8 is the backspace code
  if ((e.which < 48 || e.which > 57) && e.which !== 8) {
    return e.preventDefault();
  }

  return e;
};

const checkMaxValue = (e, value, max) => {
  if (Number(value) > max) {
    return e.preventDefault();
  }

  return e;
};

const getInputPropsOnlyLetters = () => ({
  pattern: '[A-zÀ-ÿ ]+',
  title: translate('warnings.inputOnlyLetters')
});

const getInputPropsOnlyNumbers = () => ({
  pattern: '[0-9]+',
  title: translate('warnings.inputOnlyNumbers')
});

const normalizeString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const roundNumber = (number) => Math.round((Number(number) + Number.EPSILON) * 100) / 100;

const truncateNumber = (number) => Math.trunc((Number(number) + Number.EPSILON) * 100) / 100;

const formatEmail = (email) => email.toLowerCase();

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const yesNoOption = () => [
  { id: true, label: translate('common.yes') },
  { id: false, label: translate('common.no') }
];

const translateYesOrNo = (boolean) => {
  if (boolean == null) {
    return '-';
  }
  return translate(boolean ? 'common.yes' : 'common.no');
};

export const FormHelper = {
  isFieldRequired,
  preventNumberInput,
  formatNumberInput,
  preventLetterInput,
  checkMaxValue,
  getInputPropsOnlyLetters,
  getInputPropsOnlyNumbers,
  normalizeString,
  formatEmail,
  roundNumber,
  truncateNumber,
  useDebounce,
  yesNoOption,
  translateYesOrNo
};

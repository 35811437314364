import { BannerItemOption } from 'components/_commons/Modals/common/BannerItems';
import debounce from 'debounce-promise';
import { compact } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';

import { AttestationFormRequestService } from 'services';
import { translate } from 'utils';

import { SelectEntity } from 'components/_commons/Modals/common/SelectEntity';

export const SelectEmployee = ({
  employeeValue, isEdition = false, children, isNew = false, name, newPersonTitle, buttonTitle,
  personValue, requestId, selectTitle, onNew, onSelected, excludeOperators = false, excludeContacts = false
}: {
  buttonTitle: string,
  children: any,
  employeeValue: any,
  excludeContacts?: boolean,
  excludeOperators?: boolean,
  isEdition?: boolean,
  isNew?: boolean,
  name: string,
  newPersonTitle: string,
  onNew: any,
  onSelected: any,
  personValue: any,
  requestId: any,
  selectTitle: any
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [employeesLoading, setEmployeesLoading] = useState(true);
  const getEmailArray = (c) => c?.contactEmails?.split(';') ?? c?.technicalEmail?.split(';') ?? [];

  const getEmployeesPromise = useCallback((inputValue) => new Promise((resolve, reject) => {
    AttestationFormRequestService
      .getEmployees(requestId, inputValue, excludeOperators, excludeContacts)
      .then((response) => resolve(response.content))
      .catch((error) => reject((error && error.message) || error));
  }), [excludeContacts, excludeOperators, requestId]);

  const debouncedLoadEmployees = debounce(getEmployeesPromise, 500);

  const fetchEmployees = useCallback((freeSearch) => {
    setEmployeesLoading(true);
    debouncedLoadEmployees(freeSearch)
      .then((employees) => setEmployeeOptions([
        ...employees.map((employee) => ({
          ...employee,
          contact: {
            ...employee?.contact,
            contactEmails: getEmailArray(employee?.contact)
          }
        }))
      ]))
      .catch((error) => enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => setEmployeesLoading(false));
  }, [debouncedLoadEmployees, enqueueSnackbar]);

  useEffect(() => {
    fetchEmployees('');
    // eslint-disable-next-line
  }, []);

  return (
    <SelectEntity
      buttonTitle={buttonTitle}
      createTooltip={translate('attestationFormRequest.contacts.modalAdd.newPerson')}
      entityValue={{
        avatar: compact([personValue?.firstName?.charAt(0), personValue?.lastName?.charAt(0)]),
        description: personValue?.occupation,
        title: compact([personValue?.firstName, personValue?.lastName]).join(' ')
      }}
      getOptionLabel={(option) => compact([option?.firstName, option?.lastName]).join(' ').trim()}
      isEdition={isEdition}
      loading={employeesLoading}
      loadOptions={fetchEmployees}
      name={name}
      newEntityValue={{
        title: translate(newPersonTitle)
      }}
      options={employeeOptions}
      renderSelectOption={(props, option) => (
        option.id !== 'new-person-employee' && (
          <BannerItemOption
            avatar={compact([option?.firstName?.charAt(0), option?.lastName?.charAt(0)])}
            description={option.occupation}
            innerProps={props}
            key={shortid.generate()}
            title={compact([option?.firstName, option?.lastName]).join(' ')}
          />
        )
      )}
      required={!isNew}
      selectLabel={translate('attestationFormRequest.contacts.modalAdd.selectLabel')}
      selectPlaceholder={translate('attestationFormRequest.contacts.modalAdd.selectPlaceholder')}
      title={selectTitle}
      value={employeeValue}
      onChange={onSelected}
      onCreate={onNew}
    >
      {children}
    </SelectEntity>
  );
};

import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { translate } from 'utils';
import { useModal } from './ModalProvider';

/**
 * Base hook to import Excel file and handle validation error popup
 * @param importFunction method use from a service to import and who take a Excel file in parameter
 * @param reloadListFunction method use to reload a list when the import function update a list
 * @param errorModalTitle optional : translation for the import error modal
 * @param tooltip optional : tooltip above import button
 * @returns {isImporting, importFunc} 1rst to indicate if export is processing or done, 2nd the method used when click on the button to import
 */
export function useExcelImport(importFunction, reloadListFunction, errorModalTitle = 'import.common.error', tooltip = null) {
  const {open} = useModal();
  const [isImporting, setIsImporting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const importFunc = useCallback(() => {
    open({
      type: 'IMPORT',
      onConfirm: (excelFile) => {
        setIsImporting(true);
        importFunction(excelFile)
          .then(() => {
            enqueueSnackbar(translate('confirms.fileImported'), { variant: 'success' });
            reloadListFunction();
          })
          .catch((exception) => {
            open({
              type: 'ERROR',
              text: exception.message !== undefined ? exception.message : exception,
              title: errorModalTitle
            });
          })
          .finally(() => setIsImporting(false));
      },
      closeOnSubmit: false,
      tooltip: tooltip
    });
  }, [open, importFunction, reloadListFunction, errorModalTitle]);

  return {
    isImporting,
    importFunc
  };
}

import { StorageHelper } from '.';

const setFilters = (filterKey, filters) => StorageHelper.SET(`filters_${filterKey}`, filters);

const getFilters = (filterKey) => {
  const storedFilter = StorageHelper.GET(`filters_${filterKey}`);

  if (storedFilter) {
    return JSON.parse(StorageHelper.GET(`filters_${filterKey}`));
  }

  return null;
};

const deleteStoredFilters = () => {
  Object.entries(localStorage)
    .map((key) => key[0])
    .filter((key) => key.startsWith('filters_'))
    .forEach((filter) => localStorage.removeItem(filter));
};

export const FilterHelper = {
  getFilters,
  setFilters,
  deleteStoredFilters
};

import { useTheme } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { InfoTooltip } from 'components/_commons/InfoTooltip';
import React, { useState } from 'react';
import { translate, translationExist } from 'utils';

export const ConditionalTooltip = ({
  children, disableBackground = false, displayInline = true, translationKey
}) => {
  if (translationExist(translationKey)) {
    const theme: any = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    const enhancedChildren = React.isValidElement(children) && !disableBackground
      ? React.cloneElement<any>(children, {
        style: {
          backgroundColor: isHovered && theme.palette.grey.main,
          display: displayInline && 'inline-block'
        },
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      })
      : children;
    return (
      <DatafluidesTooltip title={translate(translationKey)}>
        {enhancedChildren}
      </DatafluidesTooltip>
    );
  }
  return children;
};

export const ConditionalInfoTooltip = ({ translationKey, onClick }: {
  onClick?: any,
  translationKey: string
}) => (
  translationExist(translationKey) && (
    <div
      style={{
        width: '40px', height: '40px', display: 'inline-flex'
      }}
    >
      <InfoTooltip label={translate(translationKey)} size="1x" onClick={onClick} />
    </div>
  )
);

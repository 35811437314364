import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

const updateOperator = (body, operatorId) => RequestHelper.PUT(API_ROUTES.OPERATORS.OPERATOR(operatorId), body);
const getOperatorDetails = (operatorId) => RequestHelper.GET(API_ROUTES.OPERATORS.OPERATOR(operatorId));
const updateOrganization = (requestId, mode) => RequestHelper.PUT(API_ROUTES.REQUESTS.UPDATE_ORGANIZATION(requestId, mode));
const getDocumentsType = () => RequestHelper.GET(API_ROUTES.OPERATORS.DOCUMENTS_TYPE());
const getCategoriesByDocumentType = (parentId) => RequestHelper.GET(API_ROUTES.OPERATORS.CATEGORIES_BY_DOCUMENT_TYPE(parentId));
const updateWorkMode = (operatorId, workMode) => RequestHelper.PUT(API_ROUTES.OPERATORS.UPDATE_WORK_MODE(operatorId, workMode));
const remove = (operatorId) => RequestHelper.DELETE(API_ROUTES.OPERATORS.OPERATOR(operatorId));
const deactivate = (operatorId, toggle) => RequestHelper.PUT(API_ROUTES.OPERATORS.DEACTIVATE(operatorId, toggle));
const loadOperatorViewFromPerson = (personId) => RequestHelper.GET(API_ROUTES.PERSONS.PERSON(personId));
const linkDocument = (operatorId, body) => RequestHelper.PUT(API_ROUTES.OPERATORS.LINK_DOCUMENT(operatorId), body);

const importAttestationFormOperators = (requestId, body) => (
  RequestHelper.POST(API_ROUTES.OPERATORS.IMPORT_ATTESTATION_FORM_OPERATORS(requestId), body)
);

const exportAttestationFormOperators = (requestId) => (
  RequestHelper.POST(API_ROUTES.OPERATORS.EXPORT_ATTESTATION_FORM_OPERATORS(requestId))
);
export const AttestationFormOperatorService = {
  updateOrganization,
  remove,
  deactivate,
  getOperatorDetails,
  linkDocument,
  updateOperator,
  getDocumentsType,
  getCategoriesByDocumentType,
  updateWorkMode,
  loadOperatorViewFromPerson,
  importAttestationFormOperators,
  exportAttestationFormOperators
};

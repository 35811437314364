import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { translate } from 'utils';

const DropdownButton = ({
  label = '', icon, disabled = false, placement = 'bottom-end', children, buttonStyle = {},
  isIconOnly = false, isLoading = false, iconSize = 'xs', color = 'primary', tooltip, popperMargin = '0',
  forceClose = false
}: {
  buttonStyle?: any,
  children?: any,
  color?: any,
  disabled?: boolean,
  forceClose?: boolean,
  icon?: any,
  iconSize?: SizeProp,
  isIconOnly?: boolean,
  isLoading?: boolean,
  label?: string,
  placement?: any,
  popperMargin?: string,
  tooltip?: any
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();
  const theme: any = useTheme();

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  const button = (isIconOnly)
    ? (
      <span ref={anchorRef}>
        <IconButton
          color={color}
          disabled={disabled || isLoading}
          style={buttonStyle}
          onClick={handleToggle}
        >
          <FontAwesomeIcon fixedWidth icon={isLoading ? faSpinner : icon} size={iconSize} spin={isLoading} />
        </IconButton>
      </span>
    )
    : (
      <Button
        color={color}
        disabled={disabled || isLoading}
        ref={anchorRef}
        startIcon={icon && <FontAwesomeIcon icon={isLoading ? faSpinner : icon} spin={isLoading} />}
        style={buttonStyle}
        variant="contained"
        onClick={handleToggle}
      >
        {translate(label)}
      </Button>
    );

  return (
    <>
      {tooltip ? (
        <DatafluidesTooltip title={tooltip}>
          <span>
            {button}
          </span>
        </DatafluidesTooltip>
      ) : button }
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={isOpen}
        placement={placement}
        style={{ zIndex: theme.zIndex.small }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top center' }}
          >
            <Paper style={{ padding: '1rem', margin: popperMargin }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownButton;

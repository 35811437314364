import { DialogContent, Grid } from '@mui/material';
import { CheckboxGroup } from 'components/_commons/Form/Inputs';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { TecneaTitle } from 'components/_commons/Title';
import { useFetch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { EnumService, enumValueTypes } from 'services/EnumService';
import { translate } from 'utils';
import { ModalProps } from '../../../hooks/ModalProvider';

export const AttestationCategoriesModal = ({
  onSubmit, onClose, title, defaultValues
}: ModalProps<any>) => {
  const { response } = useFetch(() => EnumService.getEnumValues(enumValueTypes.ATTESTATION_CATEGORY), [], []);
  const options = (response ?? []).map((opt) => ({ value: opt, label: opt }));
  const formContext = useForm({ defaultValues: defaultValues ?? {} });
  const { watch } = formContext;
  const [isDisabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const attestationCategories = watch('attestationCategories');

  const onSuccess = (categories) => {
    onSubmit(categories, setIsSaving);
  };

  useEffect(
    () => setDisabled(!attestationCategories?.length),
    [attestationCategories]
  );

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'editCategoriesModal' }}
      onSuccess={onSuccess}
    >
      <ModalHeader onClose={onClose}>
        {translate(title)}
      </ModalHeader>
      <DialogContent style={{
        minHeight: '10vh', marginTop: 40, marginLeft: 20, marginRight: 20
      }}
      >
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TecneaTitle label={translate('attestationFormRequest.createModal.categories')} />
          </Grid>
          <Grid item>
            <CheckboxGroup
              name="attestationCategories"
              options={options}
              row
            />
          </Grid>

        </Grid>

      </DialogContent>
      <CustomActionButtons disabled={isDisabled} isSaving={isSaving} onClose={onClose} />
    </FormContainer>
  );
};

import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET

const filterEvaluations = ((filter, page) => (
  RequestHelper.POST(`${API_ROUTES.ATTESTATION_EVALUATIONS.FILTER(page)}`, filter)
));

const filterAssignedEvaluations = ((filter) => (
  RequestHelper.POST(`${API_ROUTES.ATTESTATION_EVALUATIONS.FILTER_ASSIGNED()}`, filter)
));

const getEvaluatorOptions = ((requestId, evaluatorType) => (
  RequestHelper.GET(`${API_ROUTES.ATTESTATION_EVALUATIONS.EVALUATOR_OPTIONS(requestId, evaluatorType)}`)
));

const getAllEvaluatorOptions = ((evaluatorType) => (
  RequestHelper.GET(`${API_ROUTES.ATTESTATION_EVALUATIONS.ALL_EVALUATOR_OPTIONS(evaluatorType)}`)
));

const countByEvaluationStatus = (() => (
  RequestHelper.GET(`${API_ROUTES.ATTESTATION_EVALUATIONS.COUNT_BY_STATUS()}`)
));

const getStatusOptions = (() => (
  RequestHelper.GET(`${API_ROUTES.ATTESTATION_EVALUATIONS.STATUS_OPTIONS()}`)
));

const getUnreadDocumentAlerts = ((requestId) => (
  RequestHelper.GET(`${API_ROUTES.ATTESTATION_EVALUATIONS.UNREAD_DOCUMENTS_ALERTS(requestId)}`)
));

const assignEvaluator = ((requestId, evaluatorType, userItem) => (
  RequestHelper.POST(`${API_ROUTES.ATTESTATION_EVALUATIONS.ASSIGN_EVALUATOR(requestId, evaluatorType)}`, userItem)
));

const startInstruction = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.START_INSTRUCTION(requestId)}`)
));

const validateInstruction = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.VALIDATE_INSTRUCTION(requestId)}`)
));

const dismissInstruction = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.DISMISS_INSTRUCTION(requestId)}`)
));

const rejectInstruction = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.REJECT_INSTRUCTION(requestId)}`)
));

const startSupervision = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.START_SUPERVISION(requestId)}`)
));

const validateSupervision = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.VALIDATE_SUPERVISION(requestId)}`)
));

const dismissSupervisionOperator = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.DISMISS_SUPERVISION_OPERATOR(requestId)}`)
));

const dismissSupervisionInstructor = ((requestId) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.DISMISS_SUPERVISION_INSTRUCTOR(requestId)}`)
));

const rejectSupervision = ((requestId, body) => (
  RequestHelper.PUT(`${API_ROUTES.ATTESTATION_EVALUATIONS.REJECT_SUPERVISION(requestId)}`, body)
));

const exportAttestationEvaluationsByEmail = ((filters, signal) => RequestHelper.POST(
  `${API_ROUTES.ATTESTATION_EVALUATIONS.EXPORT_BY_MAIL()}`,
  filters,
  null,
  signal
));

const exportAttestationEvaluations = ((filters) => (
  RequestHelper.POST(`${API_ROUTES.ATTESTATION_EVALUATIONS.EXPORT()}`, filters).then((response) => {
    DocumentHelper.handleExcelFileDownload(response);
  })
));

const importAttestationEvaluations = ((document) => (
  RequestHelper.POST(`${API_ROUTES.ATTESTATION_EVALUATIONS.IMPORT()}`, document)
));

export const AttestationEvaluationService = {
  filterEvaluations,
  getUnreadDocumentAlerts,
  filterAssignedEvaluations,
  getEvaluatorOptions,
  getAllEvaluatorOptions,
  assignEvaluator,
  startInstruction,
  validateInstruction,
  dismissInstruction,
  rejectInstruction,
  startSupervision,
  validateSupervision,
  getStatusOptions,
  countByEvaluationStatus,
  dismissSupervisionOperator,
  dismissSupervisionInstructor,
  rejectSupervision,
  exportAttestationEvaluationsByEmail,
  exportAttestationEvaluations,
  importAttestationEvaluations
};

import { InputAdornment } from '@mui/material';
import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import { DATAFLUIDES_THEME } from 'styles/DATAFLUIDES_THEME';
import { OtherProps, translate } from 'utils';

export const FormInputField = ({
  label, variant = 'standard', required = false, fullWidth = false, name, onChange, value,
  disabled = false, endAdornment, size, validation, textAlign, width, type, customInputProps,
  withArrow, helperText, helperTextColor, canBeHighlighted = false, hasError = false, onBlur, ...otherProps
}: {
  canBeHighlighted?: boolean,
  customInputProps?: any,
  disabled?: boolean,
  endAdornment?: any,
  fullWidth?: boolean,
  hasError?: boolean,
  helperText?: string,
  helperTextColor?: any,
  label?: string,
  name: string,
  onBlur?: any,
  onChange?: any,
  required?: boolean,
  size?: any,
  textAlign?: any,
  type?: any,
  validation?: any,
  value?: any,
  variant?: 'standard' | 'filled' | 'outlined',
  width?: any,
  withArrow?: boolean
} & OtherProps) => {
  const isNullOrUndefined = ((val) => val === undefined || val === null);

  let style: any = {
    width,
    '& input': {
      textAlign
    },
    ...(
      (hasError || (canBeHighlighted && isNullOrUndefined(value)))
      && { backgroundColor: (DATAFLUIDES_THEME.palette.error as any).lighter }
    )
  };

  const updatedCustomInputProps = {
    ...customInputProps,
    sx: {
      ...customInputProps?.sx,
      '&.Mui-disabled': {
        ...(customInputProps?.sx?.['&.Mui-disabled'] || {}),
        ...(
          (hasError || (canBeHighlighted && isNullOrUndefined(value)))
          && { backgroundColor: `${(DATAFLUIDES_THEME.palette.error as any).lighter} !important` }
        )
      }
    }
  };

  if (!withArrow) {
    style = {
      ...style,
      '& input[type=number]': {
        MozAppearance: 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    };
  }

  return (
    <TextFieldElement
      disabled={disabled}
      FormHelperTextProps={{
        sx: { color: helperTextColor }
      }}
      fullWidth={fullWidth}
      helperText={helperText}
      InputProps={{
        inputProps: updatedCustomInputProps,
        endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        size,
        sx: style,
        type
      }}
      label={label}
      name={name}
      required={required}
      validation={{
        required: (required ? translate('common.requiredField') : false),
        ...validation
      }}
      value={value}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
      {...otherProps}
    />
  );
};

import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormElement } from 'components/_commons/Form/FormElement/FormElement';
import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import { OtherProps, translate } from 'utils';

export const StyledListItem = styled(ListItem)(() => ({
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#DEEBFF'
  }
}));

export const MultiValueRemove = (removeProps) => {
  const clearableValue = removeProps?.data?.clearableValue;
  return clearableValue && (
    <components.MultiValueRemove {...removeProps} />
  );
};

export const StyledRequiredSelectHack = styled('input')(() => ({
  height: '1px',
  width: '100%',
  margin: 0,
  padding: 0,
  border: 'none',
  opacity: 0,
  cursor: 'default'
}));

const SelectField = ({
  name, options = [], value, label, onChange, required = false, isDisabled = false,
  placeholder = 'common.selectOption', isClearable = true, closeMenuOnSelect = true, messageNoOptions = 'warnings.noOptionsAvailable',
  height, width, ...props
}: {
  closeMenuOnSelect?: boolean,
  height?: string,
  isClearable?: boolean,
  isDisabled?: boolean,
  label?: string,
  messageNoOptions?: string,
  name?: string,
  onChange: any,
  options?: Array<any>,
  placeholder?: string,
  required?: boolean,
  value?: any,
  width?: string
} & OtherProps) => {
  const displayNoOptions = useCallback(() => translate(messageNoOptions), [messageNoOptions]);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 2000
    }),
    control: (provided) => ({
      ...provided,
      height,
      width
    })
  };

  return (
    <FormElement
      label={label ? translate(label) : label}
      required={required}
    >
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        components={{ MultiValueRemove }}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        required={required}
        styles={customStyles}
        value={value}
        onChange={onChange}
        {...props}
      />
      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={value || ''}
          required
          tabIndex={-1}
        />
      )}
    </FormElement>
  );
};

export default SelectField;

import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Typography
} from '@mui/material';
import { useModal } from 'hooks';
import { DocumentHelper, isMobile, translate } from 'utils';
import { iconEnum } from 'utils/icons';

import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { GenericTable } from 'components/_commons/GenericTable/GenericTable';
import { TruncatedText } from 'components/_commons/Text';
import { TecneaTitle } from 'components/_commons/Title';
import { AttestationFormOperatorService, AttestationFormToolService, DocumentService } from 'services';

export const DocumentsTable = observer<{
  canEdit?: boolean,
  canValidate?: boolean,
  disabled?: boolean,
  folders: any,
  isLoading?: boolean,
  label: string,
  onUpdate: any,
  onUpdateCurrentSection: any,
  requestId: any,
  section: any,
  styles: any
}>(({
  folders, section, disabled = false, styles, requestId,
  label, isLoading = false, canEdit = false, onUpdateCurrentSection, onUpdate, canValidate = false
}) => {
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const onLink = useCallback((id, newSection) => {
    if (newSection === section) {
      onUpdateCurrentSection();
    }
    onUpdate(id);
    enqueueSnackbar(translate('attestationFormRequest.linkDocument'), { variant: 'success' });
    close();
  }, [enqueueSnackbar, onUpdate, onUpdateCurrentSection, section, close]);

  const removeFolder = ({ id }) => {
    open({
      type: 'WARNING',
      onConfirm: () => DocumentService.deleteAttestationFormDocument(id)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.documentDeleted'), { variant: 'success' });
          onUpdate(id);
        })
    });
  };

  const linkOperator = useCallback((data, setIsClassifyingDocument) => {
    setIsClassifyingDocument(true);
    AttestationFormOperatorService.linkDocument(data.entity?.value, data)
      .then(() => onLink(data.document.id, data.section.value))
      .catch(() => enqueueSnackbar(translate('errors.COMMONS_ERROR'), { variant: 'error' }))
      .finally(() => setIsClassifyingDocument(false));
  }, [enqueueSnackbar, onLink]);

  const linkTool = useCallback((data, setIsClassifyingDocument) => {
    setIsClassifyingDocument(true);
    AttestationFormToolService.linkDocument(data.entity?.value, data)
      .then(() => onLink(data.document.id, data.section.value))
      .catch(() => enqueueSnackbar(translate('errors.COMMONS_ERROR'), { variant: 'error' }))
      .finally(() => setIsClassifyingDocument(false));
  }, [enqueueSnackbar, onLink]);

  const linkTracability = useCallback((data, formId, setIsClassifyingDocument) => {
    setIsClassifyingDocument(true);
    DocumentService.updateDocumentType(formId, data.entity?.value, data)
      .then(() => onLink(formId, data.section.value))
      .catch(() => enqueueSnackbar(translate('errors.COMMONS_ERROR'), { variant: 'error' }))
      .finally(() => setIsClassifyingDocument(false));
  }, [enqueueSnackbar, onLink]);

  const classifyDocuments = useCallback((folder) => open({
    type: 'CLASSIFY_DOCUMENTS',
    onSubmit: (values, setIsClassifyingDocument) => {
      const data = { ...values, document: { ...values.document, id: folder.id, documentId: folder.documentId } };
      if (values.section?.value === SectionEnum.Operators) {
        linkOperator(data, setIsClassifyingDocument);
      } else if (values.section?.value === SectionEnum.Tooling) {
        linkTool(data, setIsClassifyingDocument);
      } else if (values.section?.value === SectionEnum.Tracability) {
        linkTracability(data, folder.id, setIsClassifyingDocument);
      }
    },
    maxWidth: false,
    fullscreen: true,
    canValidate,
    modalState: {
      folder,
      section,
      requestId,
      canEdit: true
    }
  }), [requestId, open, section, canValidate, linkOperator, linkTool, linkTracability]);

  const listHeaders = () => [
    {
      name: 'name',
      label: 'attestationFormRequest.document.table.name',
      template: (row) => (
        <TableCell key={`${row.id}_name`}>
          <TruncatedText text={row?.document?.name} />
        </TableCell>
      )
    },
    {
      name: 'description',
      label: 'attestationFormRequest.document.table.description',
      hideForMobile: true,
      template: (row) => (
        <TableCell key={`${row.id}_description`}>
          <Typography>{row.description}</Typography>
        </TableCell>
      )
    },
    {
      name: 'type',
      label: 'attestationFormRequest.document.table.type',
      hideForMobile: true,
      template: (row) => (
        <TableCell key={`${row.id}_type`}>
          <Typography>{row.type?.label}</Typography>
        </TableCell>
      )
    },
    {
      name: 'actions',
      label: 'attestationFormRequest.document.table.actions',
      width: 150,
      template: (row) => (
        <TableCell className="spacing-right" key={`${row.id}_actions`}>
          { canEdit ? (
            <>
              <IconButton
                color="primary"
                disabled={!row.document}
                edge="end"
                onClick={() => classifyDocuments(row)}
              >
                <FontAwesomeIcon icon={iconEnum.folder.icon} size="sm" swapOpacity />
              </IconButton>
              {!isMobile() && (
                <IconButton
                  color="primary"
                  onClick={() => removeFolder(row)}
                >
                  <FontAwesomeIcon icon={iconEnum.trashAlt.icon as any} size="sm" swapOpacity />
                </IconButton>
              )}
            </>
          ) : (
            <IconButton
              color="primary"
              disabled={!row.document}
              edge="end"
              onClick={() => open({
                type: 'DISPLAY_DOCUMENT',
                loadContent: true,
                backdropClick: true,
                document: {
                  name: row.document.name,
                  documentId: row.documentId,
                  base64Content: DocumentHelper.getDocumentWithBase64Typing({
                    ...row.document,
                    base64Content: row.document.base64Content ?? ''
                  })
                }
              })}
            >
              <FontAwesomeIcon icon={iconEnum.eye.icon} size="sm" swapOpacity />
            </IconButton>
          )}
        </TableCell>
      )
    }
  ];

  if (!folders.length) {
    return null;
  }

  return (
    <Grid container direction="column" rowSpacing={3} style={styles}>
      <Grid item>
        <TecneaTitle label={translate(label || 'attestationFormRequest.classifyDocuments.title')} />
      </Grid>
      <Grid item>
        <GenericTable
          displayTotal={false}
          headers={listHeaders()}
          id="classifyDocumentsTable"
          isLoading={isLoading || disabled}
          rows={folders}
        />
      </Grid>
    </Grid>
  );
});

import { AddressModel } from 'core/models';
import { DateHelper } from 'utils/helpers';

function mapDetailsFromApi(data) {
  return {
    ...data,
    categories: (data.categories || []).join(', '),
    address: AddressModel.mapFromApi(data.address)
  };
}

function mapRequestFromApi(data) {
  return {
    ...data,
    categories: data.categories || [],
    startDate: DateHelper.formatDate(data.creationDate, 'dd/MM/yyyy'),
    validationDate: DateHelper.formatDate(data.validationDate, 'dd/MM/yyyy')
  };
}

function mapAttestationFromApi(data) {
  return {
    id: data.id,
    status: data.status,
    startDate: DateHelper.formatDate(data.startDate, 'dd/MM/yyyy'),
    endDate: DateHelper.formatDate(data.endDate, 'dd/MM/yyyy'),
    suspensionDate: DateHelper.formatDate(data.suspensionDate, 'dd/MM/yyyy'),
    number: data.number,
    isWarningDate: data.isWarningDate
  };
}

function mapAuditFromApi(data) {
  return {
    ...data,
    startDate: DateHelper.formatDate(data.startDate, 'dd/MM/yyyy'),
    appointment: DateHelper.formatDate(data.appointment, 'dd/MM/yyyy')

  };
}

function mapBilanFromApi(data) {
  return {
    ...data,
    transmissionDate: DateHelper.formatDate(data.transmissionDate, 'dd/MM/yyyy')
  };
}

export const InstitutionsModel = {
  mapDetailsFromApi,
  mapRequestFromApi,
  mapAttestationFromApi,
  mapAuditFromApi,
  mapBilanFromApi
};

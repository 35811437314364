import React from 'react';
import { AutocompleteElement } from 'react-hook-form-mui';
import shortid from 'shortid';
import { translate } from 'utils';

export const getOptionLabelWithInputValue = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.title;
};

const FormSelectField = ({
  label,
  placeholder,
  required = false,
  variant = 'standard',
  size,
  fullWidth = false,
  disableClearable = false,
  isOptionEqualToValue = (option, value) => option.value === value.value,
  name,
  disablePortal = true,
  options,
  filterSelectedOptions = false,
  loadingText,
  noOptionsText,
  loading = false,
  getOptionLabel,
  filterOptions,
  inputValue,
  onChange,
  autoSelect = false,
  freeSolo = false,
  onOpen,
  clearOnBlur = false,
  onInputChange,
  disabled = false,
  renderOption,
  multiple = false
}: {
  autoSelect?: boolean,
  clearOnBlur?: boolean,
  disableClearable?: boolean,
  disablePortal?: boolean,
  disabled?: boolean,
  filterOptions?: any,
  filterSelectedOptions?: boolean,
  freeSolo?: boolean,
  fullWidth?: boolean,
  getOptionLabel?: (option) => string,
  inputValue?: string,
  isOptionEqualToValue?: (option, value) => boolean,
  label?: string,
  loading?: boolean,
  loadingText?: string,
  multiple?: boolean,
  name?: string,
  noOptionsText?: string,
  onChange?: any,
  onInputChange?: any,
  onOpen?: any,
  options?: Array<any>,
  placeholder?: string,
  renderOption?: any,
  required?: boolean,
  size?: any,
  variant?: 'standard' | 'outlined' | 'filled'
}) => (
  <AutocompleteElement
    autocompleteProps={{
      inputValue,
      clearOnBlur,
      disablePortal,
      loadingText,
      noOptionsText,
      size,
      fullWidth,
      disabled,
      getOptionLabel,
      filterOptions,
      autoSelect,
      freeSolo,
      onChange,
      onOpen: () => onOpen && onOpen(),
      onInputChange: (event, value, reason) => {
        if (reason === 'input' && onInputChange) {
          onInputChange(value);
        }
      },
      disableClearable,
      isOptionEqualToValue,
      renderOption,
      filterSelectedOptions
    }}
    key={shortid.generate()}
    label={label}
    loading={loading}
    multiple={multiple}
    name={name}
    options={options}
    required={required}
    rules={{
      required: (required ? translate('common.requiredField') : false)
    }}
    textFieldProps={{
      placeholder,
      variant,
      InputProps: {}
    }}
  />
);

export default FormSelectField;

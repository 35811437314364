import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, FormControl, Grid, Typography
} from '@mui/material';
import { CheckboxGroup, FormInputField } from 'components/_commons/Form/Inputs';
import { MultipleInputField } from 'components/_commons/Form/Inputs/InputField/MultipleInputField';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { SelectEmployee } from 'components/_commons/Modals/common/SelectEmployee';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import { ModalProps } from 'hooks/ModalProvider';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { AttestationFormContactService } from 'services';
import { isMobile, translate } from 'utils';
import { Validators } from 'utils/helpers';

export const AddContactModal = ({
  onClose, onSubmit, requestId, defaultContact
}: ModalProps<any>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [contact, setContact] = useState<any>();
  const [isNewContact, setIsNewContact] = useState(false);
  const [contactTypes, setContactTypes] = useState<Array<any>>([]);
  const [contactTypesLoading, setContactTypesLoading] = useState(true);
  const [isContactSaving, setIsContactSaving] = useState(false);

  const getEmailArray = (c) => c?.contactEmails?.split(';') ?? c?.technicalEmail?.split(';') ?? [];
  const defaultValues = {
    person: defaultContact?.person ? {
      id: defaultContact.person.id ?? '',
      firstName: defaultContact.person.firstName ?? '',
      lastName: defaultContact.person.lastName ?? '',
      occupation: defaultContact.person.occupation ?? '',
      contact: {
        phoneNumber: defaultContact.person.contact?.phoneNumber ?? '',
        contactEmails: getEmailArray(defaultContact.person.contact)
      }
    } : null,
    selectedType: defaultContact?.type ? {
      value: defaultContact.type ?? ''
    } : null,
    types: defaultContact?.types ?? []
  };
  const formContext = useForm<any>({ defaultValues });
  const {
    setValue, watch, clearErrors, reset
  } = formContext;
  const isEdition = Boolean(defaultContact);
  const newPersonEmployee = {
    firstName: translate('attestationFormRequest.contacts.modalAdd.newPerson'),
    lastName: '',
    id: 'new-person-employee'
  };

  const fetchContactTypes = useCallback(() => {
    setContactTypesLoading(true);
    AttestationFormContactService.getContactTypes()
      .then((response) => setContactTypes(response))
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setContactTypesLoading(false));
  }, [enqueueSnackbar]);

  const onEmployeeSelected = (event, selected) => {
    setIsNewContact(false);
    setContact(selected ? { person: selected } : null);
    setValue('person', watch('selectedPerson'));
    // Next line mandatory to ensure the MultipleInputField rerender on emails
    setValue('person.contact.contactEmails', watch('selectedPerson.contact.contactEmails'));
  };

  const onNewContact = () => {
    clearErrors();
    reset({
      person: {
        firstName: null,
        lastName: null,
        occupation: null,
        contact: {
          phoneNumber: null,
          contactEmails: []
        }
      },
      selectedType: {
        value: null
      },
      types: []
    });
    setValue('selectedPerson', newPersonEmployee);
    setIsNewContact(true);
    setContact(null);
  };

  const onSubmitModal = (submittedContact) => {
    const emails = submittedContact?.person?.contact?.contactEmails || [];
    const stringFormatEmails = emails.join(';');
    const stringFormattedEmailContact = { ...submittedContact };
    submittedContact?.person?.contact && (stringFormattedEmailContact.person.contact.contactEmails = stringFormatEmails);
    delete stringFormattedEmailContact.selectedPerson;
    delete stringFormattedEmailContact.selectedType;
    if (contact?.types) {
      contact.types = submittedContact.types;
    }
    onSubmit(merge(contact, stringFormattedEmailContact), setIsContactSaving);
  };

  useEffect(() => {
    isEdition && setContact(defaultContact);
    fetchContactTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'addContactForm', style: { overflow: 'auto' } }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {translate(`attestationFormRequest.contacts.modalAdd.title${isEdition ? 'Edit' : 'Add'}`)}
      </ModalHeader>

      <DialogContent style={{ width: isMobile() ? '100vw' : '80vw', height: '70vh', paddingTop: '30px' }}>
        <SelectEmployee
          buttonTitle="attestationFormRequest.contacts.modalAdd.createContact"
          employeeValue={contact}
          excludeContacts
          isEdition={isEdition}
          isNew={isNewContact}
          name="selectedPerson"
          newPersonTitle="attestationFormRequest.contacts.modalAdd.newPerson"
          personValue={watch('person')}
          requestId={requestId}
          selectTitle="attestationFormRequest.contacts.modalAdd.selectTitle"
          onNew={onNewContact}
          onSelected={onEmployeeSelected}
        >
          <Grid container direction="row" item>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="standard">
                {!contact && (
                  <StyledInput>
                    <FormInputField
                      fullWidth
                      label={translate('common.firstName')}
                      name="person.firstName"
                      required
                    />
                  </StyledInput>
                )}
                {!contact && (
                  <StyledInput>
                    <FormInputField
                      fullWidth
                      label={translate('common.lastName')}
                      name="person.lastName"
                      required
                    />
                  </StyledInput>
                )}
                <StyledInput>
                  <MultipleInputField
                    fullWidth
                    label={translate('common.email')}
                    name="person.contact.contactEmails"
                    required
                    validate={Validators.validateEmails}
                  />
                </StyledInput>
                <StyledInput>
                  <FormInputField
                    fullWidth
                    label={translate('common.occupation')}
                    name="person.occupation"
                  />
                </StyledInput>
                <StyledInput>
                  <FormInputField
                    fullWidth
                    label={translate('common.phone')}
                    name="person.contact.phoneNumber"
                  />
                </StyledInput>
              </FormControl>
            </Grid>
            <Grid container item justifyContent="center" md={6} xs={12}>
              <Grid item marginTop="20px">
                <Grid container direction="column" item spacing={2}>
                  <Grid item>
                    <Typography variant="h5">
                      {`${translate('attestationFormRequest.contacts.modalAdd.typeTitle')} *`}
                    </Typography>
                  </Grid>
                  {contactTypesLoading
                    ? (
                      <Grid alignItems="center" container direction="column" item xs>
                        <Grid item xs>
                          <FontAwesomeIcon icon={faSpinner as any} size="lg" spin />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CheckboxGroup
                          name="types"
                          options={contactTypes}
                          required
                          row={false}
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SelectEmployee>
      </DialogContent>
      {!isMobile() && !(contact || isNewContact)
          && (
            <Grid item style={{ position: 'absolute', bottom: '50px', right: '20px' }}>
              <img alt="header icon" src="/assets/images/search.png" />
            </Grid>
          )}
      <CustomActionButtons
        disabled={contactTypesLoading}
        isSaving={isContactSaving}
        style={{ position: 'relative' }}
        onClose={onClose}
      />
    </FormContainer>
  );
};

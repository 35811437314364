import React from 'react';
import { TextareaAutosizeElement } from 'react-hook-form-mui';
import { translate } from 'utils';

const FormTextareaField = ({
  label, variant = 'standard', required, fullWidth, name, onChange, multiline, minRows, maxRows,
  disabled, size, validation, textAlign, width, withArrow, helperText, onBlur
}) => {
  let style = {
    width,
    '& input': {
      textAlign
    }
  };

  if (!withArrow) {
    style = {
      ...style,
      '& input[type=number]': {
        MozAppearance: 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    };
  }

  return (
    <TextareaAutosizeElement
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      InputProps={{
        multiline,
        minRows,
        maxRows,
        size,
        sx: style
      }}
      label={label}
      name={name}
      required={required}
      validation={{
        required: (required ? translate('common.requiredField') : false),
        ...validation
      }}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default FormTextareaField;

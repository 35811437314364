import React, { useState } from 'react';

import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent } from '@mui/material';

import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { StructureLevelSelect } from 'components/_commons/Modals/common/StructureLevelSelect';
import { ModalProps } from 'hooks/ModalProvider';
import { translate } from 'utils';

export const UpdateUserLevelModal = ({
  onClose,
  onSubmit,
  defaultValue,
  structureLevelOptions,
  isLoading
}: ModalProps<any>) => {
  const [selectedOption, setSelectedOption] = useState(
    structureLevelOptions.find((levelOption) => levelOption.value === defaultValue)
  );
  const [isUserLevelSaving, setIsUserLevelSaving] = useState(false);

  const handleValidateModal = (e) => {
    e.preventDefault();
    onSubmit(selectedOption, setIsUserLevelSaving);
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding as any} />
        {translate('modalStructureLevelUpdate.title')}
      </ModalHeader>

      <DialogContent style={{ marginTop: '30px' }}>
        <StructureLevelSelect
          isLoading={isLoading}
          options={structureLevelOptions}
          placeholder="common.searchByStructureLevel"
          required
          selectedItem={selectedOption || ''}
          onSelectItem={(value) => setSelectedOption(value)}
        />
      </DialogContent>

      <CustomActionButtons
        isSaving={isUserLevelSaving}
        onClose={onClose}
      />
    </form>
  );
};

import { faHome } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { PageTitle } from 'components/_commons/PageTitle/PageTitle';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LogService } from 'services';
import { translate } from 'utils';
import { ROUTES } from 'utils/constants';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    this.setState({ error: error.message });
    LogService.logStackTrace({
      stackTrace: error.message,
      body: error.stack
    })
      .catch((err) => err);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          style={{ height: '100vh', padding: '5rem' }}
        >
          <Grid item>
            <PageTitle text={translate('errors.default')} />
          </Grid>
          <Grid item>
            <Link to={ROUTES.HOME} onClick={() => this.setState({ error: null })}>
              <FontAwesomeIcon icon={faHome} size="5x" />
            </Link>
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

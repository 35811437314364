import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Typography
} from '@mui/material';
import { FormInputField } from 'components/_commons/Form/Inputs';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import React, { useCallback, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { translate } from 'utils';
import { ATTESTATION_STATUS } from 'utils/constants';
import { ModalProps } from '../../../hooks/ModalProvider';

export const DisableAttestationModal = ({
  onClose,
  onSubmit,
  multiple,
  canOnlyRemove
}: ModalProps<any>) => {
  const formContext = useForm();
  const { watch } = formContext;
  const [isSuspending, setIsSuspending] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const getSubmittedComment = useCallback(() => watch('comment'), [watch]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'disableAttestationModal' }}
    >
      <ModalHeader onClose={onClose}>
        {multiple ? translate('disableAttestationModal.mass.title') : translate('disableAttestationModal.title')}
      </ModalHeader>

      {multiple && (
        <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
          <Typography>
            {translate('disableAttestationModal.warning.massDisable')}
          </Typography>
        </DialogContent>
      )}

      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <ModalFormField title={translate('disableAttestationModal.commentTitle')}>
          <FormInputField
            fullWidth
            label={translate('disableAttestationModal.commentLabel')}
            name="comment"
            required
          />
        </ModalFormField>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isSuspending || isRemoving}
          variant="text"
          onClick={onClose}
        >
          {translate('button.cancel')}
        </Button>

        <Button
          color="primary"
          disabled={isSuspending || isRemoving}
          startIcon={isRemoving && (<FontAwesomeIcon fixedWidth icon={faSpinner as any} spin />)}
          value="remove"
          onClick={formContext.handleSubmit(() => onSubmit(
              { comment: getSubmittedComment(), status: ATTESTATION_STATUS.REMOVED },
              setIsRemoving
          ))}
        >
          {multiple ? translate('button.disableAttestation.mass.withdraw') : translate('button.disableAttestation.withdraw')}
        </Button>

        {!canOnlyRemove && (
          <Button
            color="primary"
            disabled={isSuspending || isRemoving}
            startIcon={isSuspending && (<FontAwesomeIcon fixedWidth icon={faSpinner as any} spin />)}
            value="disable"
            onClick={formContext.handleSubmit(() => onSubmit(
                { comment: getSubmittedComment(), status: ATTESTATION_STATUS.SUSPENDED },
                setIsSuspending
            ))}
          >
            {multiple ? translate('button.disableAttestation.mass.suspend') : translate('button.disableAttestation.suspend')}
          </Button>
        )}
      </DialogActions>
    </FormContainer>
  );
};

import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// POST
const resolveAlert = (alertId, alert) => (RequestHelper.PUT(`${API_ROUTES.ALERTS.ALERT(alertId)}/resolve`, alert));
const instructionResolveAlert = (alertId, alert) => (
  RequestHelper.PUT(`${API_ROUTES.ALERTS.ALERT(alertId)}/resolve-instruction`, alert));
const reopenAlert = (alertId, alert) => (RequestHelper.PUT(`${API_ROUTES.ALERTS.ALERT(alertId)}/reopen`, alert));
const closeAlert = (alertId) => (RequestHelper.PUT(`${API_ROUTES.ALERTS.ALERT(alertId)}/close`));
const deleteAlert = (alertId) => (RequestHelper.DELETE(API_ROUTES.ALERTS.ALERT(alertId)));

export const AlertService = {
  closeAlert,
  reopenAlert,
  resolveAlert,
  instructionResolveAlert,
  deleteAlert
};

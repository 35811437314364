import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { Document } from 'components/_commons/Document/Document';
import { InputFile } from 'components/_commons/Form';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import { SingleSelect } from 'components/_commons/Modals/common/SingleSelect';
import { TecneaTitle } from 'components/_commons/Title';
import React, { useCallback, useEffect } from 'react';
import { DocumentHelper, translate } from 'utils';
import { DateHelper } from 'utils/helpers';

export const DocumentAssignationModalRegistration = ({
  formContext,
  disableNextStep,
  documentTypes,
  isLoadingDocumentTypes
}) => {
  const { watch } = formContext;
  const document = watch('document');

  useEffect(() => {
    if (!document) {
      disableNextStep(true);
    } else {
      disableNextStep(false);
    }
    // eslint-disable-next-line
  }, [document]);

  const importDocument = useCallback((file) => {
    if (file) {
      const documentView = {
        ...file,
        base64Content: DocumentHelper.getDocumentWithoutBase64(file)
      };
      formContext.setValue('document', documentView);
    }
  }, [formContext]);

  const deleteDocument = useCallback(() => {
    formContext.setValue('document', null);
  }, [formContext]);

  return (
    <Grid container direction="column" padding={3} spacing={2}>
      <Grid container item>
        <Grid item xs={12}>
          <TecneaTitle label={translate('attestationFormRequest.assignationModal.registration.title')} />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item lg={6} xs={12}>
          {
            document ? (
              <Document
                deletable
                document={document}
                handleDelete={deleteDocument}
                name={document.name}
                url={document.base64Content}
              />
            ) : (
              <InputFile
                endIcon={faFileAlt}
                fullWidth
                handleAddDocument={importDocument}
                text={translate('attestationFormRequest.assignationModal.importProof')}
                whiteSpace="break-spaces"
              />
            )
          }

        </Grid>
      </Grid>
      <br />
      <Grid container item>
        <Grid item lg={6} xs={12}>
          <SingleSelect
            isLoading={isLoadingDocumentTypes}
            label={translate('attestationFormRequest.assignationModal.proofType')}
            name="documentType"
            options={documentTypes ?? []}
            required
          />
        </Grid>
      </Grid>
      <br />
      <Grid container item>
        <Grid item lg={6} xs={12}>
          <FormDatePicker
            disableFuture
            fullWidth
            label="common.documentDate"
            name="documentDate"
            required
            validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
            value={formContext.watch('documentDate')}
          />
        </Grid>
      </Grid>

    </Grid>
  );
};

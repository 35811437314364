const TOOL_ENUM_VALUES = {
  Registration: 'registration',
  Verification: 'verification',
  Possession: 'possession',
  Summary: 'summary'
};

const stepOrder = [
  'registration',
  'possession',
  'verification',
  'summary'
];

const Tools = {
  [TOOL_ENUM_VALUES.Registration]: {
    step: TOOL_ENUM_VALUES.Registration,
    title: 'attestationFormRequest.tools.modal.title.registration',
    nextStepLabel: 'button.save',
    percent: 0
  },
  [TOOL_ENUM_VALUES.Possession]: {
    step: TOOL_ENUM_VALUES.Possession,
    title: 'attestationFormRequest.tools.modal.title.possession',
    nextStepLabel: 'attestationFormRequest.tools.modal.addVerification',
    percent: 33
  },
  [TOOL_ENUM_VALUES.Verification]: {
    step: TOOL_ENUM_VALUES.Verification,
    title: 'attestationFormRequest.tools.modal.title.verification',
    nextStepLabel: 'attestationFormRequest.tools.modal.seeSummary',
    percent: 66
  },
  [TOOL_ENUM_VALUES.Summary]: {
    step: TOOL_ENUM_VALUES.Summary,
    title: 'attestationFormRequest.tools.modal.title.summary',
    nextStepLabel: 'button.save',
    percent: 100
  }
};

const isLast = (step) => step === stepOrder.slice(-1);
const isFirst = (step) => step === stepOrder[0];

function nextStep(step) {
  const index = stepOrder.indexOf(step);

  return stepOrder[Math.min(stepOrder.length - 1, index + 1)];
}

function prevStep(step) {
  const index = stepOrder.indexOf(step);

  return stepOrder[Math.max(0, index - 1)];
}

export const ToolsModalStepsEnum = {
  ...TOOL_ENUM_VALUES,
  Tools,
  isFirst,
  isLast,
  nextStep,
  prevStep
};

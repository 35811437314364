import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, Typography
} from '@mui/material';
import { FormInputField, ToggleButtons } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import FormSelectField, { getOptionLabelWithInputValue } from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import { useFetch } from 'hooks';
import React, { useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { ToolService } from 'services/ToolService';
import shortid from 'shortid';
import { DateHelper, FormHelper } from 'utils/helpers';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { ModalProps } from 'hooks/ModalProvider';
import { translate } from 'utils';
import { MINIMAL_TOOL_TYPES } from 'utils/constants';
import { CustomActionButtons } from './_CustomActionButtons';

export const AdministrateToolModal = ({
  onSubmit,
  onClose,
  defaultTool
}: ModalProps<any>) => {
  const { response: brands, isLoading: isLoadingBrands } = useFetch(() => ToolService.getBrands(), [], []);
  const { response: types, isLoading: isLoadingTypes } = useFetch(() => ToolService.getEquipmentTypes(), [], []);
  const [isToolSaving, setIsToolSaving] = useState(false);

  const isEdition = !!defaultTool;

  const formContext = useForm({
    defaultValues: {
      ...defaultTool,
      wasNewAtAcquisition: defaultTool?.wasNewAtAcquisition,
      activated: isEdition ? defaultTool?.activated : true
    }
  });
  const { watch } = formContext;
  const toolType = watch('type');
  const acquisitionDate = watch('acquisitionDate');

  const handleValidateModal = (tool) => {
    onSubmit(
      {
        ...tool,
        acquisitionDate: DateHelper.getDateWithoutTimezoneTransformation(tool.acquisitionDate)
      },
      setIsToolSaving
    );
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'administrateToolForm' }}
      onSuccess={handleValidateModal}
    >
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faTools as any} />
        {isEdition ? translate('modalTool.editTool') : translate('modalTool.createTool')}
      </ModalHeader>

      <DialogContent style={{ minWidth: '500px', paddingTop: '16px' }}>
        <Grid container direction="column" spacing={1}>
          {isEdition && (
            <ToggleButtons
              exclusive
              name="activated"
              options={[
                { value: true, label: translate('common.activated') },
                { value: false, label: translate('common.deactivated') }
              ]}
              style={{ marginBottom: '2rem' }}
              value={watch('activated')}
            />
          )}

          <StyledInput>
            <FormSelectField
              disabled={isEdition}
              fullWidth
              label={translate('attestationFormRequest.tools.table.type')}
              loading={isLoadingTypes}
              name="type"
              noOptionsText={translate('warnings.noOptionsAvailable')}
              options={types}
              required
              size="small"
              variant="outlined"
            />
          </StyledInput>

          {toolType && !MINIMAL_TOOL_TYPES.includes(toolType.value) && (
            <>
              <StyledInput>
                <FormSelectField
                  autoSelect
                  clearOnBlur
                  freeSolo
                  fullWidth
                  getOptionLabel={getOptionLabelWithInputValue}
                  label={translate('attestationFormRequest.tools.table.brand')}
                  loading={isLoadingBrands}
                  name="brand"
                  noOptionsText={translate('warnings.noOptionsAvailable')}
                  options={brands}
                  renderOption={(props, option) => (
                    <ItemOption {...props} isInsideSelect key={shortid.generate()}>
                      {getOptionLabelWithInputValue(option)}
                    </ItemOption>
                  )}
                  required
                  size="small"
                  variant="outlined"
                />
              </StyledInput>
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('attestationFormRequest.tools.table.model')}
                  name="model"
                  required
                />
              </StyledInput>
            </>
          )}

          {toolType && (toolType.value !== 'FLEXIBLE_COUPLINGS') && (
            <StyledInput>
              <FormInputField
                fullWidth
                label={translate('attestationFormRequest.tools.table.serialNumber')}
                name="serialNumber"
                required={!watch('internalIdentification')}
              />
            </StyledInput>
          )}
          <StyledInput>
            <FormInputField
              fullWidth
              label={translate('attestationFormRequest.tools.table.internalIdentification')}
              name="internalIdentification"
              required={!watch('serialNumber')}
            />
          </StyledInput>
          <StyledInput>
            <FormDatePicker
              animateYearScrolling
              disableFuture
              fullWidth
              label="attestationFormRequest.tools.table.acquisition"
              name="acquisitionDate"
              required
              validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
              value={formContext.watch('acquisitionDate')}
              variant="standard"
            />
          </StyledInput>
          {
            acquisitionDate
            && !DateHelper.isMoreThanOneYearInThePast(acquisitionDate)
            && (
              <StyledInput>
                <Typography variant="body1">{translate('attestationFormRequest.tools.modal.newToolQuestion')}</Typography>
                <ToggleButtons
                  enforceSelection={false}
                  name="wasNewAtAcquisition"
                  options={FormHelper.yesNoOption()}
                  size="small"
                  style={{ marginBottom: '10px', marginTop: '5px' }}
                  value={FormHelper.translateYesOrNo(formContext.watch('wasNewAtAcquisition'))}
                />
              </StyledInput>
            )
          }
          <StyledInput>
            <FormInputField
              fullWidth
              label={translate('attestationFormRequest.tools.table.comment')}
              name="comment"
            />
          </StyledInput>
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isSaving={isToolSaving}
        submitLabelKey={isEdition ? 'button.save' : 'common.create'}
        onClose={onClose}
      />
    </FormContainer>
  );
};

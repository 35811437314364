import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatafluidesTooltip } from 'components/_commons/DatafluidesTooltip/DatafluidesTooltip';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { translate } from 'utils';

export const InfoTooltip = ({
  label = '', size = 'xs', onClick
}: {
  label?: string,
  onClick?: any,
  size?: SizeProp
}) => {
  const theme = useTheme();

  return (
    <DatafluidesTooltip aria-label={label} title={label}>
      <IconButton aria-label={label} size={size === 'xs' ? 'small' : 'medium'} onClick={onClick}>
        <FontAwesomeIcon color={theme.palette.primary.main} icon={faQuestionCircle as any} size={size} />
      </IconButton>
    </DatafluidesTooltip>
  );
};

export const VisibilityTooltip = observer<{
  color?: string,
  isPublic?: boolean
}>(({ isPublic, color = 'inherit' }) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;

  const icon = isPublic ? faEye : faEyeSlash;
  const label = isPublic ? translate('common.isPublic') : translate('common.isPrivate');

  return (
    isConnected && (
      <DatafluidesTooltip aria-label={label} title={label}>
        <IconButton aria-label={label} style={{ padding: 0 }}>
          <FontAwesomeIcon color={color} icon={icon as any} />
        </IconButton>
      </DatafluidesTooltip>
    )
  );
});
